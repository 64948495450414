import React, { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { IApplicationContext } from 'interfaces/i-application-context-props'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import { InfoBox } from 'components/message-boxes/information-box/InformationBox'
import Psd2iFrame from 'components/afterbanks/Psd2iFrame'

import './SignPsd2.scss'
import { ProductType } from 'constants/enums/productTypes'

export const SignPsd2Es = () => {
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const [hasSignedPsd2, setHasSignedPsd2] = useState<boolean>(
		loanApplication.hasSignedPsd2
	)
	const psd2Status = sessionStorage.getItem('psd2Status')
	const { t } = useTranslation()
	const showPsd2RadioBtns =
		loanApplication.applicationData?.product === ProductType.EspCashLoan
			? true
			: false

	useEffect(
		() => {
			dispatchLoanApplication({
				type: LOAN_APPLICATION_ACTIONS.SET_UPDATE_PSD2,
				payload: {
					...loanApplication,
					hasSignedPsd2: hasSignedPsd2,
				},
			})
		},
		[hasSignedPsd2] // eslint-disable-line react-hooks/exhaustive-deps
	)

	return (
		<div className="sign-psd2">
			{psd2Status === '200' ? (
				<InfoBox infoType="success" message={t('SuccessMessagePSD2')} />
			) : (
				<>
					{showPsd2RadioBtns ? (
						<div className="sign-psd2-radio-button">
							<div className="description">
								<Trans t={t} i18nKey="SignedWithPsd2"></Trans>
							</div>
							<div className="radio-buttons">
								<label className="radio-button">
									<input
										value="Yes"
										type="radio"
										name="psd2"
										checked={hasSignedPsd2}
										onChange={() => setHasSignedPsd2(true)}
									/>
									{t('Yes')}
								</label>
								<label className="radio-button">
									<input
										value="No"
										type="radio"
										name="psd2"
										checked={!hasSignedPsd2}
										onChange={() => setHasSignedPsd2(false)}
									/>
									{t('No')}
								</label>
							</div>
						</div>
					) : (
						<></>
					)}
					<div className="sign-psd2-description">
						<Trans t={t} i18nKey="Psd2Description"></Trans>
					</div>
					<Psd2iFrame showPsd2={!hasSignedPsd2} />
				</>
			)}
		</div>
	)
}
