import { useTranslation } from 'react-i18next'
import { TranslationFile } from 'i18n'
import queryString from 'query-string'
import { CookieType } from 'constants/enums/cookieTypes'

export const OtpStartFormEs = (data: any) => {
	const { t } = useTranslation([TranslationFile.Forms])

	const getQueryValues = () => {
		return queryString.parseUrl(
			sessionStorage.getItem(CookieType.RedirectUrl) ??
				window.location.search.toLowerCase(),
			{ parseNumbers: true }
		)
	}
	const applicationId = getQueryValues().query.applicationid

	return {
		fieldSetName: '',
		formName: 'LoginEs1',
		description: '',
		collapsable: false,
		visible: true,
		market: 'es',
		fullSize: true,
		col: 12,
		controls: [
			{
				required: true,
				controlName: 'applicationId',
				type: 'number',
				label: t('forms:ApplicationId'),
				value: applicationId,
				readonly: applicationId !== undefined,
				visible: true,
				col: 12,
				maxLength: 40,
				validations: [
					{
						code: 'MustMatchPattern',
						value: '^[0-9]*$',
						message: t('forms:ValidationMsgApplicationId'),
					},
				],
			},
			{
				required: true,
				controlName: 'ssn',
				type: 'text',
				label: t('forms:Ssn'),
				visible: true,
				col: 12,
				maxLength: 9,
				validations: [
					{
						code: 'MustMatchPattern',
						// NIF, NIE, CIF formats
						value: '([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])',
						message: t('forms:ValidationMsgSsn'),
					},
				],
			},
			{
				required: true,
				controlName: 'birthDate',
				type: 'text',
				label: t('forms:BirthDate'),
				description: 'DD/MM/YYYY',
				value: '',
				visible: true,
				col: 12,
				validations: [
					{
						code: 'MustMatchPattern',
						value: '^(\\b([0][1-9]|[12][0-9]|3[0-1]|[1-9])\\b)([/])(\\b([0][1-9]|[1][0-2]|[0-9]|[1-9])\\b)([/])(\\b([12]([9]|[0])[0-9][0-9])\\b)$',
						message: t('forms:ValidationMsgBirthdate'),
					},
				],
			},
		],
	}
}
