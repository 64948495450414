import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IApplicationContext } from 'interfaces/i-application-context-props'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { IApplicationUrlsDto } from 'interfaces/i-signing-urls'
import { useApplicationData } from 'hooks/useApplicationData'
import {
	getQueryValues,
	isPsd2Mandatory,
	isPsd2StatusSigned,
} from 'utils/loanHelpers'
import { InfoBox } from 'components/message-boxes/information-box/InformationBox'
import { onPSD2SignClick } from 'utils/psd2Helpers'

import './SignPsd2.scss'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

export const SignPsd2Dk = () => {
	const { t } = useTranslation()
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)
	const [signUrls, setSignUrls] = useState<IApplicationUrlsDto | undefined>(
		loanApplication.signingUrls
	)
	const searchParams = new URLSearchParams(
		window.location.search.toLowerCase()
	)
	const applicationId =
		searchParams.get('applicationid') ?? searchParams.get('userid')

	const { signingUrls } = useApplicationData({
		applicationId,
		t,
	})

	useEffect(
		() => {
			if (loanApplication.signingUrls === undefined) {
				setSignUrls(signingUrls)
			}
		},
		[signingUrls] //eslint-disable-line
	)

	if (
		isPsd2StatusSigned(loanApplication.applicationData!) &&
		isPsd2Mandatory(loanApplication.applicationData!)
	) {
		return (
			<div className="sign-psd2">
				<InfoBox
					infoType={'success'}
					message={t('SuccessMessagePSD2')}
				/>
			</div>
		)
	}

	return (
		<div className="sign-psd2">
			<div className="sign-psd2-container">
				<div>{t('Psd2Description')}</div>
				{(getQueryValues().psd2status === 'fail' ||
					getQueryValues().psd2status === 'abort') && (
					<div className="error-box-container">
						<ErrorBox message={t('SigningFailedMessagePSD2')} />
					</div>
				)}
				<div className="sign-psd2-button-container">
					<button
						className="btn btn-primary"
						disabled={
							loanApplication.applicationData === undefined ||
							signUrls === undefined
						}
						onClick={() =>
							onPSD2SignClick(
								loanApplication.applicationData!,
								signUrls!
							)
						}>
						{t('SIGNPSD2')}
					</button>
				</div>
			</div>
		</div>
	)
}
