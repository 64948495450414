import React, { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import './HeaderTooltip.scss'

interface IHeaderTooltip {
	translationString: string
	value: any
	secondValue?: any
}

export const HeaderTooltip = ({
	translationString,
	value,
	secondValue,
}: IHeaderTooltip) => {
	const [showTooltip, setShowTooltip] = useState(false)
	const { t } = useTranslation()

	const node = useRef<any>()

	const handleClick = (e: any) => {
		if (node.current?.contains(e.target)) {
			return
		}
		setShowTooltip(false)
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClick)
		return () => {
			document.removeEventListener('mousedown', handleClick)
		}
	})
	return (
		<div
			className="header-tooltip"
			ref={node}
			onClick={() => setShowTooltip(!showTooltip)}>
			{showTooltip && (
				<span className="tooltip-text">
					<div className="arrow-down"></div>
					{secondValue ? (
						<Trans t={t} i18nKey={translationString}>
							Including personal loan margin {{ value }} and
							reference rate {{ secondValue }}
						</Trans>
					) : (
						<Trans t={t} i18nKey={translationString}>
							Including personal loan margin and reference
							interest rate {{ value }}
						</Trans>
					)}
				</span>
			)}
		</div>
	)
}
