import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { InfoBox } from 'components/message-boxes/information-box/InformationBox'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'

export const SignPnSuccessMessage = () => {
	const { t } = useTranslation()
	const { msp, dispatchMsp } = useContext(MspDispatchContext)

	const next = async () => {
		dispatchMsp({
			type: MSP_ACTIONS.SET_INDEX,
			payload: {
				index: msp.index + 1,
			},
		})
	}

	return (
		<div className="pn-success-message">
			<InfoBox infoType="success" message={t('SuccessMessageSigning')} />
			<div className="sign-pn-next-button">
				<button
					type="button"
					className="btn btn-primary"
					onClick={() => next()}>
					{t('Next')}
				</button>
			</div>
		</div>
	)
}
