import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PpiNo } from '../Ppi/PpiNo'
import { MarketingConsentCheckbox } from '../MarketingConsent/MarketingConsent'
import AppContext from 'contexts/AppContext'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import { isPnStatusSigned, onPnSignClick } from 'utils/loanHelpers'
import updateApplicationServices from 'services/updateApplicationServices'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import { ApplicationType } from 'constants/enums/applicationTypes'
import { ReadMoreExpander } from 'components/read-more-expander/ReadMoreExpander'
import TrafficLights from 'components/trafficlights/TrafficLights'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'
import ButtonSpinner from 'components/button-spinner/ButtonSpinner'

import './SignPn.scss'

interface IErrorWithMessage {
	error: boolean
	errorMessage?: string
}

export const SignPnNo = () => {
	const { t } = useTranslation()
	const { applicationType } = useContext(AppContext)
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<IErrorWithMessage>({
		error: false,
		errorMessage: t('ErrorMsg'),
	})

	const next = () => {
		dispatchMsp({
			type: MSP_ACTIONS.SET_INDEX,
			payload: {
				index: msp.index + 1,
			},
		})
	}

	useEffect(() => {
		const checkPnStatus = () => {
			if (isPnStatusSigned(loanApplication.applicationData!)) {
				next()
			}
			return
		}
		checkPnStatus()
		//eslint-disable-next-line
	}, [loanApplication.applicationData])

	const signClick = () => {
		if (
			(loanApplication.applicationData?.isCoApplicant &&
				loanApplication.signingUrls?.coapplicantSigning === null) ||
			(!loanApplication.applicationData?.isCoApplicant &&
				loanApplication.signingUrls?.applicantSigning === null)
		) {
			setError({
				error: true,
				errorMessage: t('NoSigningUrlsError'),
			})
			setLoading(false)
		} else {
			onPnSignClick(
				loanApplication.applicationData!,
				loanApplication.signingUrls!,
				t
			)
		}
	}

	const updateAllowMarketing = async () => {
		try {
			await updateApplicationServices.updateCustomerConsent(
				loanApplication.customerConsents!,
				t
			)
		} catch (error) {
			console.warn(error)
			setError({
				error: true,
				errorMessage: t('ErrorUpdateMarketingConsent'),
			})
			setLoading(false)
		}
	}

	const updatePpiAndSignPn = async () => {
		try {
			updateApplicationServices
				.updatePpi(
					{
						ppi: loanApplication.hasAddedPpi!,
						applicationId:
							loanApplication.applicationData?.loanNumber!,
					},
					t
				)
				.then(() => {
					signClick()
				})
				.catch((error) => {
					setError({
						error: true,
						errorMessage: `${error}`,
					})
					setLoading(false)
				})
		} catch (error) {
			console.warn(error)
			setError({
				error: true,
				errorMessage: t('ErrorMsg'),
			})
			setLoading(false)
		}
	}

	const sign = async () => {
		setError({
			...error,
			error: false,
		})
		setLoading(true)
		if (
			loanApplication.applicationData?.customerConsents.allowMarketing !==
			true
		) {
			await updateAllowMarketing().then(() => {
				updatePpiAndSignPn()
			})
		} else {
			updatePpiAndSignPn()
		}
	}

	return (
		<div className="sign-pn">
			<div className="signing-section">
				{loanApplication.loading ||
				isPnStatusSigned(loanApplication.applicationData!) ? (
					<CircleSpinner />
				) : (
					<>
						<div
							className={
								'sign-pn-information-section ' +
								`${
									loanApplication.applicationData
										?.isCoApplicant
										? 'co-applicant'
										: ''
								}`
							}>
							<div>
								{applicationType === ApplicationType.Loan
									? t('PromissoryNoteSigningLine1')
									: t('PromissoryNoteCreditCardSigningLine1')}
							</div>
						</div>
						{loanApplication.applicationData?.isCoApplicant ||
						!loanApplication.applicationData?.ppi ? null : (
							<PpiNo />
						)}
						{!loanApplication.applicationData?.customerConsents
							.allowMarketing ? (
							<div className="marketing-consent-section norway">
								<MarketingConsentCheckbox />
							</div>
						) : null}
						<ReadMoreExpander
							expanded={false}
							showExpander={true}
							contentTop={true}
							collapsedHeight="2rem"
							className="traffic-lights-expander"
							children={
								<TrafficLights
									rdi={loanApplication.applicationData?.rdi}
								/>
							}
						/>
						{error.error ? (
							<ErrorBox message={error.errorMessage!} />
						) : null}
						<div className="sign-pn-button-section">
							<button
								className="btn btn-primary"
								onClick={() => sign()}
								disabled={loading}>
								{loading ? (
									<div className="sign-pn-spinner-container">
										<ButtonSpinner />
									</div>
								) : (
									t('SIGNPN')
								)}
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	)
}
