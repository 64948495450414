import { TFunction } from 'i18next'
import appConfig from 'app.config'
import { IApplicationInformation } from 'interfaces/i-application-information'
import { getLanguageLocale } from 'utils/countryHelpers'

export function getCCHeaderValues(
	t: TFunction,
	application?: IApplicationInformation
) {
	let applicationArray
	switch (appConfig.country) {
		case 'no':
			applicationArray = [
				{
					text: 'ApplicationRegistered',
					// value: `${application?.dateCreated}`,
					value: new Date(
						application?.dateCreated!
					).toLocaleDateString(getLanguageLocale()),
				},
				{
					text: 'ApplicationNumber',
					value: `${application?.loanNumber}`,
				},
				{
					text: 'InterestRate',
					value: '22,65%',
				},
				{
					text: 'InterestRateOnWithdrawal',
					value: '26,79%',
				},
			]
			break
		default:
			applicationArray = [
				{
					text: 'ApplicationRegistered',
					value: `${application?.dateCreated}`,
				},
				{
					text: 'ApplicationNumber',
					value: `${application?.loanNumber}`,
				},
				{
					text: 'InterestRate',
					value: 'NEED ADDED',
				},
				{
					text: 'InterestRateOnWithdrawal',
					value: 'NEED ADDED',
				},
			]
	}
	return applicationArray
}
