import { TranslationFile } from 'i18n'
import { IOffer } from 'interfaces/i-application-information'
import { useTranslation } from 'react-i18next'

interface ILoanOfferVals extends IOffer {
	amountDefaultValue: number
	repaymentDefaultValue: number
}

export const ApplicationLoanOfferForm = (data: ILoanOfferVals) => {
	const { t } = useTranslation([TranslationFile.Forms])

	return {
		fieldSetName: '',
		formName: 'LoanOfferForm',
		collapsable: false,
		show: true,
		visible: true,
		market: 'es',
		fullSize: true,
		col: 12,
		controls: [
			{
				required: true,
				controlName: 'amount',
				type: 'slider',
				label: t('forms:Amount'),
				value: data?.amountDefaultValue,
				minValue: data?.amountMin,
				maxValue: data?.amountMax,
				stepping: 500,
				visible: true,
				col: 12,
			},
			{
				required: true,
				controlName: 'repaymentPeriod',
				type: 'slider',
				label: t('forms:RepaymentPeriod'),
				value: data?.repaymentDefaultValue,
				minValue: data?.repaymentPeriodMin,
				maxValue: data?.repaymentPeriodMax,
				stepping: 6,
				visible: true,
				col: 12,
			},
		],
	}
}
