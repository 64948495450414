export enum Provinces {
	P01 = 'Álava (Vitoria)',
	P02 = 'Albacete',
	P03 = 'Alicante',
	P04 = 'Almería',
	P05 = 'Ávila',
	P06 = 'Badajoz',
	P07 = 'Baleares (Palma de Mallorca)',
	P08 = 'Barcelona',
	P09 = 'Burgos',
	P10 = 'Cáceres',
	P11 = 'Cádiz',
	P12 = 'Castellón',
	P13 = 'Ciudad Real',
	P14 = 'Córdoba',
	P15 = 'Coruña',
	P16 = 'Cuenca',
	P17 = 'Gerona',
	P18 = 'Granada',
	P19 = 'Guadalajara',
	P20 = 'Guipúzcoa (San Sebastián)',
	P21 = 'Huelva',
	P22 = 'Huesca',
	P23 = 'Jaén',
	P24 = 'León',
	P25 = 'Lérida',
	P26 = 'La Rioja (Logroño)',
	P27 = 'Lugo',
	P28 = 'Madrid',
	P29 = 'Málaga',
	P30 = 'Murcia',
	P31 = 'Navarra (Pamplona)',
	P32 = 'Orense',
	P33 = 'Asturias (Oviedo)',
	P34 = 'Palencia',
	P35 = 'Las Palmas',
	P36 = 'Pontevedra',
	P37 = 'Salamanca',
	P38 = 'Santa Cruz de Tenerife',
	P39 = 'Cantabria (Santander)',
	P40 = 'Segovia',
	P41 = 'Sevilla',
	P42 = 'Soria',
	P43 = 'Tarragona',
	P44 = 'Teruel',
	P45 = 'Toledo',
	P46 = 'Valencia',
	P47 = 'Valladolid',
	P48 = 'Vizcaya (Bilbao)',
	P49 = 'Zamora',
	P50 = 'Zaragoza',
	P51 = 'Ceuta',
	P52 = 'Melilla',
}
