import appConfig from 'app.config'
import { logError, logEvent } from 'appinsights/appinsights'
import {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
} from 'axios'
import { waitForLanguage } from 'utils/commonhelpers'

const onRequest = async (
	config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
	config.headers['Accept-Language'] = await waitForLanguage()
	consoleLog(`[request] [${JSON.stringify(config)}]`)
	logEvent('ApplicationPageRequest', { config: `${JSON.stringify(config)}` })
	return config
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
	consoleErr(`[request error] [${error.toJSON}]`)
	logError(
		{
			...error,
			message: `${error.message} with message: "${error.request?.data.message}"`,
		},
		{ ErrorMessage: error.request?.data.message }
	)
	return Promise.reject(error)
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
	consoleLog(`[response] [${JSON.stringify(response)}]`)
	logEvent('ApplicationPageResponse', {
		url: response.config.url,
		status: response.status,
		data: response.data,
	})
	return response
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
	consoleErr(`[response error] [${JSON.stringify(error)}]`)
	logError(
		{
			...error,
			message: `${error.message} with message: "${error.response?.data.message}"`,
		},
		{ ErrorMessage: error.response?.data.message }
	)

	return Promise.reject(error)
}

const logInConsole = appConfig.environment !== 'production'
const consoleLog = (message: string) => {
	if (logInConsole) console.log(message)
}
const consoleErr = (message: string) => {
	if (logInConsole) console.error(message)
}

export function setUpApplicationInterceptorsFor(
	instance: AxiosInstance
): AxiosInstance {
	instance.interceptors.request.use(onRequest, onRequestError)
	instance.interceptors.response.use(onResponse, onResponseError)
	return instance
}
