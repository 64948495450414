import { useTranslation } from 'react-i18next'
import appConfig from 'app.config'
import { AllCountries, CountryA3 } from 'constants/enums/countryTypes'
import { TranslationFile } from 'i18n'

export function getMarket() {
	switch (appConfig.country) {
		case 'de':
			return 'Germany'
		case 'no':
			return 'Norway'
		case 'at':
			return 'Austria'
		case 'se':
			return 'Sweden'
		case 'fi':
			return 'Finland'
		case 'dk':
			return 'Denmark'
		case 'ee':
			return 'Estonia'
		case 'lt':
			return 'Lithuania'
		case 'lv':
			return 'Latvia'
		case 'es':
			return 'Spain'
	}
}

export function getCountryA3() {
	switch (appConfig.country) {
		case 'de':
			return CountryA3.de
		case 'no':
			return CountryA3.no
		case 'at':
			return CountryA3.at
		case 'se':
			return CountryA3.se
		case 'fi':
			return CountryA3.fi
		case 'dk':
			return CountryA3.dk
		case 'es':
			return CountryA3.es
		case 'ee':
			return CountryA3.ee
		case 'lt':
			return CountryA3.lt
		case 'lv':
			return CountryA3.lv
	}
}

export function getCountryA3ForCurrentMarket() {
	return CountryA3[appConfig.country as keyof typeof CountryA3]
}

export function getLanguageLocale() {
	switch (appConfig.country) {
		case 'se':
			return 'sv-SE'
		case 'fi':
			return 'fi-FI'
		case 'ee':
			return 'et-EE'
		case 'lt':
			return 'lt-LT'
		case 'lv':
			return 'lv-LV'
		case 'de':
			return 'de-DE'
		case 'no':
			return 'nb-NO'
		case 'at':
			return 'de-AT'
		case 'dk':
			return 'da-DK'
		case 'es':
			return 'es-ES'
		default:
			return 'system'
	}
}

/**
 * Getting currency from market in appconfig settings
 * @returns Currency as string
 */
export function getCurrency() {
	switch (appConfig.country) {
		case 'se':
			return 'SEK'
		case 'fi':
		case 'ee':
		case 'de':
		case 'at':
		case 'es':
		case 'lt':
		case 'lv':
			return 'EUR'
		case 'no':
			return 'NOK'
		case 'dk':
			return 'DKK'
	}
}

/**
 * Gets and sets all countries from countrytypes.tsx for dropdowns listing AllCountries.
 * Translates the countries from locales\{country}\countries.json.
 * Sorts alphabetically but also sets country of chosen market as first choice in menu.
 * @param market
 * @returns
 */
export const GetAllCountriesWithTranslation = (market: string) => {
	const { t } = useTranslation([TranslationFile.Countries])
	let options: any = []
	let countriesUnsorted: any = []
	let countriesSorted: any = []
	let countriesFiltered: any = []

	Object.keys(AllCountries).map((key: string) =>
		countriesUnsorted.push({
			value: key,
			label: t(`${AllCountries[key as keyof typeof AllCountries]}`),
		})
	)
	options.push(
		countriesUnsorted.find(
			(item: { value: string; label: string }) =>
				item.value === market.toUpperCase()
		)
	)
	countriesFiltered = countriesUnsorted.filter(
		(item: { value: string; label: string }) =>
			item.value !== market.toUpperCase()
	)
	countriesSorted = countriesFiltered.sort(
		(
			a: { value: string; label: string },
			b: { value: string; label: string }
		) => {
			const countryA = a.label
			const countryB = b.label
			if (
				countryA.localeCompare(countryB, market) <
				countryB.localeCompare(countryA, market)
			) {
				return -1
			}
			if (
				countryA.localeCompare(countryB, market) >
				countryB.localeCompare(countryA, market)
			) {
				return 1
			}
			return 0
		}
	)
	options.push(...countriesSorted)
	return options
}
