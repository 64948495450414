import { TFunction } from 'i18next'
import Axios from 'axios'

import { setUpApplicationInterceptorsFor } from './interceptors'
import { cdaApiRoutes } from 'constants/routes/cdaApiRoutes'
import { getHeadersWithJwtToken } from 'utils/authHelpers'
import { IUpdateApplicationInformation } from 'interfaces/i-update-application'
import { IAddPublicNote } from 'interfaces/i-add-public-note'
import { IPpiData } from 'interfaces/i-ppi'
import { IUpdateCustomerConsents } from 'interfaces/i-update-customer-consents'
import { IUpdateMissingKyc } from 'interfaces/i-update-missing-kyc'
import { ISblData } from 'interfaces/i-sbl'

const transportWithCredentials = setUpApplicationInterceptorsFor(
	Axios.create({})
)

transportWithCredentials.defaults.headers.post['Content-Type'] =
	'application/json'
transportWithCredentials.defaults.headers.post['Access-Control-Allow-Origin'] =
	'*'
transportWithCredentials.defaults.headers.post['Access-Control-Allow-Headers'] =
	'Origin, X-Requested-With, Content-Type, Accept'

const updateApplicationServices = {
	async updateLoanApplication(
		loanApplicationData: IUpdateApplicationInformation,
		t: TFunction
	) {
		const data = await transportWithCredentials.post(
			cdaApiRoutes.updateApplicationInformation,
			{
				applicationId: loanApplicationData.applicationId,
				product: loanApplicationData.product,
				applicant: loanApplicationData.applicant,
				bankInfo: loanApplicationData.bankInfo,
			},
			await getHeadersWithJwtToken()
		)
		if (data.status === 200) {
			return data
		}
		throw new Error(
			'Something went wrong updating loan application information.'
		)
	},

	async addPublicNote(addPublicNoteData: IAddPublicNote, t: TFunction) {
		if (!addPublicNoteData) return
		const data = await transportWithCredentials.post(
			cdaApiRoutes.addPublicNote,
			addPublicNoteData,
			await getHeadersWithJwtToken()
		)
		if (data.status === 200) return
		throw new Error(t('Something went wrong tryling to update public note'))
	},

	async updatePpi(ppiData: IPpiData, t: TFunction) {
		const data = await transportWithCredentials.post(
			cdaApiRoutes.updatePpi,
			ppiData,
			await getHeadersWithJwtToken()
		)
		if (data.status === 200) return data.status
		throw new Error(t('PpiError'))
	},

	async updateCustomerConsent(
		customerConsents: IUpdateCustomerConsents,
		t: TFunction
	) {
		let data = await transportWithCredentials.post(
			cdaApiRoutes.updateCustomerConsents,
			customerConsents,
			await getHeadersWithJwtToken()
		)

		if (data.status === 200) {
			return data.status
		}
		throw new Error(t('ErrorMsg'))
	},

	async updateMissingKyc(
		updateMissingKycData: IUpdateMissingKyc,
		t: TFunction
	) {
		if (!updateMissingKycData) return

		const data = await transportWithCredentials.post(
			cdaApiRoutes.updateMissingKyc,
			updateMissingKycData,
			await getHeadersWithJwtToken()
		)
		if (data.status === 200) return
		throw new Error(t('UpdatingMissingKycError'))
	},

	async updateSbl(sblData: ISblData, t: TFunction) {
		try {
			const data = await transportWithCredentials.post(
				cdaApiRoutes.updateSbl,
				sblData,
				await getHeadersWithJwtToken()
			)
			if (data.status === 200) return
			throw new Error(t('SblError'))
		} catch (error) {
			console.log(error)
			throw new Error(t('SblError'))
		}
	},
}

export default updateApplicationServices
