import React, { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { IApplicationContext } from 'interfaces/i-application-context-props'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import { DocumentTypes } from 'constants/enums/documentTypes'
import { getQueryValues } from 'utils/loanHelpers'
import { UrlStatus } from 'constants/enums/signingUrlTypes'
import { UploadDocumentsDropZoneItems } from 'components/application-blocks/common-blocks/UploadDocuments/UploadDocumentsDropZoneItems'
import { DocumentTypesNorway } from 'constants/values/uploadDocumentsTypes'

import './UploadDocuments.scss'

export const UploadDocumentsNo = () => {
	const { t } = useTranslation()
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)
	const [psd2Signed, setPsd2Signed] = useState<boolean>()

	const documentsNeedUpload = [DocumentTypes.TaxReturn, DocumentTypes.Payroll]

	const isPSD2StatusConsent = () =>
		loanApplication?.applicationData?.signingUrls?.psD2?.status! ===
			UrlStatus.Signed || getQueryValues().status === 'ok'

	const back = () => {
		dispatchMsp({
			type: MSP_ACTIONS.SET_INDEX,
			payload: {
				index: msp.index - 1,
			},
		})
	}

	useEffect(
		() => {
			if (loanApplication.hasSignedPsd2 || isPSD2StatusConsent()) {
				setPsd2Signed(true)
			} else {
				setPsd2Signed(false)
			}
		},
		[loanApplication.hasSignedPsd2, loanApplication.applicationData] //eslint-disable-line
	)

	return (
		<div className="upload-documents norway">
			<div className="upload-documents-description-top">
				{loanApplication.hasSignedPsd2 ||
				loanApplication.applicationData?.signingUrls.psD2.status ===
					UrlStatus.Signed ? (
					<>{t('UploadDocumentText2')}</>
				) : (
					<Trans t={t} i18nKey="UploadDocumentText">
						<>
							You have decided NOT to share psd2 data with us, we
							therefore require you to upload some documents for
							us. We need this information to process your
							application.
						</>
						<button
							className="btn btn-link-gray-11"
							onClick={() => back()}>
							If you wish you may go back and share psd2 data with
							us instead.
						</button>
					</Trans>
				)}
			</div>
			<UploadDocumentsDropZoneItems
				documentTypes={DocumentTypesNorway}
				applicationData={loanApplication}
				documentsNeedUpload={documentsNeedUpload}
				psd2Status={psd2Signed}
			/>
			{!psd2Signed ? (
				<div className="upload-documents-back-button-section">
					<button
						className="btn btn-secondary dark-gray"
						onClick={() => back()}>
						{t('Back')}
					</button>
				</div>
			) : null}
		</div>
	)
}
