import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReadMoreExpander } from 'components/read-more-expander/ReadMoreExpander'
import { checkIsMobile } from 'utils/commonhelpers'
import { InfoBox } from 'components/message-boxes/information-box/InformationBox'

import './LoanApplicationHeader.scss'

const LoanApplicationHeader = () => {
	const { t } = useTranslation()

	return (
		<div className="loan-application-header">
			<h1 className="top-header">{t('LoanApplicationHeader')}</h1>
			<hr />
			<ReadMoreExpander
				expanded={!checkIsMobile()}
				showExpander={checkIsMobile()}
				collapsedHeight="10rem"
				contentTop={true}
				children={
					<InfoBox
						infoType="info"
						message={t('LoanApplicationText')}
					/>
				}
			/>
		</div>
	)
}

export default LoanApplicationHeader
