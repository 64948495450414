import React, { useContext, useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import download_interface_cream from 'img/document-icons/download-interface-cream.svg'
import { DocumentTypes } from 'constants/enums/documentTypes'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { IDocuments } from 'interfaces/i-documents'
import { downloadBlob } from 'utils/documentHelpers'
import documentServices from 'services/documentServices'
import ButtonSpinner from 'components/button-spinner/ButtonSpinner'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { AuthErrorMessage } from 'components/message-boxes/auth-error-box/AuthErrorBox'

interface IProps {
	buttonName: string
	documentType: DocumentTypes
}

export const ThankYouDownloadPnButton = ({
	buttonName,
	documentType,
}: IProps) => {
	const { t } = useTranslation()
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)

	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<boolean>(false)
	const [pnDocument, setPnDocument] = useState<IDocuments>()

	const getDocuments = useCallback(() => {
		let documents: IDocuments[] = loanApplication.documentData!
		documents.map((document) => {
			if (document.type === documentType) {
				return setPnDocument(document)
			}
			return pnDocument
		})
	}, [loanApplication.documentData, pnDocument])

	const downloadPn = async (documentId: any, fileName: string) => {
		setLoading(true)
		try {
			const { blob }: any =
				await documentServices.getDownloadDocumentById(documentId)
			downloadBlob(blob as Blob, fileName)
		} catch (error) {
			console.warn(error)
			setError(true)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		getDocuments()
	}, [getDocuments])

	return (
		<div className="thank-you-printpn-button-container">
			{pnDocument !== undefined && (
				<button
					type="button"
					className="btn btn-primary download-pn-button"
					disabled={loading}
					onClick={() => {
						downloadPn(pnDocument.documentId, pnDocument.fileName)
					}}>
					{loading ? (
						<div className="download-pn-spinner">
							<ButtonSpinner />
						</div>
					) : (
						<>
							<div>{t(buttonName)}</div>
							<img
								src={download_interface_cream}
								alt="download img"
							/>
						</>
					)}
				</button>
			)}
			{error ? (
				<AuthErrorMessage css="error" message={t('DownloadError')} />
			) : null}
		</div>
	)
}
