import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import './LoanOffer.scss'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import updateBankInfoServices from 'services/updateBankInfoServices'
import creditDecisionServices from 'services/creditDecisionServices'
import LoanOfferHeader from 'components/application-headers/loan-offer-headers/loan-offer-header/LoanOfferHeader'
import getApplicationServices from 'services/getApplicationServices'
import documentServices from 'services/documentServices'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import Calculator from './Calculator/Calculator'
import { UpdateBankInfoEs } from '../UpdateBankInfo/UpdateBankInfoEs'
import { Decision } from 'constants/enums/decision'
import AppContext from 'contexts/AppContext'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

const LoanOfferEs = () => {
	const { t } = useTranslation()
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)
	const { setHeader } = useContext(AppContext)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)
	const [loading, setLoading] = useState<boolean>(true)
	const [error, setError] = useState<Error | any>()
	const [disabled, setDisabled] = useState<boolean>(true)
	const applicationId = loanApplication.applicationData?.loanNumber!

	useEffect(() => {
		getApplicationInformation(applicationId)
	}, []) //eslint-disable-line

	const updateBankInfo = async () => {
		setLoading(true)
		setError(null)
		try {
			await updateBankInfoServices
				.updateBankInfo({
					applicationId: applicationId,
					bankInfo: loanApplication.updateApplicationData?.bankInfo!,
				})
				.then((response) => {
					if (response === 200) {
						updateLoanData()
					}
				})
		} catch (error) {
			console.warn(error)
			setError(error)
			setLoading(false)
		}
	}

	const updateLoanData = async () => {
		try {
			await creditDecisionServices
				.updateLoanData(
					applicationId,
					{
						amount: loanApplication.updateApplicationData?.loanData
							?.amount!,
						repaymentPeriod:
							loanApplication.updateApplicationData?.loanData
								?.repaymentPeriod!,
					},
					t
				)
				.then(async (response) => {
					if (response?.status === 200) {
						await getApplicationInformation(applicationId).then(
							() => {
								setHeader(<LoanOfferHeader />)
								dispatchMsp({
									type: MSP_ACTIONS.SET_INDEX,
									payload: {
										index: msp.index + 1,
									},
								})
							}
						)
					}
				})
		} catch (error) {
			console.warn(error)
			setError(error)
			setLoading(false)
		}
	}

	const getApplicationInformation = async (applicationId: number) => {
		setLoading(true)
		try {
			await getApplicationServices
				.GetApplicationInformation(applicationId, t)
				.then((response) => {
					loanApplication.setApplicationData(response)
				})
			await documentServices
				.getDownloadDocuments(applicationId.toString(), t)
				.then((response) => {
					loanApplication.setDocumentData(response)
				})
		} catch (error) {
			console.warn(error)
			setError(error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (
			!loanApplication.updateApplicationData?.loanData
				?.maxInstalmentWarning &&
			loanApplication.updateApplicationData?.bankInfo?.iban !==
				undefined &&
			loanApplication.updateApplicationData?.bankInfo?.iban !== ''
		) {
			setDisabled(false)
		} else {
			setDisabled(true)
		}
	}, [
		loanApplication.updateApplicationData?.loanData,
		loanApplication.updateApplicationData?.bankInfo?.iban,
	])

	return (
		<div className="loan-offer">
			{loading ? (
				<div className="col-spinner">
					<CircleSpinner />
				</div>
			) : (
				<>
					{loanApplication.applicationData?.offer === undefined ? (
						<ErrorBox message={`${t('LoanInfoError')}`} />
					) : (
						<>
							<Calculator />
							{loanApplication.applicationData?.decision ===
							Decision.Approved ? (
								<UpdateBankInfoEs />
							) : null}
							{error ? (
								<ErrorBox message={t('ErrorMsg')} />
							) : null}
							<div className="loan-offer-next-button">
								<button
									className="btn btn-primary"
									disabled={disabled}
									onClick={() => updateBankInfo()}>
									{t('Next')}
								</button>
							</div>
						</>
					)}
				</>
			)}
		</div>
	)
}

export default LoanOfferEs
