import React, { useContext, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Image } from 'react-bootstrap'

import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import { userManager } from 'utils/authHelpers'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

import tfbank21_logo_white from 'img/tf-logos/tfbank21-logo-white.svg'
import './LogoutPage.scss'
import AuthContext from 'contexts/AuthContext'

function LogoutPage() {
	const [error, setError] = React.useState<Error>()
	const { t } = useTranslation()

	const { isAuthenticated } = useContext(AuthContext)
	useEffect(() => {
		console.log('is auth', isAuthenticated)
		isAuthenticated &&
			userManager.signoutRedirect({
				id_token_hint: sessionStorage.getItem('id_token'),
			})
		userManager.clearStaleState()
		// eslint-disable-next-line
	}, [])

	const ErrorButton = () => {
		return (
			<button
				className="btn btn-outline-danger"
				onClick={() => setError(undefined)}>
				{t('Close')}
			</button>
		)
	}

	return (
		<div id="logout-page">
			<div className="logout-container">
				<Image
					src={tfbank21_logo_white}
					className="tfbank-logo logout"
					alt="TF Bank AB"
				/>
				{error ? (
					<div className="error-container">
						<ErrorBox
							message={error.message}
							button={<ErrorButton />}
						/>
					</div>
				) : (
					<div className="spinner-container">
						<CircleSpinner />
					</div>
				)}
			</div>
		</div>
	)
}

export default LogoutPage
