import queryString from 'query-string'
import { TFunction } from 'i18next'
import { CookieType } from 'constants/enums/cookieTypes'
import updateApplicationServices from 'services/updateApplicationServices'
import { ISblData } from 'interfaces/i-sbl'
import { IApplicationUrlsDto } from 'interfaces/i-signing-urls'
import { IApplicationInformation } from 'interfaces/i-application-information'

export const updatePxSbl = (t: TFunction) => {
	let _queryValues = queryString.parseUrl(
		window.location.search.toLowerCase()
	).query
	const hasUrlQuery =
		_queryValues.authorizationcode || _queryValues.failedauthorizationcode
	if (sessionStorage.getItem(CookieType.Sbl) === 'ok') return

	if (hasUrlQuery) {
		//logic parse data, call sbl endpoint, fail message,
		let data = {
			applicationId: _queryValues.applicationid as unknown as number,
			authCode: hasUrlQuery,
			isSuccessful: _queryValues.status === 'ok',
		} as ISblData
		updateApplicationServices
			.updateSbl(data, t)
			.then(() =>
				sessionStorage.setItem(
					CookieType.Sbl,
					_queryValues.status as string
				)
			)
			.catch((error: Error) => {
				console.log(error)
			})
	}
}

export const onPSD2SignClick = (
	applicationData: IApplicationInformation,
	signingUrls: IApplicationUrlsDto
) => {
	const url = applicationData?.isCoApplicant
		? signingUrls?.coapplicantPsd2
		: signingUrls?.applicantPsd2
	window.location.href = url
}
