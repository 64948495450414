import React from 'react'

import './AuthErrorBox.scss'

interface IAuthErrorMessage {
	message: string
	css?: string
}

export const AuthErrorMessage = ({ css, message }: IAuthErrorMessage) => (
	<div className={`auth-error-message ${css ?? ''}`}>{message}</div>
)
