import React, { createContext, Context, useState, useEffect } from 'react'
import Axios from 'axios'
import appConfig from 'app.config'
import { waitForLanguage } from 'utils/commonhelpers'
import { IMaintenance } from 'interfaces/i-maintenance'
import { logError } from 'appinsights/appinsights'
import { getMarket } from 'utils/countryHelpers'
import { cdaApiRoutes } from 'constants/routes/cdaApiRoutes'
import MaintenanceWindow from 'components/maintenance-window/MaintenanceWindow'

const transportWithCredentials = Axios.create({})
transportWithCredentials.interceptors.request.use(async (config) => {
	config.headers['Accept-Language'] = await waitForLanguage()
	return config
})

interface IMaintenanceContext {
	maintenanceData: IMaintenance | undefined
	setMaintenanceData: Function
}

const MaintenanceContext: Context<IMaintenanceContext> =
	createContext<IMaintenanceContext>({
		maintenanceData: undefined,
		setMaintenanceData: () => {},
	})

export function MaintenanceContextProvider() {
	const [maintenanceData, setMaintenanceData] = useState<IMaintenance>()

	const maintenanceInfo = {
		async getMaintenanceInfo() {
			const response = await transportWithCredentials
				.get<IMaintenance>(
					cdaApiRoutes.getMaintenanceInfo(getMarket()!)
				)
				.then((result) => {
					if (result.status === 204) return undefined
					return result.data
				})
				.catch((err) => {
					logError(err)
					if (err.response?.status === 404) {
						throw new Error(
							'There is no maintenancedata for chosen market'
						)
					} else {
						throw err
					}
				})
			return response as IMaintenance
		},
	}
	const getMaintenanceInfo = async () => {
		try {
			let data = await maintenanceInfo.getMaintenanceInfo()
			setMaintenanceData(data)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		async function checkMaintenanceInfo() {
			if (await maintenanceInfo) {
				getMaintenanceInfo()
			}
		}
		checkMaintenanceInfo()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{maintenanceData &&
				maintenanceData.affectedMarkets.includes(appConfig.country) && (
					<MaintenanceContext.Provider
						value={{ maintenanceData, setMaintenanceData }}>
						<MaintenanceWindow />
					</MaintenanceContext.Provider>
				)}
		</>
	)
}

export default MaintenanceContext
