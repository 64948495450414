export enum CustomerForm {
	ApplicationMissingKyc = 'ApplicationMissingKyc',
	ApplicationPersonalInformationForm = 'ApplicationPersonalInformationForm',
	ApplicationAddressForm = 'ApplicationAddressForm',
	ApplicationEmploymentForm = 'ApplicationEmploymentForm',
	ApplicationExpensesForm = 'ApplicationExpensesForm',
	ApplicationContactInformationForm = 'ApplicationContactInformationForm',
	ApplicationLoanOfferForm = 'ApplicationLoanOfferForm',
	ApplicationBankInfoForm = 'ApplicationBankInfoForm',
	OtpStartFormEs = 'OtpStartFormEs',
	OtpCompleteForm = 'OtpCompleteForm',
}
