// import { TranslationFile } from 'i18n'
import appConfig from 'app.config'
import { AccommodationType } from 'constants/enums/accommodationTypes'
import { PropertyType } from 'constants/enums/propertyTypes'
import { TranslationFile } from 'i18n'
import { IApplicationInformation } from 'interfaces/i-application-information'
import { useTranslation } from 'react-i18next'
import { FormatEnumToList, checkIsMobile } from 'utils/commonhelpers'
import { GetAllCountriesWithTranslation } from 'utils/countryHelpers'
import { formatToStringLocale } from 'utils/dateAndTimeHelpers'

interface IProps {
	applicationData: IApplicationInformation
	province: string | undefined
}

export const ApplicationAddressForm = (data: IProps) => {
	// const { t } = useTranslation([TranslationFile.Forms])
	const { t } = useTranslation()
	const isMobile: boolean = checkIsMobile()

	return {
		fieldSetName: '',
		formName: 'ApplicationAddressForm',
		description: t('forms:AddressDescription'),
		collapsable: false,
		show: true,
		visible: true,
		market: 'es',
		fullSize: true,
		col: 12,
		controls: [
			{
				required: true,
				controlName: 'type',
				type: 'dropdown',
				label: t('forms:AccommodationType'),
				options: FormatEnumToList(
					AccommodationType,
					PropertyType.AccommodationType,
					TranslationFile.Dropdown
				),
				value: data.applicationData?.accommodation?.type,
				visible: true,
				col: 12,
			},
			{
				required: true,
				controlName: 'since',
				type: 'text',
				label: t('forms:SinceDate'),
				description: 'DD/MM/YYYY',
				value: formatToStringLocale(
					data.applicationData?.accommodation?.since
				),
				validations: [
					{
						code: 'MustMatchPattern',
						value: '^(\\b([0][1-9]|[12][0-9]|3[0-1]|[1-9])\\b)([/])(\\b([0][1-9]|[1][0-2]|[0-9]|[1-9])\\b)([/])(\\b([12]([9]|[0])[0-9][0-9])\\b)$',
						message: t('forms:ValidationMsgBirthdate'),
					},
				],
				visible: true,
				col: 12,
			},
			{
				required: false,
				controlName: 'accommodationCost',
				type: 'number',
				label: t('forms:AccommodationCost'),
				value: data.applicationData?.accommodation?.cost,
				validations: [
					{
						code: 'MustMatchPattern',
						value: '^[0-9]*$',
						message: t('forms:ValidationMsgValue'),
					},
				],
				visible: true,
				col: 12,
			},
			{
				required: true,
				controlName: 'streetType',
				type: 'text',
				label: t('forms:StreetType'),
				value: data.applicationData?.address?.streetType,
				visible: true,
				col: 12,
			},
			{
				required: true,
				controlName: 'street',
				type: 'text',
				label: t('forms:Street'),
				options: GetAllCountriesWithTranslation(appConfig.country),
				value: data.applicationData?.address?.street,
				visible: true,
				col: 12,
			},
			{
				required: true,
				controlName: 'streetNumber',
				type: 'text',
				label: t('forms:StreetNumber'),
				value: data.applicationData?.address?.streetNumber,
				visible: true,
				col: isMobile ? 12 : 6,
			},
			{
				required: false,
				controlName: 'flatNumber',
				type: 'text',
				label: t('forms:FlatNumber'),
				value: data.applicationData?.address?.flatNumber,
				visible: true,
				col: isMobile ? 12 : 6,
			},
			{
				required: true,
				controlName: 'zip',
				type: 'text',
				label: t('forms:Zip'),
				value: data.applicationData?.address?.zip,
				visible: true,
				col: isMobile ? 12 : 5,
				validations: [
					{
						code: 'MustMatchPattern',
						value: '^(\\b\\d{5}\\b)$',
						message: t('ValidationMsgZip'),
					},
				],
			},
			{
				required: true,
				controlName: 'city',
				type: 'text',
				label: t('forms:City'),
				value: data.applicationData?.address?.city,
				visible: true,
				col: isMobile ? 12 : 7,
			},
		],
	}
}
