import React, { useContext, useEffect } from 'react'
import { FormBuilder, formKeySelector } from '@tf/formbuilder'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import { IApplicationInformation } from 'interfaces/i-application-information'
import { getLanguageLocale, getMarket } from 'utils/countryHelpers'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { FormFactory } from 'formBuilder/formFactory'
import { CustomerForm } from 'constants/enums/formTypes'

import './UpdateBankInfo.scss'

export const UpdateBankInfoEs = () => {
	const { t } = useTranslation()
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)

	let formSchema = FormFactory(
		CustomerForm.ApplicationBankInfoForm,
		{
			formName: 'bankInfoForm',
			blockName: 'bankInfoForm',
			market: getMarket(),
			locale: getLanguageLocale(),
			getDataUrl: '',
			validationErrorList: false,
			requiredMessage: t('ValidateRequired'),
		},
		loanApplication.applicationData as IApplicationInformation
	)

	const formStateValues = useSelector(
		formKeySelector(formSchema.formMeta.formName, 'controlsState')
	)

	const updateBankInfo = (value: string | undefined) => {
		dispatchLoanApplication({
			type: LOAN_APPLICATION_ACTIONS.SET_UPDATE_APPLICATIONDATA,
			payload: {
				...loanApplication,
				updateApplicationData: {
					...loanApplication.updateApplicationData,
					bankInfo: {
						...loanApplication.updateApplicationData?.bankInfo,
						iban: value?.replaceAll(' ', '').toUpperCase(),
						directDebitIban: value
							?.replaceAll(' ', '')
							.toUpperCase(),
					},
				},
			},
		})
	}

	useEffect(
		() => {
			updateBankInfo(formStateValues?.ibanVal.value)
		},
		[formStateValues?.ibanVal.value] // eslint-disable-line react-hooks/exhaustive-deps
	)

	return (
		<div className="update-bank-info">
			<div className="update-bank-info-header">
				<h3>{t('UpdateIbanHeader')}</h3>
				<hr />
			</div>
			{formSchema ? (
				<FormBuilder
					formSchema={formSchema}
					apiError={undefined}
					showErrorsInForm={true}
					showSubmitButton={false}
				/>
			) : (
				<CircleSpinner />
			)}
		</div>
	)
}
