import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IDocuments } from 'interfaces/i-documents'
import { DocumentTypes } from 'constants/enums/documentTypes'
import { IDocumentDto } from 'interfaces/i-upload-documents'
import download_interface_bronze from 'img/document-icons/download-interface-bronze.svg'
import upload_document from 'img/document-icons/upload-document.svg'

interface IDropZone {
	files: IDocumentDto[]
	setFiles: Function
	text: string
	maxFiles: number
	documentType: DocumentTypes
	icon?: string
	accept?: string
	dropHeading?: string
	disabled?: boolean
	downloadDocument?: Function
	documentsUploaded?: IDocuments[]
	bottomText?: string
}

function DropZone({
	files,
	setFiles,
	text,
	maxFiles,
	documentType,
	icon = upload_document,
	accept = 'image/jpeg, image/png, application/pdf',
	dropHeading,
	disabled = false,
	downloadDocument,
	documentsUploaded,
	bottomText,
}: IDropZone) {
	const { t } = useTranslation()
	const [internalItems, setInternalItems] = useState<File[]>([])

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			let items: IDocumentDto[] = []
			acceptedFiles.forEach((element: File) => {
				items.push({
					content: element,
					documentType: documentType,
				} as IDocumentDto)
			})
			setFiles(files.concat(...items))
			setInternalItems(internalItems.concat(acceptedFiles))
			console.log('Print_acceptedFiles', acceptedFiles)
		},
		[internalItems, setFiles, files, documentType]
	)
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: accept,
		maxFiles: maxFiles,
		disabled: disabled,
	})

	const removeFile = useCallback(
		(item: File) => {
			setFiles(files.filter((file) => file.content !== item))
			setInternalItems(internalItems.filter((file) => file !== item))
		},
		[files, setFiles, internalItems]
	)

	useEffect(() => {
		if (files.length === 0) setInternalItems([])
	}, [files])

	function SelectedItems() {
		return (
			<>
				{internalItems.map((item: any, key: number) => {
					return (
						<div
							key={`internal-items-${key}`}
							className="upload-documents-selected-documents">
							<div className="mb-0 mt-4 pl-3">
								<b>{t('SelectedFile')}</b>
							</div>
							<div className="d-flex flex-row justify-content-between pl-3">
								<div>{item.name}</div>
								<div
									className="trash-icon"
									onClick={() => removeFile(item)}>
									<FontAwesomeIcon
										icon="trash-alt"
										className="mr-3 trash-icon"
										color="#8d191d"
									/>
								</div>
							</div>
						</div>
					)
				})}
			</>
		)
	}

	function PreviouslyUploadedItems() {
		if (!documentsUploaded?.some((x) => x.type === documentType))
			return <></>
		return (
			<>
				<div className="prev-upload-items px-3 my-2 align-items-center">
					<div className="mb-0 mt-4 pt-2">
						<b>{t('PreviouslyUploadedDocuments')}</b>
					</div>
					{documentsUploaded?.map((item: IDocuments, key: number) => {
						if (item.type === documentType && downloadDocument) {
							return (
								<button
									type="button"
									className="prev-upload-items-docs d-flex justify-content-between align-items-center"
									key={`${key}${item.documentId}`}
									onClick={() =>
										downloadDocument(
											item.documentId,
											item.fileName
										)
									}>
									<div className="file-name">
										{item.fileName}
									</div>
									<img
										alt="download"
										src={download_interface_bronze}
									/>
								</button>
							)
						} else {
							return null
						}
					})}
				</div>
			</>
		)
	}

	return (
		<div className="dropzone-outer">
			<button type="button" className="drop-zone" {...getRootProps()}>
				<input {...getInputProps()} />
				{isDragActive ? (
					<div className="d-flex flex-column justify-content-center text-center">
						{t('DropFile')}
					</div>
				) : (
					<div className="d-flex flex-column justify-content-center text-center">
					    <b>{t(dropHeading!)}</b>
						<div>
							<img
								className="mb-3 mt-2"
								src={icon}
								alt="upload-icon"
							/>
						</div>
						{t(text)}
					</div>
				)}
			</button>
			<SelectedItems />
			<PreviouslyUploadedItems />
			{bottomText !== undefined && (
				<div className="dropzone-outer-bottom-text">
					{t(bottomText)}
				</div>
			)}
		</div>
	)
}

export default DropZone