import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import logo_white from 'img/tf-logos/tfbank21-logo-symbol-white.svg'
import './Menu.scss'

const Menu = () => {
	const { t } = useTranslation()

	return (
		<div id="menu" className="application-menu">
			<header>
				<div className="menu-container">
					<div className="header-container">
						<img
							src={logo_white}
							className="img-responsive tfbank-logo-header"
							alt="logo"
						/>
						<div className="sign-out">
							<Link
								to="/logout"
								className="btn btn-outline-lightgray">
								{t('SignOut')}
							</Link>
						</div>
					</div>
				</div>
			</header>
		</div>
	)
}

export default Menu
