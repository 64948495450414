import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Image } from 'react-bootstrap'

import AppContext from 'contexts/AppContext'
import { ReadMoreExpander } from 'components/read-more-expander/ReadMoreExpander'
import { checkIsMobile, checkIsTablet } from 'utils/commonhelpers'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { CountryTypes } from 'constants/enums/countryTypes'
import { getCCHeaderValues } from 'constants/values/header-values/ccHeaderValues'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'

import goldcard_no_mc from 'img/credit-card-images/goldcard_no_mc.png'
import './CreditCardOfferHeader.scss'

const CreditCardOfferHeader = () => {
	const { t } = useTranslation()
	const { market } = useContext(AppContext)
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)
	const [showSubtitle, setShowSubtitle] = useState<boolean>(true)

	useEffect(() => {
		const isLastStep = () => {
			if (loanApplication.isOnThankYouPage) {
				setShowSubtitle(false)
			} else {
				setShowSubtitle(true)
			}
		}
		isLastStep()
	}, [loanApplication.isOnThankYouPage])

	return (
		<div className="credit-card-offer-header">
			<h1 className="top-header">{t('CreditCardOffer')}</h1>
			<hr />
			<ReadMoreExpander
				expanded={!checkIsMobile() || checkIsTablet()}
				showExpander={checkIsMobile() && !checkIsTablet()}
				collapsedHeight={'8rem'}
				contentTop={true}
				children={
					<>
						<div className="credit-card-offer-box">
							<div className="credit-card-offer-box-head">
								<div className="credit-card-offer-box-headers">
									<h4>{t('CreditCardOfferHeader')}</h4>
									<h2>
										{market !== CountryTypes.no && '&euro;'}
										{
											loanApplication?.applicationData
												?.creditLimit
										}
										{market === CountryTypes.no && ' kr'}
									</h2>
								</div>

								<div
									className={`credit-card-offer-box-information-text ${
										!showSubtitle &&
										market === CountryTypes.no
											? 'hide'
											: 'show'
									}`}>
									{t('CreditCardOfferText')}
								</div>
							</div>
							<div className="credit-card-offer-box-image">
								<Image
									src={goldcard_no_mc}
									className="credit-card-gold"
									alt="TF Bank Mastercard Gold"
								/>
							</div>
						</div>
						<div className="credit-card-offer-table">
							{getCCHeaderValues(
								t,
								loanApplication.applicationData
							).map((item, key) => {
								return (
									<div
										key={`credit-card-offer-item-${key}`}
										className="credit-card-offer-item">
										<div className="credit-card-offer-item-information">
											{t(item.text)}
										</div>
										<div className="credit-card-offer-item-value">
											{item.value}
										</div>
									</div>
								)
							})}
						</div>
					</>
				}
			/>
		</div>
	)
}

export default CreditCardOfferHeader
