import React, { useCallback, useContext } from 'react'
import { FormBuilder, formKeySelector } from '@tf/formbuilder'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { FormFactory } from 'formBuilder/formFactory'
import { CustomerForm } from 'constants/enums/formTypes'
import { getLanguageLocale, getMarket } from 'utils/countryHelpers'
import { IApplicationInformation } from 'interfaces/i-application-information'
import {
	addLeadingZeroToDates,
	formatFromToIsoDate,
} from 'utils/dateAndTimeHelpers'

import './ApplicationBlocks.scss'

const EmploymentAndIncome = () => {
	const { t } = useTranslation()
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)

	const formSchema = FormFactory(
		CustomerForm.ApplicationEmploymentForm,
		{
			formName: 'applicationEmploymentForm',
			blockName: 'applicationEmploymentForm',
			market: getMarket(),
			locale: getLanguageLocale(),
			getDataUrl: '',
			validationErrorList: false,
			requiredMessage: t('ValidateRequired'),
			submitButtonText: t('Next'),
		},
		loanApplication.applicationData as IApplicationInformation
	)
	const formStateValues = useSelector(
		formKeySelector(formSchema.formMeta.formName, 'controlsState')
	)

	const updateInformation = useCallback(
		(values) => {
			dispatchLoanApplication({
				type: LOAN_APPLICATION_ACTIONS.SET_UPDATE_APPLICATIONDATA,
				payload: {
					...loanApplication,
					updateApplicationData: {
						...loanApplication.updateApplicationData,
						applicant: {
							...loanApplication.updateApplicationData?.applicant,
							employment: {
								...loanApplication.updateApplicationData
									?.applicant?.employment,
								education: values.education,
								monthlyNetIncome: values.monthlyNetIncome,
								occupationType: values.occupationType,
								employerName: values.employerName,
								employedSince: formatFromToIsoDate(
									addLeadingZeroToDates(values.employedSince),
									'dd/MM/yyyy'
								),
								employmentSector: values.employmentSector,
								otherIncome: values.otherIncome,
							},
						},
					},
				},
			})
			dispatchMsp({
				type: MSP_ACTIONS.SET_INDEX,
				payload: {
					index: msp.index + 1,
				},
			})
		},
		[formStateValues] // eslint-disable-line react-hooks/exhaustive-deps
	)

	// useEffect(() => {
	// 	updateInformation()
	// }, [msp, formStateValues, updateInformation])

	return (
		<div className="employment-and-income">
			<FormBuilder
				formSchema={formSchema!}
				apiError={undefined}
				showErrorsInForm={true}
				showSubmitButton={true}
				onSubmit={(values) => updateInformation(values)}
			/>
		</div>
	)
}

export default EmploymentAndIncome
