import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'

import { IApplicationContext } from 'interfaces/i-application-context-props'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'

import 'common/styling/modal-styling.scss'
import './MissingKycModal.scss'

const MissingKycModal = () => {
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)

	const { t } = useTranslation()

	const handleClose = () =>
		dispatchLoanApplication({
			type: LOAN_APPLICATION_ACTIONS.SET_IS_PEP,
			payload: {
				isPep: true,
				showPepModal: false,
			},
		})

	const handleCloseAndResetPep = () => {
		dispatchLoanApplication({
			type: LOAN_APPLICATION_ACTIONS.SET_IS_PEP,
			payload: {
				...loanApplication,
				showPepModal: false,
			},
		})
	}

	return (
		<Modal
			show={loanApplication.showPepModal}
			onHide={handleCloseAndResetPep}
			className="confirm-pep-modal"
			backdrop="static"
			animation={false}>
			<Modal.Header>
				<Modal.Title>{t('ConfirmPep')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Trans t={t} i18nKey="ConfirmPepDesc">
					<span>You have informed us that you're pep.</span>
					<span>
						By politically exposed person it is meant person who
						holds, or during the last year has held, a high-ranking
						public office or position in a country.
					</span>
					<span>Is this correct?</span>
				</Trans>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-primary" onClick={handleClose}>
					{t('Yes')}
				</button>
				<button
					className="btn btn-primary"
					onClick={handleCloseAndResetPep}>
					{t('No')}
				</button>
			</Modal.Footer>
		</Modal>
	)
}

export default MissingKycModal
