import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import { IUpdateBankInfo } from 'interfaces/i-application-information'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import updateBankInfoServices from 'services/updateBankInfoServices'
import ButtonSpinner from 'components/button-spinner/ButtonSpinner'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

import './UpdateBankInfo.scss'

export const UpdateBankInfoNo = () => {
	const { t } = useTranslation()
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<boolean>(false)
	const [errorMsg, setErrorMsg] = useState<string>(t('ErrorMsg'))
	const [applicationId] = useState<number | undefined>(
		loanApplication.applicationData?.loanNumber
	)
	const [val, setVal] = useState<string>('')
	const [bankData, setBankData] = useState<IUpdateBankInfo>(
		{} as IUpdateBankInfo
	)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)

	const next = () => {
		dispatchMsp({
			type: MSP_ACTIONS.SET_INDEX,
			payload: {
				index: msp.index + 1,
			},
		})
	}

	const updateBankData = async () => {
		setLoading(true)
		setError(false)
		setBankData(
			Object.assign(bankData, {
				applicationId: applicationId,
				bankInfo: {
					...bankData?.bankInfo,
					accountNumber: val!,
				},
			})
		)
		updateAccountInfo()
	}

	const updateAccountInfo = async () => {
		try {
			await updateBankInfoServices
				.updateBankInfo(bankData)
				.then((response) => {
					if (response === 200) {
						next()
					}
				})
				.catch((error) => {
					if (error.message.includes('400')) {
						setErrorMsg(t('ErrorMessageAccountNr'))
					} else {
						setErrorMsg(t('ErrorMsg'))
					}
					setError(true)
					console.warn(error)
				})
		} catch (error) {
			console.warn(error)
			setErrorMsg(t('ErrorMsg'))
			setError(true)
		} finally {
			setLoading(false)
		}
	}

	useEffect(
		() => {
			dispatchLoanApplication({
				type: LOAN_APPLICATION_ACTIONS.SET_UPDATE_APPLICATIONDATA,
				payload: {
					updateApplicationData: {
						...loanApplication.updateApplicationData,
						bankInfo: {
							...loanApplication.updateApplicationData?.bankInfo,
							accountNumber: val,
						},
					},
				},
			})
		},
		[val] //eslint-disable-line
	)

	useEffect(
		() => {
			if (
				loanApplication.updateApplicationData?.bankInfo
					?.accountNumber !== undefined
			) {
				setVal(
					loanApplication.updateApplicationData?.bankInfo
						.accountNumber
				)
			} else if (
				loanApplication.applicationData?.bankInfo.accountNumber !==
					null &&
				loanApplication.applicationData?.bankInfo.accountNumber !==
					undefined
			) {
				setVal(loanApplication.applicationData?.bankInfo.accountNumber)
			} else {
				setVal(val)
			}
		},
		[] //eslint-disable-line
	)

	return (
		<div className="update-bank-info norway">
			<div>
				<p>{t('UpdateAccountNrDescription')}</p>
			</div>
			<div className="update-bank-info-section">
				<input
					type="text"
					value={val}
					placeholder={t('BankAccountNumber')}
					className="form-control update-bank-info"
					onChange={(e) => {
						setVal(e.target.value)
					}}
					disabled={loading}
				/>
				{error && (
					<div className="error-box-container">
						<ErrorBox message={errorMsg} />
					</div>
				)}

				<div className="update-bank-info-next-button">
					<button
						type="button"
						className="btn btn-primary"
						disabled={loading}
						onClick={() => updateBankData()}>
						{loading ? (
							<div className="spinner-container">
								<ButtonSpinner />
							</div>
						) : (
							t('Next')
						)}
					</button>
				</div>
			</div>
		</div>
	)
}
