import React from 'react'

import { CreditCardOfferStepsNo } from '../application-multistep-values/msvNorway'
import CreditCardOfferHeader from 'components/application-headers/creditcard-application-header/CreditCardOfferHeader'
import { CountryTypes } from 'constants/enums/countryTypes'
import { CardApplicationStepsEs } from '../application-multistep-values/msvSpain'

interface IApplicationConfigProps {
	country: string
	header: JSX.Element
	multistep: JSX.Element
}

export const applicationContextConfigCreditCards: IApplicationConfigProps[] = [
	// {
	// 	country: CountryTypes.at,
	// 	header: <CreditCardOfferHeader />,
	// 	multistep: <></>,
	// },
	{
		country: CountryTypes.de,
		header: <CreditCardOfferHeader />,
		multistep: <></>,
	},
	{
		country: CountryTypes.es,
		header: <></>,
		multistep: <CardApplicationStepsEs />,
	},
	{
		country: CountryTypes.no,
		header: <CreditCardOfferHeader />,
		multistep: <CreditCardOfferStepsNo />,
	},
]
