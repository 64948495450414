import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import appConfig from 'app.config'
import { InfoBox } from 'components/message-boxes/information-box/InformationBox'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import { logError, logEvent } from 'appinsights/appinsights'
import authenticationService from 'services/authServices'
import videoIdServices from 'services/videoIdServices'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { useExternalScript } from 'hooks/useScript'
import { ScrollToTop } from 'utils/commonhelpers'

import './Identification.scss'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'
import { ProductType } from 'constants/enums/productTypes'

const IdentificationEs = () => {
	const { t } = useTranslation()
	const { library, isLoaded } = useExternalScript(
		appConfig.electronicIdScriptUrl,
		'EID'
	)
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)
	const [isLoading, setLoading] = useState(false)
	const [error, setError] = useState<boolean>(false)
	const [errorMsg, setErrorMsg] = useState<string>('ErrorMsg')
	const [isCompleted, setCompleted] = useState(false)
	const allowedIdTypes =
		appConfig.environment === 'production' ? ['11', '12', '15', '16', '19', '20', '47', '48', '51', '52', '53', '55', '62', '63', '64', '66', '67', '68', '69', '76', '80', '82', '83', '85', '90', '107', '108', '109', '110', '111', '112', '121', '124', '125', '136', '137', '145', '146', '147', '148', '154', '163', '165', '166', '167', '168', '169', '188', '192', '193', '200', '210', '215', '229', '237', '296', '301', '340'] 
												: []												
	const applicationId = loanApplication.applicationData?.loanNumber
	const product = loanApplication.applicationData?.product

	const attachVideoIdentification = async (verificationRequestId: string) => {
		try {
			await videoIdServices
				.attachVideoIdentification(
					{
						ssn: loanApplication.personalData?.ssn!,
						verificationRequestId: verificationRequestId,
						applicationId: applicationId!,
						product: product!.toString(),
					},
					t
				)
				.then((response) => {
					if (response.status === 200) {
						console.log(
							'Video identification request id was attached successfully.'
						)
					}
					setCompleted(true)
				})
		} catch (error) {
			setErrorMsg(t('VideoIdError'))
			setError(true)
			setLoading(false)
		}
	}

	const createVideoIdentity = async (verificationRequestId: string) => {
		try {
			await videoIdServices
				.createVideoIdentity(
					{
						verificationRequestId: verificationRequestId,
						email: loanApplication.applicationData?.contacts.email!,
						phone: loanApplication.applicationData?.contacts
							.cellPhone!,
						applicationId: applicationId!,
						product: product!.toString(),
						ssn: loanApplication.personalData?.ssn!,
					},
					t
				)
				.then((response) => {
					if (response.status === 200) {
						console.log('Video identity was created successfully')
					}
					setCompleted(true)
				})
		} catch (error) {
			setErrorMsg(t('VideoIdError'))
			setError(true)
			setLoading(false)
		}
	}

	const sendVerificationRequest = async (id: string) => {
		setLoading(true)
		try {
			await authenticationService
				.electronicIDVerificationRequest(id, product!.toString())
				.then((response) => {
					if (response && product === ProductType.EspCashLoan) {
						attachVideoIdentification(response.id)
					} else if (
						response &&
						product === ProductType.EspCreditCard
					) {
						createVideoIdentity(response.id)
					}
				})
		} catch (error) {
			setErrorMsg('ErrorMsg')
			setError(true)
		} finally {
			setLoading(false)
		}
	}

	useEffect(
		() => {
			async function load() {
				const EID = (library as any).EID
				console.log('loaded EID', EID)
				logEvent('VideoId loaded', { Name: EID.name, Event: EID })
				console.log('dynamic loaded script', library)
				setLoading(true)
				try {
					let requirements = await EID.videoId.checkRequirements()
					console.log('requirements', requirements)
					logEvent('VideoId Requirements', {
						Name: requirements.name,
						Event: requirements,
					})
					if (requirements.passed === true) {
						setLoading(false)
						// Requirements are met. Proceed with VideoID
						let videoId = await EID.videoId('#video', {
							lang: 'es',
						})
						console.log('videoId is set', videoId)
						logEvent(
							'VideoId is set:' + `{VideoId: ${videoId}}`,
							{}
						)
						videoId.start({
							authorization: (
								await authenticationService.electronicIDAuthorizationToken(
									applicationId!.toString(),
									product!.toString()
								)
							).authorization,
							allowedIdTypes: allowedIdTypes,
						})

						videoId.on('notification', function (event: any) {
							console.log('Event: ', event)
							if (event.name === 'xxx') {
							}
							if (event.name === 'CameraPermission.Request') {
								ScrollToTop({ top: 0, left: 0 })
							}
							logEvent('VideoId Event', {
								Name: event.name,
								Event: event,
							})
							//Add your functionality here
						})

						videoId.on('completed', function (video: any) {
							console.log('videoIdSuccess', video)
							// add call to Registry for validation
							sendVerificationRequest(video.id)
							// update PX
						})
						videoId.on('failed', function (error: any) {
							console.log('videoIdError', error)
							logError(error)
							// set error message
							// alert('VideoId Failed')
						})
					} else {
						// Minimum requirements are not met. Fallback to an alternative.
						console.log('requirementsResult', requirements)
						setErrorMsg('VideoIdError')
						setError(true)
						logError(requirements)
					}
				} catch (error) {
					// Unexpected errors //Generic error
					console.log('checkRequirementsError', error)
					setErrorMsg('ErrorMsg')
					setError(true)
					logError(error as any)
				} finally {
					setLoading(false)
				}
			}
			if (isLoaded) load()
		},
		[isLoaded] //eslint-disable-line
	)

	const next = () => {
		dispatchMsp({
			type: MSP_ACTIONS.SET_INDEX,
			payload: {
				index: msp.index + 1,
			},
		})
		ScrollToTop({ top: 100, left: 0 })
	}

	return (
		<div className="identification">
			{isLoading ? (
				<div className="col-spinner">
					<CircleSpinner />
				</div>
			) : (
				<>
					{error ? (
						<div className="error-box-container">
							<ErrorBox message={t(errorMsg)} />
						</div>
					) : (
						<>
							{isCompleted ? (
								<>
									<InfoBox
										infoType="success"
										message={t('SuccessMessageVideoId')}
									/>
									<div className="videoid-next-button">
										<button
											type="button"
											className="btn btn-primary"
											onClick={() => next()}>
											{t('Next')}
										</button>
									</div>
								</>
							) : (
								<div
									id="video"
									style={{
										width: '100%',
										height: 600,
									}}></div>
							)}
						</>
					)}
				</>
			)}
		</div>
	)
}

export default IdentificationEs
