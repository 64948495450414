import React, { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ThankYouBackButton } from './ThankYouButtons/ThankYouBackButton'
import { ThankYouDownloadPnButton } from './ThankYouButtons/ThankYouDownloadPnButton'
import AppContext from 'contexts/AppContext'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import SuccessBox from 'components/message-boxes/success-box/SuccessBox'
import { IThankYouSettings } from 'interfaces/i-thank-you'
import { ThankYouSettingTypes } from 'constants/values/thankYouSettingsTypes'

import './ThankYou.scss'

interface IThankYouProps {
	className?: string
}

const ThankYou = ({ className }: IThankYouProps) => {
	const { t } = useTranslation()
	const { market, applicationType } = useContext(AppContext)
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const [thankYouSettings, setThankYouSettings] = useState<
		IThankYouSettings | undefined
	>(undefined)
	const ThankYouTypes = ThankYouSettingTypes()

	const isOnThankYouPage = () => {
		dispatchLoanApplication({
			type: LOAN_APPLICATION_ACTIONS.SET_IS_ON_THANKYOU_PAGE,
			payload: {
				...loanApplication,
				isOnThankYouPage: true,
			},
		})
	}

	const ThankYouContent = () => {
		return ThankYouTypes.forEach((type) => {
			if (type.market === market) {
				return type.thankYouInformation.forEach((info) => {
					if (info.applicationType === applicationType)
						setThankYouSettings(info)
				})
			}
		})
	}

	useEffect(
		() => {
			if (market && applicationType) {
				ThankYouContent()
				isOnThankYouPage()
			}
		},
		[market, applicationType] //eslint-disable-line
	)

	return (
		<div
			className={`thank-you ${className !== undefined ? className : ''}`}>
			<SuccessBox
				css="success-msg-checkmark-animation"
				message={
					thankYouSettings === undefined ? (
						<div className="success-message">
							<Trans t={t} i18nKey="ThankYouDescription"></Trans>
						</div>
					) : (
						<>
							<div>
								<Trans
									t={t}
									i18nKey={`${thankYouSettings.successText}`}></Trans>
							</div>
							{thankYouSettings.backButton?.show && (
								<div>
									{t(
										`${thankYouSettings.backButton.subtitleText}`
									)}
								</div>
							)}
							{thankYouSettings?.downloadPnButton && (
								<ThankYouDownloadPnButton
									buttonName={
										thankYouSettings?.downloadPnButton
											.buttonName
									}
									documentType={
										thankYouSettings?.downloadPnButton
											.documentType
									}
								/>
							)}
						</>
					)
				}
			/>
			{thankYouSettings?.backButton !== undefined &&
				thankYouSettings.backButton.show && (
					<ThankYouBackButton
						buttonName={thankYouSettings.backButton.buttonName}
						stepId={thankYouSettings.backButton.stepId}
					/>
				)}
		</div>
	)
}

export default ThankYou
