import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import Multistep from 'components/multistep/Multistep'
import { LifeCycleStatus } from 'constants/enums/lifeCycleStatus'
import { UrlStatus } from 'constants/enums/signingUrlTypes'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { IMultistepComponent } from 'interfaces/i-multistep'
import { CheckIsLifeCycleStatus } from 'utils/loanHelpers'
import { SignPnNo } from 'components/application-blocks/application-offer-blocks/SignPn/SignPnNo'
import MissingKyc from 'components/application-blocks/application-offer-blocks/MissingKyc/MissingKyc'
import { SignPsd2No } from 'components/application-blocks/application-offer-blocks/SignPsd2/SignPsd2No'
import { UploadDocumentsNo } from 'components/application-blocks/application-offer-blocks/UploadDocuments/UploadDocumentsNo'
import ThankYou from 'components/application-blocks/common-blocks/ThankYou/ThankYou'
import { UpdateBankInfoNo } from 'components/application-blocks/application-offer-blocks/UpdateBankInfo/UpdateBankInfoNo'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

export const CreditCardOfferStepsNo = () => {
	const { t } = useTranslation()
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)

	const creditCardOfferSteps: IMultistepComponent[] = [
		{
			id: 1,
			header: 'PromissoryNote',
			body: <SignPnNo />,
			show:
				loanApplication.applicationData?.signingUrls.pnSigning
					.status !== UrlStatus.Signed &&
				CheckIsLifeCycleStatus(
					[LifeCycleStatus.ReceiveSigning],
					loanApplication.applicationData?.lifeCycleStatus!
				),
			stepCompleted: false,
		},
		{
			id: 2,
			header: 'forms:ApplicationMissingKyc',
			body: <MissingKyc />,
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.ReceiveSigning,
					LifeCycleStatus.FinalCreditApproval,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
		{
			id: 3,
			header: 'PSD2',
			body: <SignPsd2No />,
			show:
				loanApplication?.applicationData?.signingUrls?.psD2?.status! !==
					UrlStatus.Signed &&
				CheckIsLifeCycleStatus(
					[
						LifeCycleStatus.ReceiveSigning,
						LifeCycleStatus.FinalCreditApproval,
					],
					loanApplication.applicationData?.lifeCycleStatus!
				),
			stepCompleted: false,
		},
		{
			id: 4,
			header: 'UploadDocument',
			body: <UploadDocumentsNo />,
			show:
				CheckIsLifeCycleStatus(
					[
						LifeCycleStatus.ReceiveSigning,
						LifeCycleStatus.FinalCreditApproval,
					],
					loanApplication.applicationData?.lifeCycleStatus!
				) &&
				loanApplication.applicationData?.signingUrls.psD2.status !==
					UrlStatus.Signed,
			stepCompleted: false,
		},
		{
			id: 5,
			header: 'ThankYouHeaderCreditCard',
			body: <ThankYou className={'norway'} />,
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.ReceiveSigning,
					LifeCycleStatus.FinalCreditApproval,
					LifeCycleStatus.SendToBo,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
		{
			id: 6,
			header: 'ErrorMsgHeader',
			body: (
				<div className="application-error">
					<ErrorBox message={t('ErrorMsg')} />
				</div>
			),
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.ApplicationReceived,
					LifeCycleStatus.PrintPromissoryNote,
					LifeCycleStatus.CreditDecisionError,
					LifeCycleStatus.CreditDecisionInvestigation,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
	]

	return (
		<Multistep
			multisteps={creditCardOfferSteps}
			resetInitialState={false}
		/>
	)
}

export const LoanApplicationStepsNo = () => {
	const { t } = useTranslation()
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)

	const loanApplicationSteps: IMultistepComponent[] = [
		{
			id: 1,
			header: 'PromissoryNote',
			body: <SignPnNo />,
			show:
				loanApplication.applicationData?.signingUrls.pnSigning
					.status !== UrlStatus.Signed &&
				CheckIsLifeCycleStatus(
					[LifeCycleStatus.ReceiveSigning],
					loanApplication.applicationData?.lifeCycleStatus!
				),
			stepCompleted: false,
		},
		{
			id: 2,
			header: 'forms:ApplicationMissingKyc',
			body: <MissingKyc />,
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.ReceiveSigning,
					LifeCycleStatus.DocumentsUpload,
					LifeCycleStatus.InternalCheck,
					LifeCycleStatus.FinalCreditApproval,
					LifeCycleStatus.AccountNumberVerificationFailed,
					LifeCycleStatus.MultiStepUpdateLoanData,
					LifeCycleStatus.ExternalRegisterCheck,
					LifeCycleStatus.ExternalRegisterCheckSend,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
		{
			id: 3,
			header: 'PSD2',
			body: <SignPsd2No />,
			show:
				CheckIsLifeCycleStatus(
					[
						LifeCycleStatus.ReceiveSigning,
						LifeCycleStatus.DocumentsUpload,
						LifeCycleStatus.InternalCheck,
						LifeCycleStatus.FinalCreditApproval,
						LifeCycleStatus.AccountNumberVerificationFailed,
						LifeCycleStatus.MultiStepUpdateLoanData,
						LifeCycleStatus.ExternalRegisterCheck,
						LifeCycleStatus.ExternalRegisterCheckSend,
					],
					loanApplication.applicationData?.lifeCycleStatus!
				) &&
				loanApplication?.applicationData?.signingUrls?.psD2?.status! !==
					UrlStatus.Signed,
			stepCompleted: false,
		},
		{
			id: 4,
			header: 'UploadDocument',
			body: <UploadDocumentsNo />,
			show:
				CheckIsLifeCycleStatus(
					[
						LifeCycleStatus.ReceiveSigning,
						LifeCycleStatus.DocumentsUpload,
						LifeCycleStatus.InternalCheck,
						LifeCycleStatus.FinalCreditApproval,
						LifeCycleStatus.AccountNumberVerificationFailed,
						LifeCycleStatus.MultiStepUpdateLoanData,
						LifeCycleStatus.ExternalRegisterCheck,
						LifeCycleStatus.ExternalRegisterCheckSend,
					],
					loanApplication.applicationData?.lifeCycleStatus!
				) &&
				loanApplication.applicationData?.signingUrls.psD2.status !==
					UrlStatus.Signed,
			stepCompleted: false,
		},
		{
			id: 5,
			header: 'UpdateAccountNr',
			body: <UpdateBankInfoNo />,
			show:
				CheckIsLifeCycleStatus(
					[
						LifeCycleStatus.PrintPromissoryNote,
						LifeCycleStatus.ManualSendPromissoryNote,
						LifeCycleStatus.ReceiveSigning,
						LifeCycleStatus.DocumentsUpload,
						LifeCycleStatus.InternalCheck,
						LifeCycleStatus.FinalCreditApproval,
						LifeCycleStatus.AccountNumberVerificationFailed,
						LifeCycleStatus.MultiStepUpdateLoanData,
						LifeCycleStatus.ExternalRegisterCheck,
						LifeCycleStatus.ExternalRegisterCheckSend,
					],
					loanApplication.applicationData?.lifeCycleStatus!
				) &&
				loanApplication.applicationData?.bankInfo.accountNumber ===
					null,
			stepCompleted: false,
		},
		{
			id: 6,
			header: 'ThankYouHeader',
			body: <ThankYou className={'norway'} />,
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.ReceiveSigning,
					LifeCycleStatus.DocumentsUpload,
					LifeCycleStatus.InternalCheck,
					LifeCycleStatus.FinalCreditApproval,
					LifeCycleStatus.AccountNumberVerificationFailed,
					LifeCycleStatus.MultiStepUpdateLoanData,
					LifeCycleStatus.ExternalRegisterCheck,
					LifeCycleStatus.ExternalRegisterCheckSend,
					LifeCycleStatus.SendToBo,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
		{
			id: 7,
			header: 'ErrorMsgHeader',
			body: (
				<div className="application-error">
					<ErrorBox message={t('ErrorMsg')} />
				</div>
			),
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.ApplicationReceived,
					LifeCycleStatus.CreditDecisionError,
					LifeCycleStatus.CreditDecisionInvestigation,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
	]
	return (
		<Multistep
			multisteps={loanApplicationSteps}
			resetInitialState={false}
		/>
	)
}
