import appConfig from 'app.config'
import { MissingKycValues } from 'constants/enums/missingKycValues'
import { PropertyType } from 'constants/enums/propertyTypes'
import { PurposeOfLoan } from 'constants/enums/purposeOfLoanTypes'
import { TranslationFile } from 'i18n'
import { IApplicationInformation } from 'interfaces/i-application-information'
import { useTranslation } from 'react-i18next'
import { FormatEnumToList } from 'utils/commonhelpers'

export const ApplicationMissingKycFormFi = (
	_applicationData: IApplicationInformation
) => {
	const showPurposeOfLoan = () =>
		(_applicationData?.personalInfo?.missingKyc?.includes(
			MissingKycValues.PurposeOfLoan
		) ||
			_applicationData?.personalInfo?.missingKyc?.includes(
				MissingKycValues.PurposeOfLoanOther
			)) ??
		false
	const showPep = () =>
		_applicationData?.personalInfo?.missingKyc?.includes(
			MissingKycValues.PEP
		) ?? false

	const { t } = useTranslation([
		TranslationFile.Translation,
		TranslationFile.Forms,
		TranslationFile.Dropdown,
	])

	return {
		fieldSetName: '',
		formName: 'MissingKycForm',
		description: '',
		collapsable: false,
		visible: true,
		market: appConfig.country,
		fullSize: true,
		col: 12,
		controls: [
			{
				options: [
					{
						value: true,
						label: t('Yes'),
					},
					{
						value: false,
						label: t('No'),
					},
				],
				required: true,
				controlName: 'pep',
				type: 'dropdown',
				label: t('forms:PoliticallyExposedPerson'),
				value: '',
				visible: showPep(),
				col: 12,
			},
			{
				options: FormatEnumToList(
					PurposeOfLoan,
					PropertyType.PurposeOfLoan,
					TranslationFile.Dropdown
				),
				required: true,
				controlName: 'purposeOfLoan',
				type: 'dropdown',
				label: t('forms:ApplicationPurposeOfLoan'),
				value: '',
				visible: showPurposeOfLoan(),
				col: 12,
			},
			{
				required: true,
				controlName: 'purposeOfLoanOther',
				type: 'text',
				label: t('forms:ApplicationPurposeOfLoanOther'),
				value: '',
				visible: false,
				col: 12,
				condition: [
					{
						when: 'all',
						action: 'show',
						items: [
							{
								field: 'purposeOfLoan',
								rule: 'equal',
								value: 'Other',
							},
						],
					},
				],
			},
		],
	}
}
