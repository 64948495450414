import {
	ApplicationInsights,
	SeverityLevel,
} from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import appConfig from 'app.config'

const reactPlugin: any = new ReactPlugin()
const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey:
			appConfig.instrumentationKey ||
			'xxxxxxxxxxx-xxxxxxxxxx-xxxxxxx-xxxxx',
		enableAutoRouteTracking: true,
		extensions: [reactPlugin],
	},
})
appInsights.loadAppInsights()
appInsights.trackPageView() // Manually call trackPageView to establish the current user/session/pageview

const logEvent = (name: string, properties: { [key: string]: any }) => {
	appInsights.trackEvent({
		name: name,
		properties: { properties },
	})
}
const logError = (error: Error, properties?: { [key: string]: any }) => {
	appInsights.trackException(
		{
			exception: error,
			severityLevel: SeverityLevel.Error,
		},
		{ properties }
	)
}

const logCritical = (error: Error) => {
	appInsights.trackException({
		exception: error,
		severityLevel: SeverityLevel.Critical,
	})
}
export { reactPlugin, appInsights, logError, logCritical, logEvent }
