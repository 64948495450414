import { TranslationFile } from 'i18n'
import { IApplicationInformation } from 'interfaces/i-application-information'
import { useTranslation } from 'react-i18next'

export const ApplicationMortgageForm = (data: IApplicationInformation) => {
	const { t } = useTranslation([TranslationFile.Forms])

	return {
		fieldSetName: '',
		formName: 'ApplicationMortgageForm',
		collapsable: false,
		show: true,
		visible: true,
		market: 'es',
		fullSize: true,
		col: 12,
		controls: [
			{
				required: true,
				controlName: 'mortgage',
				type: 'radio',
				description: t('forms:LoansHouseTotalDescription'),
				options: [
					{
						value: true,
						label: t('forms:Yes'),
					},
					{
						value: false,
						label: t('forms:No'),
					},
				],
				visible: true,
				col: 12,
			},
			{
				required: true,
				controlName: 'houseTotal',
				formName: 'houseTotal',
				type: 'number',
				label: t('forms:LoansHouseTotal'),
				visible: false,
				condition: [
					{
						when: 'all',
						action: 'show',
						items: [
							{
								field: 'mortgage',
								rule: 'equal',
								value: true,
							},
						],
					},
				],
				validations: [
					{
						code: 'MustMatchPattern',
						value: '^[0-9]*$',
						message: t('forms:ValidationMsgValue'),
					},
				],
				col: 12,
			},
			{
				required: true,
				controlName: 'houseMonthlyCost',
				type: 'number',
				label: t('forms:LoansHouseMonthlyCost'),
				visible: false,
				condition: [
					{
						when: 'all',
						action: 'show',
						items: [
							{
								field: 'mortgage',
								rule: 'equal',
								value: true,
							},
						],
					},
				],
				validations: [
					{
						code: 'MustMatchPattern',
						value: '^[0-9]*$',
						message: t('forms:ValidationMsgValue'),
					},
				],
				col: 12,
			},
		],
	}
}
