import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { IMspProps } from 'interfaces/i-multistep'

interface IProps {
	buttonName: string
	stepId: number
}

export const ThankYouBackButton = ({ buttonName, stepId }: IProps) => {
	const { t } = useTranslation()
	const { msp, dispatchMsp }: IMspProps = useContext(MspDispatchContext)
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)

	const back = () => {
		const backToStep = msp.steps.indexOf(stepId)
		dispatchMsp({
			type: MSP_ACTIONS.SET_INDEX,
			payload: {
				...msp,
				index: backToStep,
			},
		})
		dispatchLoanApplication({
			type: LOAN_APPLICATION_ACTIONS.SET_IS_ON_THANKYOU_PAGE,
			payload: {
				...loanApplication,
				isOnThankYouPage: false,
			},
		})
	}
	return (
		<div className="thank-you-back-button-container">
			<button
				type="button"
				className="btn btn-secondary dark-gray"
				onClick={() => back()}>
				{t(`${buttonName}`)}
			</button>
		</div>
	)
}
