import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import './UploadDocuments.scss'

import Aktivierungsformular_Muster from 'img/credit-card-images/Aktivierungsformular-Muster.png'
import UploadDocumentSvg from 'img/document-icons/upload-document.svg'

import { IApplicationInformation } from 'interfaces/i-application-information'
import { checkIsMobile } from 'utils/commonhelpers'
import { IDocumentDto, IDocumentPageProps } from 'interfaces/i-upload-documents'
import DropZone from 'components/application-blocks/common-blocks/UploadDocuments/DropZone'
import { DocumentTypes } from 'constants/enums/documentTypes'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'
import documentServices from 'services/documentServices'

interface IUploadDocumentsDe {
	applicationId: number
	application: IApplicationInformation
	success: boolean
	setSuccess: Function
}

function UploadDocumentsDe({
	applicationId,
	success,
	setSuccess,
}: IUploadDocumentsDe) {
	const isMobile = checkIsMobile()
	const { t } = useTranslation(['translation', 'dropdown'])

	const [files, setFiles] = useState<IDocumentDto[]>([])
	const [formData] = useState<FormData>(new FormData())

	const [pageProps, setPageProps] = useState<IDocumentPageProps>({
		disabled: true,
		loading: false,
		error: undefined,
	})

	useEffect(() => {
		if (files!.length > 0) {
			setPageProps({
				...pageProps,
				disabled: false,
			})
		} else {
			setPageProps({
				...pageProps,
				disabled: true,
			})
		}
		// eslint-disable-next-line
	}, [files, pageProps.disabled])

	async function onSubmit() {
		setPageProps({
			...pageProps,
			loading: true,
		})
		files.map(async (file: IDocumentDto) => {
			let fileEnding = file.content.type.split('/').pop()

			formData.append('document.contentType', file.content.type)
			formData.append('document.content', file.content)
			formData.append(
				'document.fileName',
				`${applicationId}_activationslip.${fileEnding}`,
			)
			formData.append('documentType', file.documentType)
			try {
				await documentServices.UploadDocument(
					formData,
					applicationId,
					t,
				)
				setPageProps({
					...pageProps,
					loading: false,
				})
				setSuccess(true)
			} catch (e) {
				console.log(e)
				setPageProps({
					...pageProps,
					loading: false,
					error: new Error(t('UploadDocumentError')),
				})
			}
		})
	}

	return (
		<>
			{!success && (
				<>
					<h5 className="mb-0 mt-4">{t('Activationslip')}</h5>
					<hr />
					<form className="d-flex flex-column my-5">
						<div className="d-flex flex-column w-100">
							<div>{t('UploadDocumentText')}</div>
							<div className="mt-4 text-red">
								{t('ActivationslipExampleText')}
							</div>
							<div className="mt-4 text-red">
								{t('Attention')}
							</div>
						</div>
						<div
							className={
								isMobile
									? 'd-flex flex-column w-100 mt-5'
									: 'd-flex justify-content-around'
							}
						>
							<div className="p-5">
								<div className="activationslip-example d-flex justify-content-center">
									<div className="example-text mb-3">
										{t('ActivationFormDesignInfo')}
									</div>
									<img
										src={Aktivierungsformular_Muster}
										alt="activationslip"
										className="img-responsive"
									/>
								</div>
							</div>
							<div className={isMobile ? 'p-0' : 'p-5'}>
								<DropZone
									files={files}
									setFiles={setFiles}
									icon={UploadDocumentSvg}
									text="DropActivationslip"
									documentType={DocumentTypes.ActivationSlip}
									maxFiles={3}
								/>
							</div>
						</div>
						<div className="d-flex justify-content-center">
							<button
								type="button"
								disabled={pageProps.disabled}
								style={{
									width: isMobile ? '100%' : '',
								}}
								className="btn btn-default"
								onClick={() => onSubmit()}
							>
								{pageProps.loading ? (
									<div id="circle-spinner"></div>
								) : (
									t('UploadBtn')
								)}
							</button>
						</div>
					</form>
				</>
			)}
			{pageProps.error && (
				<ErrorBox message={pageProps.error.message} css="alert alert-danger" />
			)}
		</>
	)
}

export default UploadDocumentsDe
