import React, {
	useEffect,
	useReducer,
	useState,
	createContext,
	useCallback,
} from 'react'
import { TranslationFile } from 'i18n'
import { ProgressBar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import './Multistep.scss'

export interface IMultistepComponent {
	id: number
	header?: string
	body: any
	stepCompleted: boolean
	show?: boolean
}

interface IMultistepProps {
	steps: number[]
	index: number
	nextButtonText: string
	hidePreviousBtn: boolean
}

interface IProps {
	multisteps: IMultistepComponent[]
	hidePreviousButton?: boolean
	resetInitialState: boolean
}

const initialState: IMultistepProps = {
	steps: [],
	index: 0,
	nextButtonText: 'Next',
	hidePreviousBtn: true,
}

export const MSP_ACTIONS = {
	SET_STEPS: 'SET_STEPS',
	SET_INDEX: 'SET_INDEX',
	SET_STEPS_AND_INDEX: 'SET_STEPS_AND_INDEX',
	SET_NEXTBUTTON_TEXT: 'SET_NEXTBUTTON_TEXT',
	SET_NEXTBUTTON_FUNCTION: 'SET_NEXTBUTTON_FUNCTION',
	SET_HIDE_PREVIOUS_BUTTON: 'SET_HIDE_PREVIOUS_BUTTON',
}

function reducer(msp: any, action: any) {
	switch (action.type) {
		case MSP_ACTIONS.SET_STEPS:
			return {
				...msp,
				steps: action.payload.steps,
			}
		case MSP_ACTIONS.SET_INDEX:
			return {
				...msp,
				index: action.payload.index,
			}
		case MSP_ACTIONS.SET_STEPS_AND_INDEX:
			return {
				...msp,
				steps: action.payload.steps,
				index: action.payload.index,
			}
		case MSP_ACTIONS.SET_NEXTBUTTON_TEXT:
			return {
				...msp,
				nextButtonText: action.payload.nextButtonText,
			}
		case MSP_ACTIONS.SET_HIDE_PREVIOUS_BUTTON:
			return {
				...msp,
				hidePreviousBtn: action.payload.hidePreviousBtn,
			}
		default:
			return msp
	}
}

export const MspDispatchContext = createContext<{
	msp: IMultistepProps
	dispatchMsp: any
}>({ msp: initialState, dispatchMsp: reducer })

function Multistep({
	multisteps,
	hidePreviousButton = true,
	resetInitialState,
}: IProps) {
	const { t } = useTranslation([TranslationFile.Translation])

	const [msp, dispatchMsp] = useReducer(reducer, initialState)

	const [isFirstPage, setIsFirstPage] = useState<boolean>(true)
	const [isLastPage, setIsLastPage] = useState<boolean>(false)
	const [currentStep, setCurrentStep] = useState<IMultistepComponent>()
	const [reset, setReset] = useState<boolean>(resetInitialState)
	const [hidePreviousBtn] = useState<boolean>(hidePreviousButton)

	const mspStorageName = 'msp'

	const getMultisteps = useCallback(() => {
		const savedSteps = sessionStorage.getItem(mspStorageName)
		const activeSteps = multisteps
			.filter((x) => x.show === true || x.show === undefined)
			.map((x) => x.id)

		if (savedSteps === undefined || savedSteps === null) {
			dispatchMsp({
				type: MSP_ACTIONS.SET_STEPS_AND_INDEX,
				payload: {
					steps: activeSteps,
					index: 0,
				},
			})
		} else {
			let item = JSON.parse(savedSteps)
			if (reset) {
				dispatchMsp({
					type: MSP_ACTIONS.SET_STEPS_AND_INDEX,
					payload: {
						steps: activeSteps,
						index: 0,
					},
				})
				setReset(false)
			} else {
				dispatchMsp({
					type: MSP_ACTIONS.SET_STEPS_AND_INDEX,
					payload: {
						steps: item.steps,
						index: item.index,
					},
				})
			}
		}
	}, [multisteps, reset])

	useEffect(() => {
		getMultisteps()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (msp) {
			setCurrentStep(
				multisteps.find((x) => x.id === msp.steps[msp.index])
			)
			setIsFirstPage(msp.index === 0)
			setIsLastPage(msp.index === msp?.steps.length - 1)
			sessionStorage.setItem(mspStorageName, JSON.stringify(msp))
		}
	}, [msp, multisteps])

	useEffect(() => {
		const mscontainer = document.getElementById('multistep')
		const mscontainerrect = mscontainer?.getBoundingClientRect()
		if (msp.index > 0 && mscontainerrect !== undefined) {
			window.scrollTo({
				left: mscontainerrect.left + window.scrollX,
				top: mscontainerrect.top + window.scrollY - 100,
				behavior: 'smooth',
			})
		} else {
			scrollToTop()
		}
	}, [msp.index])

	const nextPage = () => {
		if (!isLastPage) {
			msp &&
				dispatchMsp({
					type: MSP_ACTIONS.SET_INDEX,
					payload: {
						index: msp.index + 1,
					},
				})
		}
	}

	const previousPage = () => {
		if (!isFirstPage) {
			msp &&
				dispatchMsp({
					type: MSP_ACTIONS.SET_INDEX,
					payload: {
						index: msp.index - 1,
					},
				})
		}
	}

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		})
	}

	const getProgressBar = () => {
		return (
			<div className={`progress-container rounded my-3`}>
				{msp &&
					msp.steps.map((step: number, index: number) => {
						const isDone = index <= msp.index
						return (
							<ProgressBar
								key={index}
								variant="info"
								min={0}
								max={1}
								now={isDone ? 1 : 0}
							/>
						)
					})}
			</div>
		)
	}

	const getPagination = () => {
		return (
			<div className="d-flex justify-content-center pagination-container">
				{!isFirstPage && !hidePreviousBtn && !isLastPage && (
					<button
						className="btn btn-outline-darkgray mx-2"
						onClick={() => {
							previousPage()
						}}>
						{t('Previous')}
					</button>
				)}
				{!isLastPage && currentStep?.stepCompleted && (
					<button
						className="btn btn-primary mx-2"
						onClick={() => nextPage()}>
						{t(`${msp.nextButtonText}`)}
					</button>
				)}
			</div>
		)
	}

	const getBody = () => {
		return (
			<div>
				<div className="d-flex flex-column multistep-container">
					<div className="multistep-content">
						<h2 className="multistep-header">
							{t(`${currentStep?.header}`)}
						</h2>
					</div>
					<hr />
					<div className="multistep-content">{currentStep?.body}</div>
				</div>
				{getPagination()}
			</div>
		)
	}

	return (
		<MspDispatchContext.Provider value={{ msp, dispatchMsp }}>
			<div id="multistep">
				{getProgressBar()}
				{getBody()}
			</div>
		</MspDispatchContext.Provider>
	)
}

export default Multistep
