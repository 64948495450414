import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { InfoBox } from 'components/message-boxes/information-box/InformationBox'
import { CreditDecisionTypes } from 'constants/enums/creditdecisionTypes'
import { CreditDecisionMessages } from 'constants/values/creditDecisionMessageValues'
import {
	ICreditDecisionMessage,
	ICreditDecisionMessageProps,
} from 'interfaces/i-credit-decision-message'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

export const CreditDecisionMessage = ({
	creditDecision,
	css = '',
}: ICreditDecisionMessageProps) => {
	const { t } = useTranslation()
	const [creditDecisionMessage, setCreditDecisionMessage] =
		useState<ICreditDecisionMessage>({
			type: undefined,
			css: 'fail',
			message: 'ErrorMsg',
		})

	useEffect(() => {
		CreditDecisionMessages.find((item) => {
			if (item.type === creditDecision) {
				return setCreditDecisionMessage(item)
			}
			return setCreditDecisionMessage((c) => c)
		})
	}, [creditDecision])

	return (
		<React.Fragment>
			{creditDecisionMessage.css === 'fail' ? (
				<ErrorBox
					css={css}
					message={
						creditDecisionMessage.type ===
							CreditDecisionTypes.Invalid ||
						creditDecisionMessage.type ===
							CreditDecisionTypes.Rejected ? (
							<Trans
								t={t}
								i18nKey={
									'CreditDecision' +
									creditDecisionMessage.type
								}>
								Unfortunately we
								<span>cannot</span>
								grant you the loan at this time, please contact
								TF Bank if you want more information.
							</Trans>
						) : (
							t(creditDecisionMessage.message)
						)
					}
				/>
			) : (
				<InfoBox
					infoType={creditDecisionMessage.css}
					css={css}
					message={
						creditDecisionMessage.type ===
							CreditDecisionTypes.Invalid ||
						creditDecisionMessage.type ===
							CreditDecisionTypes.Rejected ? (
							<Trans
								t={t}
								i18nKey={
									'CreditDecision' +
									creditDecisionMessage.type
								}>
								Unfortunately we
								<span>cannot</span>
								grant you the loan at this time, please contact
								TF Bank if you want more information.
							</Trans>
						) : (
							t(creditDecisionMessage.message)
						)
					}
				/>
			)}
		</React.Fragment>
	)
}
