export const appRoutes = {
	startpage: '/',
	login: '/login',
	logout: '/logout',
	callbackPage: '/callback',
	logoutCallback: '/logoutcallback',
	signicatCallback: '/signicatcallback',
	norwegianBankIdCallback: '/norwegianBankIdCallback',
	applicationPage: '/application',
	cardApplicationPage: '/cardapplication',
	cardApplicationLogin: '/cardapplicationlogin'
}
