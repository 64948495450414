import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
	isPnStatusSigned,
	isPpiStatusSigned,
	onPpiSignClick,
} from 'utils/loanHelpers'
import { InfoBox } from 'components/message-boxes/information-box/InformationBox'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'

import './Ppi.scss'

export const PpiFi = () => {
	const { t } = useTranslation()
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)

	if (isPpiStatusSigned(loanApplication.applicationData!))
		return <InfoBox infoType={'success'} message={t('SuccessMessagePPI')} />

	return (
		<>
			{isPnStatusSigned(loanApplication.applicationData!) && (
				<div className="payment-protection-insurance">
					<div className="ppi-information-section">
						<h6>{t('PpiHeading1')}</h6>
						<div>{t('PpiHeading2')}</div>
						<div>{t('PpiLine1')}</div>
						<div>
							<Trans t={t} i18nKey="PPiLine2">
								The monthly insurance premium is 9% of the
								monthly installment under the loan agreement.
								Example: If your monthly payment is 100 euros,
								the loan security payment is only 9 euros per
								month. The insurance premium will be charged
								each month in connection with the loaned monthly
								installment. The first two months of loan
								security are free, and you can cancel the
								insurance as soon as you wish.
								<a
									rel="noopener noreferrer"
									target="_blank"
									className="text-link link-darkgray"
									href="https://www.tfbank.fi/lainat/lainaturva">
									You can read more about loan security on our
									website.
								</a>
							</Trans>
						</div>
					</div>
					<div className="ppi-next-button">
						<button
							className={'btn btn-primary'}
							onClick={() =>
								onPpiSignClick(loanApplication.signingUrls!, t)
							}>
							{t('GETPPI')}
						</button>
					</div>
					<div className="font-italic mt-3">
						{t('LogoutApplicatonHint')}
					</div>
				</div>
			)}
		</>
	)
}
