export enum EmploymentSector {
	Other,
	Administration,
	Agriculture,
	Art,
	Gambling,
	Business,
	Culture,
	ConstructionRealEstate,
	EducationScience,
	Finance,
	Tourism,
	IndustryEnergy,
	PublicSector,
	Health,
	Security,
	Services,
	Technology,
	Technician,
	Transport,
	StallHolder,
	LegalOrJudicial,
}
