import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { IApplicationContext } from 'interfaces/i-application-context-props'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { UploadDocumentsDropZoneItems } from 'components/application-blocks/common-blocks/UploadDocuments/UploadDocumentsDropZoneItems'
import {
	DocumentTypesSpain,
	DocumentTypesSpainPsd2Signed,
} from 'constants/values/uploadDocumentsTypes'

import './UploadDocuments.scss'
import AppContext from 'contexts/AppContext'
import { ApplicationType } from 'constants/enums/applicationTypes'

export const UploadDocumentsEs = () => {
	const { t } = useTranslation()
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)
	const { applicationType } = useContext(AppContext)
	const [hasSharedPsd2, setHasSharedPsd2] = useState(true) //eslint-disable-line
	const uploadDocumentTextPsd2 =
		applicationType === ApplicationType.Card
			? 'UploadDocumentText2Card'
			: 'UploadDocumentText2'

	//const psd2Status = sessionStorage.getItem('psd2Status')
	// const applicationId = parseInt(loanApplication.applicationData?.loanNumber)

	// useEffect(() => {
	// 	if (psd2Status === '200' || loanApplication.hasSignedPsd2) {
	// 		setHasSharedPsd2(true)
	// 	}
	// 	return
	// }, [psd2Status])

	return (
		<div className="upload-documents">
			<div className="upload-documents-description-top">
				<Trans t={t} i18nKey={uploadDocumentTextPsd2}></Trans>
			</div>
			<UploadDocumentsDropZoneItems
				documentTypes={
					hasSharedPsd2
						? DocumentTypesSpainPsd2Signed
						: DocumentTypesSpain
				}
				documentsNeedUpload={[]}
				applicationData={loanApplication}
				psd2Status={hasSharedPsd2}
			/>
		</div>
	)
}
