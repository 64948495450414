export enum SignPnStepName {
	DownloadContract = 'DownloadContract',
	MethodSelect = 'MethodSelect',
	OtpValidate = 'OtpValidate',
	Success = 'Success',
}

export enum OtpDistributionChannel {
	Unknown = 'Unknown',
	Email = 'Email',
	SMS = 'SMS',
}
