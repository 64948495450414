import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import queryString from 'query-string'

import {
	getQueryValues,
	isPsd2Mandatory,
	isPsd2StatusSigned,
} from 'utils/loanHelpers'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { InfoBox } from 'components/message-boxes/information-box/InformationBox'
import { onPSD2SignClick } from 'utils/psd2Helpers'

import './SignPsd2.scss'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

const isPsd2StatusDeclined = () => getQueryValues().psd2status === 'skip'

export const SignPsd2Fi = () => {
	const { t } = useTranslation()
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)

	const setPsd2Status = (value: string) => {
		const query = getQueryValues()
		query.psd2status = value
		const newQuery = queryString.stringify(query)
		window.location.search = newQuery
	}

	if (isPsd2StatusDeclined()) {
		return (
			<div className="sign-psd2">
				<InfoBox
					infoType={'info'}
					message={
						<Trans
							t={t}
							i18nKey="SigningSkippedMessagePSD2WithRetry">
							Ohitit tulojen vahvistamisen tilitietojesi avulla.
							Suosittelemme että
							<button
								className="btn btn-link-gray-11 resume-psd2-button"
								onClick={() => setPsd2Status('null')}>
								{' '}
								vahvistat{' '}
							</button>
							tulosi Asiakastiedon tilitietopalvelun avulla jo
							ennen sopimuksen allekirjoitusta, jotta
							hakemuksellasi on kaikki sen käsittelyyn tarvittavat
							tiedot. Muussa tapauksessa ota yhteyttä TF Bankin
							asiakaspalveluun sopimuksen allekirjoittamisen
							jälkeen
						</Trans>
					}
				/>
			</div>
		)
	}

	return (
		<div className="sign-psd2">
			{isPsd2StatusSigned(loanApplication.applicationData!) &&
			isPsd2Mandatory(loanApplication.applicationData!) ? (
				<div className="sign-psd2">
					<InfoBox
						infoType={'success'}
						message={t('SuccessMessagePSD2')}
					/>
				</div>
			) : (
				<>
					{(getQueryValues().psd2status === 'fail' ||
						getQueryValues().psd2status === 'abort') && (
						<ErrorBox message={t('SigningFailedMessagePSD2')} />
					)}

					<div className="sign-psd2-section">
						<span>
							{isPsd2Mandatory(loanApplication.applicationData!)
								? t('ConfirmIncomeHard')
								: t('ConfirmIncome')}
						</span>
						<span> {t('PrimaryBank')}</span>
						<div className="sign-psd2-button-container">
							<button
								className="btn btn-primary"
								onClick={() =>
									onPSD2SignClick(
										loanApplication.applicationData!,
										loanApplication.signingUrls!
									)
								}>
								{t('SIGNPSD2')}
							</button>
						</div>
					</div>
					<div className="skip-psd2-description">
						<Trans t={t} i18nKey="ApplicationSkipPsd2">
							Jos sinulla on vaikeuksia vahvistaa tulotietojasi,
							<button
								className="btn btn-link-gray-11 skip-psd2-button"
								onClick={() => setPsd2Status('skip')}>
								{' '}
								ohita{' '}
							</button>
							tämä vaihe ja ota yhteyttä asiakaspalveluumme
						</Trans>
					</div>
				</>
			)}
		</div>
	)
}
