import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TranslationFile } from 'i18n'
import { useLocation } from 'react-router'
import queryString from 'query-string'

import './SignPsd2.scss'
import AppContext from 'contexts/AppContext'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import { getQueryValues } from 'utils/loanHelpers'
import { UrlStatus } from 'constants/enums/signingUrlTypes'
import { InfoBox } from 'components/message-boxes/information-box/InformationBox'
import { ApplicationType } from 'constants/enums/applicationTypes'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { onPSD2SignClick, updatePxSbl } from 'utils/psd2Helpers'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

export const SignPsd2No = () => {
	const { t } = useTranslation([
		TranslationFile.Translation,
		TranslationFile.Forms,
		TranslationFile.Dropdown,
	])
	const { applicationType } = useContext(AppContext)
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)

	const isPSD2StatusConsent = () =>
		loanApplication?.applicationData?.signingUrls?.psD2?.status! ===
			UrlStatus.Signed || getQueryValues().status === 'ok'

	const isPSD2StatusNoConsent = () =>
		loanApplication?.applicationData?.signingUrls?.psD2?.status! ===
			UrlStatus.Declined || getQueryValues().status === 'failed'

	const PSD2StatusMessage = () => {
		if (isPSD2StatusConsent())
			return (
				<InfoBox
					infoType={'success'}
					message={t('SuccessMessagePSD2')}
				/>
			)
		if (isPSD2StatusNoConsent())
			return (
				<ErrorBox
					message={
						applicationType === ApplicationType.Loan
							? t('NoConsentMessagePSD2')
							: t('NoConsentMessagePSD2CreditCard')
					}
				/>
			)
		return <></>
	}

	useEffect(() => {
		updatePxSbl(t)
	}, [t])

	const IsPsd2Response = () => {
		const location = useLocation()
		const [value, setValue] = useState<boolean>()
		useEffect(() => {
			setValue(
				(queryString.parse(location.search.toLowerCase()) ?? '')
					.status === 'ok'
			)
		}, [location])
		return isPSD2StatusConsent() || value || isPSD2StatusNoConsent()
	}

	const next = () => {
		updatePsd2Status(isPSD2StatusConsent())

		dispatchMsp({
			type: MSP_ACTIONS.SET_INDEX,
			payload: {
				index: isPSD2StatusConsent() ? msp.index + 2 : msp.index + 1,
			},
		})
	}

	const updatePsd2Status = useCallback(
		(status: boolean) => {
			dispatchLoanApplication({
				type: LOAN_APPLICATION_ACTIONS.SET_UPDATE_PSD2,
				payload: {
					...loanApplication,
					hasSignedPsd2: status,
				},
			})
		},
		[dispatchLoanApplication, loanApplication] //eslint-disable-line
	)

	return (
		<div className="sign-psd2 norway">
			{IsPsd2Response() ? (
				<PSD2StatusMessage />
			) : (
				<>
					<div className="sign-psd2-section">
						<span>{t('ConfirmIncome')}</span>
						<div className="sign-psd2-button-container">
							<button
								key="sign-psd2-button"
								className="btn btn-primary"
								onClick={() => {
									onPSD2SignClick(
										loanApplication.applicationData!,
										loanApplication.signingUrls!
									)
								}}>
								{t('SIGNPSD2')}
							</button>
						</div>
					</div>
					<div className="skip-psd2-description">
						<span>{t('SkipSignPsd2')}</span>
					</div>
				</>
			)}
			<div className="sign-psd2-next-button-container">
				<button className="btn btn-primary" onClick={() => next()}>
					{IsPsd2Response() ? t('Next') : t('Skip')}
				</button>
			</div>
		</div>
	)
}
