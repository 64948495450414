import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import authenticationService from 'services/authServices'
import { logError } from 'appinsights/appinsights'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import AuthMethodType from 'constants/values/authMethods'

interface NorwegianBankIdLoginBlockProps {
	onError?(error: Error): void
	authMethodType : AuthMethodType
	authBtnClassName : string
}

function NorwegianBankIdContentBlock({
	onError = () => {}, authMethodType, authBtnClassName,
}: NorwegianBankIdLoginBlockProps) {
	const [loading, setLoading] = useState<boolean>(false)
	const btnClassName = "btn authButton " + authBtnClassName

	async function norwegianBankIdAuth() {
		setLoading(true)
		try {
			const redirectUrl =
				await authenticationService.norwegianBankIdRedirectLinkGet(
					authMethodType
				)
			window.location.href = redirectUrl
		} catch (error) {
			logError(error as Error)
			onError(error as Error)
			setLoading(false)
		}
	}

	const { t } = useTranslation()
	// @ts-ignore
	// prettier-ignore
	// const buttonRounded = (<ButtonRounded methods={[AuthMethodType.norwegianBankId]} onClick={norwegianBankIdAuth}></ButtonRounded>)
	const buttonRounded = (
		<div
			key={authMethodType}
			className="NorwegianBankId pb-4">
			<b>{t('Login')}</b>
		</div>
	)
	return (
		<React.Fragment>
			{loading ? (
				<div className="login-container m-2 d-flex flex-column justify-content-center">
					<CircleSpinner />
				</div>
			) : (
				<div
					onClick={() => norwegianBankIdAuth()}
					className="login-container m-2 d-flex flex-column justify-content-between align-items-center pointer">
					<div className={btnClassName}></div>
					<div>{buttonRounded}</div>
				</div>
			)}
		</React.Fragment>
	)
}
export default NorwegianBankIdContentBlock
