import React, { useContext, useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { SignPnContext } from '../SignPnEs'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import pnServices from 'services/pnServices'
import { ICompleteESigningES } from 'interfaces/i-sign-pn'
import { OtpDistributionChannel } from 'constants/enums/signPnTypes'
import { CountdownTimer } from 'utils/dateAndTimeHelpers'
import ButtonSpinner from 'components/button-spinner/ButtonSpinner'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'
import { ProductType } from 'constants/enums/productTypes'

export const SignPnOtpValidate = () => {
	const {
		otpDistributionChannel,
		nextStep,
		previousStep,
		setOtpDistributionRetry,
	} = useContext(SignPnContext)
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)
	const { t } = useTranslation()
	const [timeOut, setTimeOut] = useState<boolean>(false)
	const [otpCode, setOtpCode] = useState<string | undefined>('')
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<boolean>(false)
	const [next, setNext] = useState<boolean>(false)
	const applicationId = loanApplication.applicationData?.loanNumber!
	const product = loanApplication.applicationData?.product
	const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

	const signPnComplete = async (otp: string) => {
		setLoading(true)
		setError(false)
		try {
			let response = await pnServices.signPnComplete({
				applicationId: applicationId,
				otp: otp,
				product: product!.toString()
			} as ICompleteESigningES)
			if (response === 200) await confirmApplication()
		} catch (error) {
			console.log(error)
			setError(true)
			setLoading(false)
		} finally {
			setLoading(false)
		}
	}

	const confirmApplication = async () => {
		setError(false)
		try {
			if (product === ProductType.EspCashLoan) {
				await pnServices
					.confirmMultiCashApplication(applicationId)
					.then((response) => {
						if (response === 200) {
							setNext(true)
						}
					})
			} else if (product === ProductType.EspCreditCard) {
				await pnServices
					.confirmApplication(applicationId)
					.then((response) => {
						if (response === 200) {
							setNext(true)
						}
					})
			}
		} catch (error) {
			console.warn(error)
			setError(true)
		}
	}

	useEffect(() => {
		if (next) {
			nextStep()
		}
		return () => {
			setNext(false)
		}
	}, [next, nextStep])

	const addInputEventlistner = () => {
		const otpInputField = document.getElementById(
			'otp-validate-input-field'
		)
		if (isIOS) {
			otpInputField?.addEventListener('input', updateValue)
		}
		function updateValue(e: any) {
			setOtpCode(e.target.value)
		}
	}

	useEffect(() => {
		addInputEventlistner()
	}, []) //eslint-disable-line

	const backAndRetry = () => {
		setOtpDistributionRetry(true)
		previousStep()
	}

	return (
		<div className="sign-pn-otp-validate">
			<div className="otp-section-sms-and-email">
				<div className="otp-information-top">
					<Trans t={t} i18nKey="OtpValidateText">
						Code has been sent to
						<strong>
							{{
								state:
									otpDistributionChannel ===
									OtpDistributionChannel.Email
										? `${loanApplication.applicationData?.contacts.email}`
										: `${loanApplication.applicationData?.contacts.cellPhone}`,
							}}
						</strong>
						and is valid for another:
					</Trans>
				</div>
				<div className={`otp-timer ${timeOut ? 'time-out' : ''}`}>
					{timeOut ? (
						`${t('OtpTimeOut')}`
					) : (
						<CountdownTimer
							isTimedOut={(bool: boolean) => {
								setTimeOut(bool)
							}}
							format={`mm:ss ${t('Minutes')}`}
						/>
					)}
				</div>
				<>
					{timeOut ? (
						<></>
					) : (
						<>
							<div className="otp-information-bottom">
								<Trans t={t} i18nKey="OtpValidateText2">
									<strong>
										The code takes a few seconds to arrive.
									</strong>{' '}
									If at the end of the time you have not
									received any message, please click back to
									go back one step and request the code again
									by choosing another shipping method.
								</Trans>
							</div>
							<div className="otp-code-entry-section">
								<input
									className="form-control"
									id="otp-validate-input-field"
									type="text"
									placeholder={t('OTPCode')}
									value={otpCode}
									disabled={loading}
									onChange={(e) => setOtpCode(e.target.value)}
								/>
								<button
									disabled={
										otpCode === '' || otpCode === undefined
									}
									type="button"
									className="btn btn-primary validate-btn"
									onClick={() => {
										signPnComplete(otpCode!)
									}}>
									{loading ? (
										<div className="pn-spinner-container">
											<ButtonSpinner />
										</div>
									) : (
										<>
											<div className="file-name">
												{t('ProceedValidateOtpCode')}
											</div>
										</>
									)}
								</button>
							</div>
							<div className="otp-information-bottom field-note">
								{t('OtpValidateText3')}
							</div>
							{error ? (
								<div className="error-box-container">
									<ErrorBox message={t('ErrorMsg')} />
								</div>
							) : (
								<></>
							)}
						</>
					)}
				</>
				<div className="back-section">
					<button
						type="button"
						className="btn btn-secondary dark-gray back-btn"
						onClick={() => backAndRetry()}>
						{timeOut ? t('Retry') : t('Back')}
					</button>
				</div>
			</div>
		</div>
	)
}
