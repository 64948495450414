export enum PurposeOfLoan {
	Unspecified,
	Consumption,
	DebtConsolidation,
	Renovation,
	HomeExpenses,
	Healthcare,
	Travel,
	PurchaseVehicle,
	Other,
	Gambling,
	Donation,
	RealEstatePurchase,
	Education,
	InvestmentInOwnCompany,
	Bills,
	PrivateInvestment,
	HomeHouseExpenses,
}
