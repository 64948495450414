export enum LifeCycleStatus {
	Other = 'Other',
	ReceiveSigning = 'ReceiveSigning',
	CustomerVerification = 'CustomerVerification',
	DocumentsUpload = 'DocumentsUpload',
	InternalCheck = 'InternalCheck',
	SendToBo = 'SendToBo',
	Disbursed = 'Disbursed',
	Canceled = 'Canceled',
	TimedOut = 'TimedOut',
	Denied = 'Denied',
	Duplicated = 'Duplicated',
	ApplicationReceived = 'ApplicationReceived',
	CreditDecisionRunning = 'CreditDecisionRunning',
	CreditDecisionInvestigation = 'CreditDecisionInvestigation',
	CreditDecisionError = 'CreditDecisionError',
	SendIncomeVerification = 'SendIncomeVerification',
	ReceiveIncomeVerification = 'ReceiveIncomeVerification',
	PrintPromissoryNote = 'PrintPromissoryNote',
	Invalid = 'Invalid',
	ManualSendPromissoryNote = 'ManualSendPromissoryNote',
	CollectRefinData = 'CollectRefinData',
	CreditDecisionAwaitConsents = 'CreditDecisionAwaitConsents',
	FinalCreditApproval = 'FinalCreditApproval',
	MultiStepUpdateLoanData = 'MultiStepUpdateLoanData',
	MultiStepUpdatePersonalData = 'MultiStepUpdatePersonalData',
	IssueCard = 'IssueCard',
	CreditDecisionWaitingForFeedback = 'CreditDecisionWaitingForFeedback',
	CreditDecisionFeedbackReceived = 'CreditDecisionFeedbackReceived',
	CustomerVerificationFailed = 'CustomerVerificationFailed',
	AccountNumberVerificationFailed = 'AccountNumberVerificationFailed',
	IdentificationReceivedId = 'IdentificationReceivedId',
	AwaitAccept = 'AwaitAccept',
	ExternalRegisterCheck = 'ExternalRegisterCheck',
	ExternalRegisterCheckSend = 'ExternalRegisterCheckSend',
}

/* Used in useApplicationData hook - ignoring fetching signing urls when application is in any of these statuses */
export enum IgnoreLifeCycleStatus {
	Denied = 'Denied',
	Disbursed = 'Disbursed',
	TimedOut = 'TimedOut',
	Duplicated = 'Duplicated',
	Canceled = 'Canceled',
}
