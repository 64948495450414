import { TFunction } from 'i18next'
import Axios from 'axios'

import { setUpApplicationInterceptorsFor } from './interceptors'
import { cdaApiRoutes } from 'constants/routes/cdaApiRoutes'
import { authAndSignApiRoutes } from 'constants/routes/authAndSignApiRoutes'
import { getHeadersWithJwtToken } from 'utils/authHelpers'
import {
	ICompleteESigningES,
	IInitiateESigningES,
	IResendOtpESigningES,
} from 'interfaces/i-sign-pn'

const transportWithCredentials = setUpApplicationInterceptorsFor(
	Axios.create({})
)

transportWithCredentials.defaults.headers.post['Content-Type'] =
	'application/json'
transportWithCredentials.defaults.headers.post['Access-Control-Allow-Origin'] =
	'*'
transportWithCredentials.defaults.headers.post['Access-Control-Allow-Headers'] =
	'Origin, X-Requested-With, Content-Type, Accept'

const pnServices = {
	async signPnInit(initPnSignES: IInitiateESigningES) {
		let data = await transportWithCredentials.post(
			authAndSignApiRoutes.signPnInit,
			initPnSignES,
			await getHeadersWithJwtToken()
		)
		if (data.status === 200) {
			return data.status
		}
		throw new Error(
			'Something went wrong when trying to start signing the Promissory note'
		)
	},

	async signPnComplete(completePnSignES: ICompleteESigningES) {
		let data = await transportWithCredentials.post(
			authAndSignApiRoutes.signPnComplete,
			completePnSignES,
			await getHeadersWithJwtToken()
		)
		if (data.status === 200) {
			return data.status
		}
		throw new Error(
			'Something went wrong when trying to complete signing the Promissory note'
		)
	},

	async signPnResendOtp(resendOtpPnSignES: IResendOtpESigningES) {
		let data = await transportWithCredentials.post(
			authAndSignApiRoutes.signPnResendOtp,
			resendOtpPnSignES
		)
		if (data.status === 200) {
			return data.status
		}
		throw new Error('Something went wrong when trying to resend the otp')
	},

	async printPn(applicationId: number, t: TFunction) {
		const data = await transportWithCredentials.post(
			cdaApiRoutes.printPn,
			{ applicationId: applicationId },
			await getHeadersWithJwtToken()
		)

		if (data.status === 200) return data.status
		throw new Error(t('ErrorMsg'))
	},

	async confirmApplication(applicationId: number) {
		let data = await transportWithCredentials.post(
			cdaApiRoutes.confirmApplication,
			{ applicationId: applicationId },
			await getHeadersWithJwtToken()
		)
		if (data.status === 200) {
			return data.status
		}
		throw new Error('Something went wrong confirming application.')
	},
		
	async confirmMultiCashApplication(applicationId: number) {
		let data = await transportWithCredentials.post(
			cdaApiRoutes.confirmMultiCashApplication,
			{ applicationId: applicationId },
			await getHeadersWithJwtToken()
		)
		if (data.status === 200) {
			return data.status
		}
		throw new Error('Something went wrong confirming application.')
	},
}

export default pnServices
