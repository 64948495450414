import { useState, useEffect } from 'react'

//Use as " const [value, setValue] = useStateWithSessionStorage( 'myValueInSessionStorage', ); "
const useStateWithSessionStorage = (sessionStorageKey: string) => {
	const [value, setValue] = useState(
		sessionStorage.getItem(sessionStorageKey) || ''
	)

	useEffect(() => {
		sessionStorage.setItem(sessionStorageKey, value)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	return [value, setValue] as const
}

export default useStateWithSessionStorage
