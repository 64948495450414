import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FormBuilder, formKeySelector } from '@tf/formbuilder'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ReactDOM from 'react-dom'

import { IApplicationInformation } from 'interfaces/i-application-information'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import { FormFactory } from 'formBuilder/formFactory'
import { CustomerForm } from 'constants/enums/formTypes'
import { getLanguageLocale, getMarket } from 'utils/countryHelpers'
import { Provinces } from 'constants/enums/provinces'
import {
	addLeadingZeroToDates,
	formatFromToIsoDate,
} from 'utils/dateAndTimeHelpers'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'

import './ApplicationBlocks.scss'

interface IProvinceProps {
	applicationData: IApplicationInformation
	province: string | undefined
}

const AddressAndAccommodation = () => {
	const { t } = useTranslation()
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)
	const [province, setProvince] = useState<string>('')

	const [data, setData] = useState<IProvinceProps>({
		applicationData: loanApplication.applicationData!,
		province: loanApplication.applicationData?.address?.province,
	})

	const formSchema = FormFactory(
		CustomerForm.ApplicationAddressForm,
		{
			formName: 'applicationAddressForm',
			blockName: 'applicationAddressForm',
			market: getMarket(),
			locale: getLanguageLocale(),
			getDataUrl: '',
			validationErrorList: false,
			requiredMessage: t('ValidateRequired'),
			submitButtonText: t('Next'),
		},
		data as IProvinceProps
	)

	const formStateValues = useSelector(
		formKeySelector(formSchema.formMeta.formName, 'controlsState')
	)

	useEffect(() => {
		let zipCode = formStateValues?.zip.value
		if (zipCode !== undefined && zipCode.length >= 2) {
			let provinceCode = 'P' + zipCode.substring(0, 2)
			let provList = Object.entries(Provinces)
			provList.forEach(([key, value]) => {
				if (key === provinceCode) {
					setProvince(value)
				}
			})
		}
	}, [formStateValues?.zip])

	const updateInformation = useCallback(
		(values) => {
			dispatchLoanApplication({
				type: LOAN_APPLICATION_ACTIONS.SET_UPDATE_APPLICATIONDATA,
				payload: {
					...loanApplication,
					updateApplicationData: {
						...loanApplication.updateApplicationData,
						applicant: {
							...loanApplication.updateApplicationData?.applicant,
							address: {
								...loanApplication.updateApplicationData
									?.applicant?.address,
								streetType: values.streetType,
								street: values.street,
								streetNumber: values.streetNumber,
								flatNumber: values.flatNumber,
								zip: values.zip,
								city: values.city,
								countryOfResidence: 'ES',
								regionId:
									loanApplication.applicationData?.address
										.regionId,
								careOf: loanApplication.applicationData?.address
									.careOf,
							},
							accommodation: {
								...loanApplication.updateApplicationData
									?.applicant?.accommodation,
								type: values.type,
								since: formatFromToIsoDate(
									addLeadingZeroToDates(values.since),
									'dd/MM/yyyy'
								),
								cost: values.accommodationCost,
								salesValue:
									loanApplication.applicationData
										?.accommodation.salesValue,
								adultsInFamily:
									loanApplication.applicationData
										?.accommodation.adultsInFamily,
								childrenAge:
									loanApplication.applicationData
										?.accommodation.childrenAge,
							},
						},
					},
				},
			})
			dispatchMsp({
				type: MSP_ACTIONS.SET_INDEX,
				payload: {
					index: msp.index + 1,
				},
			})
		},
		[formStateValues] // eslint-disable-line react-hooks/exhaustive-deps
	)

	useEffect(() => {
		setData({
			applicationData: data.applicationData,
			province: province,
		})
	}, [province, data.applicationData])

	const RenderProvinceField = () => {
		let formdiv = document.querySelector('.applicant-content')
		if (formdiv)
			return ReactDOM.createPortal(
				<div className="col-12 province-input-box">
					<div className="row">
						<div className="form-box col-12">
							<div
								className={`field has-feedback form-group control-text spain label-floating ${
									province ? 'has-value' : 'is-empty'
								}`}>
								<label className="control-label">
									<span>{t('forms:Province')}</span>
								</label>
								<div className="value row">
									<div className="inputbox col-12">
										<input
											className="form-control"
											type="text"
											value={province}
											onChange={(e) => {
												setProvince(e.target.value)
											}}
											readOnly
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>,
				formdiv
			)
		return null
	}

	return (
		<div className="address-and-accommodation">
			{formSchema ? (
				<FormBuilder
					formSchema={formSchema}
					apiError={undefined}
					showErrorsInForm={true}
					showSubmitButton={true}
					onSubmit={(values) => updateInformation(values)}
				/>
			) : (
				<CircleSpinner />
			)}
			<RenderProvinceField />
		</div>
	)
}

export default AddressAndAccommodation
