import React, { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next'
import appConfig from 'app.config'

import AuthMethodType, {
	ISigningButton,
	getAuthMethodsForCurrentCountry,
} from 'constants/values/authMethods'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import { AuthErrorMessage } from 'components/message-boxes/auth-error-box/AuthErrorBox'
import { FooterLoggedOut } from 'components/footer-blocks/footer-logged-out/FooterLoggedOut'

import tfbank21_logo_white from 'img/tf-logos/tfbank21-logo-white.svg'
import './LoginPage.scss'

function LoginPage() {
	const { t } = useTranslation()
	const filteredMethods = getAuthMethodsForCurrentCountry()
	const [showAuthCardMenu, setShowAuthCardMenu] = useState<boolean>(true)
	const [content, setContent] = useState<ISigningButton>()
	const tagManagerArgs = {
		dataLayer: {
			userId: '001',
			userProject: 'project',
			page: '/login',
		},
		dataLayerName: 'PageDataLayer',
	}

	/** If there's only one auth method and a content property exists ignore the card menu screen and only display the content */
	useEffect(() => {
		if (filteredMethods.length === 1 && filteredMethods[0].content) {
			setShowAuthCardMenu(false)
			setContent(filteredMethods[0])
		}
	}, []) // eslint-disable-line

	useEffect(() => {
		TagManager.dataLayer(tagManagerArgs)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	/**
	 * Generates the icon (and with optional title) for specific authentication type
	 * @param {AuthMethodType} type Authentication method
	 * @param {boolean} [isContent = false] Optional parameter to tell what's being rendered. If true it will check if title should be shown or not based of property in interface
	 * @returns JSX element with the authentication icon or an empty if type was not found
	 */
	const getAuthIcon = (type: AuthMethodType, isContent: boolean = false) => {
		const authMethod = filteredMethods.find((x) => x.type === type)
		const showAuthTextInContent =
			isContent && authMethod?.hideTitleInContent === true

		if (!authMethod) {
			return <></>
		}

		return (
			<div className="authButton-icon">
				<span className={`${authMethod.logoClassName}`} />
				{authMethod.logoTitle && (
					<span
						className={`authButton-text ${
							showAuthTextInContent ? 'd-none' : ''
						}`}>
						{t(authMethod.logoTitle)}
					</span>
				)}
			</div>
		)
	}

	/**
	 * @returns JSX.Element with the authentication card menu
	 */
	const AuthCardMenu = () => {
		const [isLoading, setIsLoading] = useState<boolean[]>(
			Array.from({ length: filteredMethods.length }, () => false)
		)
		const [error, setError] = useState<string[]>(
			Array.from({ length: filteredMethods.length }, () => '')
		)

		const updateError = (key: number, message: string) => {
			error[key] = message
			setError([...error])
		}

		const updateIsLoading = (key: number, value: boolean) => {
			isLoading[key] = value
			setIsLoading([...isLoading])
		}

		const hasMultipleAuthMethods = () => filteredMethods.length > 1

		return (
			<div className="auth-card-container">
				{hasMultipleAuthMethods() && (
					<div className="auth-card-header-container">
						<h2>{t('ChooseLoginMethod')}</h2>
					</div>
				)}
				<div
					className={`auth-card-items-container ${
						hasMultipleAuthMethods() && 'multiple-methods'
					}`}>
					{filteredMethods.map((i, key) => {
						return (
							<button
								key={i.type}
								className="btn auth-card"
								onClick={async () => {
									if (i.onClick) {
										try {
											if (error[key]) updateError(key, '')
											updateIsLoading(key, true)
											await i.onClick()
										} catch {
											updateError(
												key,
												t('AuthenticationError')
											)
											updateIsLoading(key, false)
										}
									} else setContent(i)
								}}>
								<div className="auth-card-header">
									{getAuthIcon(i.type)}
								</div>
								{hasMultipleAuthMethods() ? (
									<>
										{isLoading[key] && (
											<div className="auth-card-content">
												<CircleSpinner />
											</div>
										)}
										{error[key] && (
											<div className="auth-card-content">
												<AuthErrorMessage
													message={error[key]}
													css="mt-2"
												/>
											</div>
										)}
									</>
								) : (
									<div className="auth-card-content">
										{isLoading[key] ? (
											<CircleSpinner />
										) : (
											<span className="auth-card-content-info">
												{t('Login')}
											</span>
										)}
										{error[key] && (
											<AuthErrorMessage
												message={error[key]}
												css="mt-2"
											/>
										)}
									</div>
								)}
							</button>
						)
					})}
				</div>
			</div>
		)
	}

	/**
	 * @returns JSX.Element with the content of the chosen authentication type
	 */
	const AuthContent = () => {
		return (
			<div className="auth-content-container">
				{content!.loginContainerHeaderKey && (
					<div className="login-container-header">
						<h1>{t(content!.loginContainerHeaderKey)}</h1>
					</div>
				)}
				<div className={`login-container ${appConfig.country}`}>
					<div className="login-header">
						{getAuthIcon(content!.type, true)}
					</div>
					<div className="login-content">{content!.content}</div>
				</div>
				<button
					className={`btn btn-secondary-light ${
						showAuthCardMenu ? '' : 'hide'
					}`}
					onClick={() => setContent(undefined)}>
					{t('Back')}
				</button>
			</div>
		)
	}

	return (
		<div id="login-page">
			<div className="login-bg">
				<div className="login-page-container">
					<img
						src={tfbank21_logo_white}
						className="tfbank-logo login"
						alt="logo"
					/>
					{content ? <AuthContent /> : <AuthCardMenu />}
					<FooterLoggedOut />
				</div>
			</div>
		</div>
	)
}
export default LoginPage
