import React from 'react'

import './ErrorBox.scss'

interface IErrorBox {
	header?: string
	message: string | object
	button?: object
	css?: string
}

/**
 * Used to display larger error messages in application to customer.
 * @param message Error message to display with translation attached
 * @param header optional header to display
 * @param button Optional button with functionality
 * @param css Optional classname for new css
 * @returns
 */
const ErrorBox = ({ css, message, button, header }: IErrorBox) => {
	return (
		<div className={`error-box ${css ? css : ''}`}>
			<div className="error-box-content-container">
				{header ? <h3>{header}</h3> : null}
				<div className="message-container">{message}</div>
				{button ? (
					<div className="button-container">{button}</div>
				) : null}
			</div>
		</div>
	)
}

export default ErrorBox
