import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SignPnContext } from '../../SignPnEs'

const SignPnTerms = () => {
	const { t } = useTranslation()
	const { nextStep } = useContext(SignPnContext)
	const [pnTermsApproved, setPnTermsApproved] = useState<boolean>(false)

	return (
		<React.Fragment>
			<div className="sign-pn-approve-checkbox-section">
				<label className="checkbox-container">
					<input
						type="checkbox"
						defaultChecked={pnTermsApproved}
						onClick={() => setPnTermsApproved(!pnTermsApproved)}
					/>
					<span className="checkmark"></span>
					<span className="text">
						{t('PromissoryNoteApproveTerms')}
					</span>
				</label>
			</div>
			<div className="sign-pn-button-section">
				<button
					type="button"
					className="btn btn-primary"
					disabled={!pnTermsApproved}
					onClick={() => nextStep()}>
					{t('SIGNPN')}
				</button>
			</div>
		</React.Fragment>
	)
}

export default SignPnTerms
