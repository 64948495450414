import React, { useEffect, useContext, useState, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import download_interface_cream from 'img/document-icons/download-interface-cream.svg'

import { SignPnContext } from '../../SignPnEs'
import SignPnDocumentViewer from './SignPnDocumentViewer'
import SignPnTerms from './SignPnTerms'
import { downloadBlob } from 'utils/documentHelpers'
import documentServices from 'services/documentServices'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import ButtonSpinner from 'components/button-spinner/ButtonSpinner'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

import './SignPnContract.scss'

export const SignPnContract = () => {
	const { t } = useTranslation()
	const { setError, pnDocument } = useContext(SignPnContext)
	const [loading, setLoading] = useState<boolean>(false)
	const [loadingDocument, setLoadingDocument] = useState<boolean>(true)
	const [blob, setBlob] = useState<Blob>()

	const downloadPn = (fileName: string) => {
		if (blob) {
			try {
				downloadBlob(blob as Blob, fileName)
			} catch (error) {
				console.warn(error)
				setError(true)
			} finally {
				setLoading(false)
			}
		}
	}

	const getPromissoryNoteDocument = useCallback(
		async (documentId: any) => {
			setLoadingDocument(true)
			try {
				const { blob }: any =
					await documentServices.getDownloadDocumentById(documentId)
				const newBlobType = new Blob([blob], {
					type: 'application/pdf',
				})
				setBlob(newBlobType)
			} catch (error) {
				console.warn(error)
				setError(true)
			} finally {
				setLoadingDocument(false)
			}
		},
		[setError]
	)

	useEffect(() => {
		if (pnDocument) {
			getPromissoryNoteDocument(pnDocument.documentId)
		}
	}, [pnDocument, getPromissoryNoteDocument])

	return (
		<div className="sign-pn-view-contract-section">
			<div className="sign-pn-description">
				<div>
					<Trans t={t} i18nKey={'PromissoryNoteSigningLine1'}></Trans>
				</div>
				<div>
					<Trans t={t} i18nKey={'PromissoryNoteSigningLine2'}></Trans>
				</div>
			</div>
			{loadingDocument ? (
				<div className="col-spinner">
					<CircleSpinner />
				</div>
			) : (
				<React.Fragment>
					{loading ? (
						<div className="col-spinner">
							<CircleSpinner />
						</div>
					) : (
						<React.Fragment>
							{pnDocument ? (
								<React.Fragment>
									{blob ? (
										<div id="view-pn-container">
											<SignPnDocumentViewer blob={blob} />
										</div>
									) : (
										<ErrorBox message={t('ErrorViewPn')} />
									)}
									<div className="sign-pn-download-pn-section">
										<button
											className="btn btn-primary download-pn-button"
											onClick={() =>
												downloadPn(pnDocument.fileName)
											}
											disabled={loading}
											key={pnDocument.documentId}>
											{loading ? (
												<div className="pn-spinner-container">
													<ButtonSpinner />
												</div>
											) : (
												<React.Fragment>
													<div className="file-name">
														{t('DownloadPN')}
													</div>
													<img
														src={
															download_interface_cream
														}
														alt="download img"
													/>
												</React.Fragment>
											)}
										</button>
									</div>
									<SignPnTerms />
								</React.Fragment>
							) : (
								<ErrorBox message={t('ErrorFetchPn')} />
							)}
						</React.Fragment>
					)}
				</React.Fragment>
			)}
		</div>
	)
}
