import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import './cardApplicationPage.scss'
import { LifeCycleStatus } from 'constants/enums/lifeCycleStatus'
import { SigningUrlType, UrlStatus } from 'constants/enums/signingUrlTypes'
import { IApplicationUrlsDto } from 'interfaces/i-signing-urls'
import getApplicationServices from 'services/getApplicationServices'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import { Decision } from 'constants/enums/decision'
import Footer from 'components/footer-blocks/footer/Footer'
import Heading from 'components/heading/Heading'
import { InfoBox } from 'components/message-boxes/information-box/InformationBox'
import UploadDocumentsDe from 'components/application-blocks/application-offer-blocks/UploadDocuments/UploadDocumentsDe'
import SignSection from 'components/application-blocks/application-offer-blocks/SignPn/SignPnDe/SignSection'
import CardSection from 'components/application-blocks/application-offer-blocks/CardSection/CardSection/CardSection'
import Menu from 'components/menu/Menu'
import AppDownloadSection from 'components/mobile/AppDownloadSection'
import AppContext from 'contexts/AppContext'

const CardApplicationPage = () => {
	const { t } = useTranslation()

	let applicationId = sessionStorage.getItem('applicationid')
	const [signSuccess, setSignSuccess] = useState(false)
	const [activationSlipnSuccess, setActivationSlipnSuccess] = useState(false)

	const { applicationInformation } = useContext(AppContext)

	const { applicationData, loading, error } = applicationInformation || {}

	const [signingLink, setSigningLink] = useState(
		applicationData?.signingUrls.onlineIdentification.url,
	)

	function checkLifeCycleStatus() {
		switch (applicationData?.lifeCycleStatus) {
			case LifeCycleStatus.DocumentsUpload:
			case LifeCycleStatus.InternalCheck:
			case LifeCycleStatus.IdentificationReceivedId:
				return true
			default:
				return false
		}
	}

	useEffect(() => {
		setSigningLink(applicationData?.signingUrls.onlineIdentification.url)

		async function getSigningUrls() {
			if (
				applicationData?.signingUrls.onlineIdentification.status ===
					UrlStatus.NotCreated ||
				applicationData?.signingUrls.onlineIdentification.status ===
					UrlStatus.Canceled
			) {
				const data: IApplicationUrlsDto =
					await getApplicationServices.GetSigningUrls(
						+applicationId!,
						SigningUrlType.OnlineIdentification,
						t,
					)
				setSigningLink(data.onlineIdentification)
			}
		}
		getSigningUrls()
		// eslint-disable-next-line
	}, [applicationData, signingLink])

	return (
		<div id="germany" className="card-applicationpage">
			<Menu />
			<div className="de-page-color">
				{loading ? (
					<div className="col-spinner d-flex justify-content-center align-items-center">
						<CircleSpinner />
					</div>
				) : (
					<>
						<div className="container pt-5">
							{applicationData?.decision ===
								Decision.Rejected && (
								<div className="rejected">
									<Heading
										title={
											t('YourApplication', {
												applicationId,
											}) +
											` ${applicationData?.personalInfo?.firstName} ${applicationData?.personalInfo?.lastName}`
										}
									/>
									<div className="mb-2">
										{t('ApplicationRejected')}
									</div>
								</div>
							)}
							{error && (
								<div className="alert alert-danger">
									{t(error.message)}
								</div>
							)}
						</div>

						{applicationData?.decision !== Decision.Rejected &&
							!error && (
								<>
									<div className="intro-section">
										<div className="container">
											<Heading
												title={
													t('YourApplication', {
														applicationId,
													}) +
													` ${applicationData?.personalInfo?.firstName} ${applicationData?.personalInfo?.lastName}`
												}
												description="ApplicationHeading"
											/>
										</div>
										<CardSection
											applicationData={applicationData}
										/>
									</div>

									<div className="idnow-section">
										{(applicationData?.lifeCycleStatus ===
											LifeCycleStatus.CustomerVerification ||
											applicationData?.lifeCycleStatus ===
												LifeCycleStatus.DocumentsUpload) && (
											<SignSection
												application={applicationData!}
												applicationId={parseInt(
													applicationId!,
												)}
												signingLink={signingLink!}
												success={signSuccess}
												setSuccess={setSignSuccess}
											/>
										)}
									</div>

									<div className="activationslip-section">
										{(checkLifeCycleStatus() ||
											applicationData?.lifeCycleStatus !==
												LifeCycleStatus.Disbursed ||
											signSuccess) && (
											<section className="container mb-5 upload-doc">
												<UploadDocumentsDe
													applicationId={parseInt(
														applicationId!,
													)}
													application={
														applicationData!
													}
													success={
														activationSlipnSuccess
													}
													setSuccess={
														setActivationSlipnSuccess
													}
												/>
											</section>
										)}
									</div>

									{activationSlipnSuccess && (
										<div className="container">
											<InfoBox
												infoType="success"
												css="success"
												message={t('ActivationslipSuccess')}
											/>
										</div>
									)}

									{applicationData?.lifeCycleStatus ===
										LifeCycleStatus.Disbursed && (
										<AppDownloadSection />
									)}
								</>
							)}
					</>
				)}
				<Footer />
			</div>
		</div>
	)
}

export default CardApplicationPage
