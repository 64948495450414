import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FormBuilder, formKeySelector } from '@tf/formbuilder'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SmoothCollapse from 'react-smooth-collapse'

import { IOffer } from 'interfaces/i-application-information'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import { FormFactory } from 'formBuilder/formFactory'
import { CustomerForm } from 'constants/enums/formTypes'
import { getLanguageLocale, getMarket } from 'utils/countryHelpers'
import { CalculateLoanExample } from 'utils/loanHelpers'
import { stringifyNum } from 'utils/commonhelpers'
import { Decision } from 'constants/enums/decision'
import { InfoBox } from 'components/message-boxes/information-box/InformationBox'
import { CreditDecisionMessage } from 'components/creditdecisionmessage/CreditDecisionMessage'

import './Calculator.scss'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

interface ILoanOfferVals extends IOffer {
	amountDefaultValue: number
	repaymentDefaultValue: number
}

const Calculator = () => {
	const { t } = useTranslation()
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const [loanInfo, setLoanInfo] = useState({
		amount: 1000,
		repaymentPeriod: 12,
		totalAmountToBeRepaid: 0,
		totalLoanCost: 0,
		instalment: 0,
	})
	const [translation, setTranslation] = useState<any>('')
	const maxInstalment = loanApplication.applicationData?.offer.maxInstallment
	const amountDefault =
		Math.ceil(
			(loanApplication.applicationData?.offer.amountMax! -
				loanApplication.applicationData?.offer.amountMin!) /
				500 /
				4
		) * 500
	const repaymentPeriodDefault =
		Math.ceil(
			(loanApplication.applicationData?.offer.repaymentPeriodMax! -
				loanApplication.applicationData?.offer?.repaymentPeriodMin!) /
				6 /
				2
		) * 6
	const [instalmentWarning, setInstalmentWarning] = useState<boolean>(false)
	const [loanOfferValues] = useState<ILoanOfferVals>({
		...loanApplication.applicationData?.offer!,
		amountDefaultValue: amountDefault,
		repaymentDefaultValue: repaymentPeriodDefault,
	})

	let formSchema = FormFactory(
		CustomerForm.ApplicationLoanOfferForm,
		{
			formName: 'applicationLoanOfferForm',
			blockName: 'applicationLoanOfferForm',
			market: getMarket(),
			locale: getLanguageLocale(),
			getDataUrl: '',
			validationErrorList: false,
			requiredMessage: t('ValidateRequired'),
		},
		loanOfferValues as ILoanOfferVals
	)

	const formStateValues = useSelector(
		formKeySelector(formSchema.formMeta.formName, 'controlsState')
	)

	//Sets the background color of the input range slider
	const handleInputChange = () => {
		const inputFields = document.querySelectorAll('input[type="range"]')
		inputFields.forEach((field: any) => {
			field.setAttribute(
				'style',
				`background-size: ${
					((field.value - field.min) / (field.max - field.min)) * 100
				}%, 100%`
			)
		})
	}

	const calculateLoanValues = useCallback(() => {
		let sum = CalculateLoanExample(
			formStateValues?.amount.value,
			formStateValues?.repaymentPeriod.value,
			loanApplication.applicationData?.offer?.effectiveInterestRate!,
			loanApplication.applicationData?.offer?.interestRate!
		)
		setLoanInfo({
			amount: sum.loanAmount,
			repaymentPeriod: sum.periodInMonths,
			totalAmountToBeRepaid: sum.totalAmount,
			totalLoanCost: sum.totalCost,
			instalment: sum.instalment,
		})
	}, [
		formStateValues?.amount.value,
		formStateValues?.repaymentPeriod.value,
		loanApplication.applicationData?.offer?.effectiveInterestRate,
		loanApplication.applicationData?.offer?.interestRate,
	])

	useEffect(() => {
		if (formStateValues !== undefined) {
			handleInputChange()
			calculateLoanValues()
		}
	}, [formStateValues, calculateLoanValues])

	useEffect(
		() => {
			if (loanInfo.instalment >= maxInstalment!) {
				setInstalmentWarning(true)
			} else {
				setInstalmentWarning(false)
			}
			dispatchLoanApplication({
				type: LOAN_APPLICATION_ACTIONS.SET_UPDATE_APPLICATIONDATA,
				payload: {
					...loanApplication,
					updateApplicationData: {
						...loanApplication.updateApplicationData,
						loanData: {
							...loanApplication.updateApplicationData?.loanData,
							amount: loanInfo.amount,
							repaymentPeriod: loanInfo.repaymentPeriod,
						},
					},
				},
			})
		},
		[loanInfo] // eslint-disable-line react-hooks/exhaustive-deps
	)

	useEffect(
		() => {
			dispatchLoanApplication({
				type: LOAN_APPLICATION_ACTIONS.SET_UPDATE_APPLICATIONDATA,
				payload: {
					...loanApplication,
					updateApplicationData: {
						...loanApplication.updateApplicationData,
						loanData: {
							...loanApplication.updateApplicationData?.loanData,
							maxInstalmentWarning: instalmentWarning,
						},
					},
				},
			})
		},
		[instalmentWarning] // eslint-disable-line react-hooks/exhaustive-deps
	)

	useEffect(() => {
		if (loanInfo) {
			setTranslation(
				<Trans t={t} i18nKey="CalculatedExample">
					Example of financing for an amount of
					<strong>
						{{
							amount: stringifyNum(
								loanInfo.amount ? loanInfo.amount : 1000,
								{ useGrouping: true }
							),
						}}
						€.
					</strong>{' '}
					to be paid in
					<strong>
						{{
							repaymentPeriod: loanInfo.repaymentPeriod
								? loanInfo.repaymentPeriod
								: 12,
						}}{' '}
						months
					</strong>{' '}
					installments with an{' '}
					<strong>
						interest rate of
						{{
							interestRate: stringifyNum(
								loanApplication.applicationData?.offer
									?.interestRate,
								{
									style: 'percent',
								}
							),
						}}
						effective interest rate{' '}
						{{
							effectiveInterestRate: stringifyNum(
								loanApplication.applicationData?.offer
									?.effectiveInterestRate,
								{
									style: 'percent',
								}
							),
						}}
						.
					</strong>
					The total amount owed will be{' '}
					{{
						totalAmountToBeRepaid: stringifyNum(
							loanInfo.totalAmountToBeRepaid,
							{ useGrouping: true }
						),
					}}
					€. Total cost of the loan/amount of interest:{' '}
					{{
						totalLoanCost: stringifyNum(loanInfo.totalLoanCost, {
							useGrouping: true,
						}),
					}}{' '}
					€.
				</Trans>
			)
		}
	}, [
		loanInfo,
		loanApplication.applicationData?.offer?.effectiveInterestRate,
		loanApplication.applicationData?.offer?.interestRate,
		t,
	])

	return (
		<>
			{loanApplication.applicationData?.decision === Decision.Approved ? (
				<div id="calculator">
					<div className="calculator-form-description">
						<Trans t={t} i18nKey="forms:LoanOfferFormDescription">
							<strong>
								We can offer you
								{{
									maxAmount: stringifyNum(
										loanApplication.applicationData.offer
											.amountMax,
										{ useGrouping: true }
									),
								}}
								€.
							</strong>{' '}
							Use the sliders below to choose the amount and
							repayment period.
						</Trans>
					</div>
					<div>
						<FormBuilder
							formSchema={formSchema}
							apiError={undefined}
							showErrorsInForm={false}
							showSubmitButton={false}
						/>
						<div className="calculator-bottom-information col-12">
							<SmoothCollapse
								expanded={instalmentWarning}
								children={
									<ErrorBox
										css="instalment-error-box"
										message={t('MaxInstalmentError')}
									/>
								}
							/>
							<div className="calculated-sum">
								{t('CalculatedSum')}
								{' : ' +
									stringifyNum(loanInfo.instalment, {
										useGrouping: true,
									}) +
									' €'}
							</div>
							<InfoBox
								infoType="info-gray"
								css="calculated-example"
								message={translation}
							/>
						</div>
					</div>
				</div>
			) : (
				<CreditDecisionMessage
					creditDecision={loanApplication.applicationData?.decision}
				/>
			)}
		</>
	)
}

export default Calculator
