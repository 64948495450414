import { useTranslation } from 'react-i18next'
import { TranslationFile } from 'i18n'

export const OtpCompleteForm = (data: any) => {
	const { t } = useTranslation([TranslationFile.Forms])
	return {
		fieldSetName: '',
		formName: 'LoginEs',
		description: '',
		collapsable: false,
		visible: true,
		market: 'es',
		fullSize: true,
		col: 12,
		controls: [
			{
				required: true,
				controlName: 'otp',
				type: 'text',
				label: t('forms:OtpCode'),
				description: t('forms:EnterReceivedOtpCode'),
				visible: true,
				col: 12,
				validations: [
					{
						code: 'Invalid',
						message: t('forms:ValidationMsgOtp'),
					},
				],
			},
		],
	}
}
