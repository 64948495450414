import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SignPnContext } from '../../SignPnEs'

import zoom_in from 'img/sign-pn-icons/zoom_in.svg'
import zoom_out from 'img/sign-pn-icons/zoom_out.svg'
import magnifying_glass_gray from 'img/sign-pn-icons/magnifying-glass-gray.svg'
import arrow_down_white from 'img/sign-pn-icons/arrow-down-white.svg'

const SignPnZoomButtons = () => {
	const { t } = useTranslation()
	const { docScale, setScale } = useContext(SignPnContext)
	const [zoomActive, setZoomActive] = useState<boolean>(false)

	function zoom(offset: number) {
		setScale((prevScale: any) => prevScale + offset)
	}

	function zoomOut() {
		zoom(-0.5)
	}

	function zoomIn() {
		zoom(0.5)
	}

	return (
		<div className={`zoom-container ${zoomActive ? 'active' : 'inactive'}`}>
			<div className="zoom-content-container zoom-buttons">
				<button
					type="button"
					className="btn zoom-btn zoom-out-btn"
					disabled={docScale <= 0.5}
					onClick={zoomOut}>
					<img src={zoom_out} alt="zoom out" />
				</button>
				{t('Zoom')}
				<button
					type="button"
					className="btn zoom-btn zoom-in-btn"
					disabled={docScale >= 3}
					onClick={zoomIn}>
					<img src={zoom_in} alt="zoom in" />
				</button>
			</div>
			<img
				src={arrow_down_white}
				alt="arrow down"
				className="arrow-down"
			/>
			<div className="zoom-content-container magnifying-glass">
				<img
					className="zoom-icon-magnifying-glass"
					src={magnifying_glass_gray}
					alt="zoom magnifying glass"
					onClick={() => setZoomActive(!zoomActive)}
				/>
			</div>
		</div>
	)
}

export default SignPnZoomButtons
