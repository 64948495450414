import React from 'react'

import './InformationBox.scss'

interface IInfoBox {
	infoType: 'info' | 'info-gray' | 'success' | 'post'
	css?: string
	message: string | React.JSX.Element
}

/**
 *
 * @param infoType type of message displayed. Will determine the type of styling the box will have
 * @param message message displayed
 * @param css optional additional class name to set styling
 * @returns
 */
export const InfoBox = ({ infoType, css, message }: IInfoBox) => (
	<div className={`info-box ${infoType} ${css}`}>{message}</div>
)
