import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import AuthMethodType from 'constants/values/authMethods'
import authenticationService from 'services/authServices'

interface NorwegianBankIdMobileLoginBlockProps {
	onError?(error: Error): void
}

function NorwegianBankIdMobileContentBlock({
	onError = () => {},
}: NorwegianBankIdMobileLoginBlockProps) {
	const [loading, setLoading] = useState<boolean>(false)

	async function norwegianBankIdMobileAuth() {
		setLoading(true)
		try {
			const redirectUrl =
				await authenticationService.norwegianBankIdRedirectLinkGet(
					AuthMethodType.norwegianBankIdMobile
				)
			window.location.href = redirectUrl
		} catch (error) {
			onError(error as Error)
			setLoading(false)
		}
	}

	const { t } = useTranslation()
	// @ts-ignore
	// prettier-ignore
	// const buttonRounded = (<ButtonRounded methods={[AuthMethodType.norwegianBankIdMobile]} onClick={norwegianBankIdMobileAuth}></ButtonRounded>)
	const buttonRounded = (
		<div
			key={AuthMethodType.norwegianBankIdMobile}
			className="NorwegianBankId pb-4">
			<b>{t('Login')}</b>
		</div>
	)

	return (
		<React.Fragment>
			{loading ? (
				<div className="login-container m-2 d-flex flex-column justify-content-center">
					<CircleSpinner />
				</div>
			) : (
				<div
					onClick={() => norwegianBankIdMobileAuth()}
					className="login-container m-2 d-flex flex-column justify-content-between align-items-center pointer">
					<div className="btn authButton NorwegianBankIdMobile"></div>
					<div>{buttonRounded}</div>
				</div>
			)}
		</React.Fragment>
	)
}
export default NorwegianBankIdMobileContentBlock
