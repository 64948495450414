import React, { useCallback, useContext } from 'react'
import { FormBuilder, formKeySelector } from '@tf/formbuilder'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { IApplicationContext } from 'interfaces/i-application-context-props'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import { FormFactory } from 'formBuilder/formFactory'
import { CustomerForm } from 'constants/enums/formTypes'
import { getLanguageLocale, getMarket } from 'utils/countryHelpers'
import { IApplicationProps } from 'interfaces/i-application-props'

import './ApplicationBlocks.scss'

const PersonalInformation = () => {
	const { t } = useTranslation()
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)

	let formSchema = FormFactory(
		CustomerForm.ApplicationPersonalInformationForm,
		{
			formName: 'personalInformationForm',
			blockName: 'personalInformationForm',
			market: getMarket(),
			locale: getLanguageLocale(),
			getDataUrl: '',
			validationErrorList: false,
			requiredMessage: t('ValidateRequired'),
			submitButtonText: t('Next'),
		},
		loanApplication as IApplicationProps
	)
	const formStateValues = useSelector(
		formKeySelector(formSchema.formMeta.formName, 'controlsState')
	)

	const updateInformation = useCallback(
		(values) => {
			dispatchLoanApplication({
				type: LOAN_APPLICATION_ACTIONS.SET_UPDATE_APPLICATIONDATA,
				payload: {
					...loanApplication,
					updateApplicationData: {
						...loanApplication.updateApplicationData,
						applicant: {
							...loanApplication.updateApplicationData?.applicant,
							personalInfo: {
								nationality: values.nationality,
								gender: values.gender,
								maritalStatus: values.maritalStatus,
								countryOfBirth: values.countryOfBirth,
								firstName: values.firstName,
								lastName: values.lastName,
							},
							accommodation: {
								...loanApplication.updateApplicationData
									?.applicant?.accommodation,
								childrenInFamily: values.childrenInFamily,
							},
						},
					},
				},
			})
			dispatchMsp({
				type: MSP_ACTIONS.SET_INDEX,
				payload: {
					index: msp.index + 1,
				},
			})
		},
		[formStateValues] // eslint-disable-line react-hooks/exhaustive-deps
	)

	return (
		<div className="personal-information">
			<FormBuilder
				formSchema={formSchema!}
				apiError={undefined}
				showErrorsInForm={true}
				showSubmitButton={true}
				onSubmit={(values) => updateInformation(values)}
			/>
		</div>
	)
}

export default PersonalInformation
