export enum MoneySource {
	Other,
	Income,
	Savings,
	OtherSavings,
	Investments,
	Inheritance,
	Gift,
	SalesOfProperty,
	SalesOfVehicle,
	SalesOfCulture,
	SaleOfCompany,
	Gambling,
	OtherBusiness,
	CapitalGains,
	InsuranceReimbursement,
	SocialInsuranceAllowance,
	Donation,
}
