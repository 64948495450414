import React, { useState, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
//@ts-ignore
import { Document, Page, pdfjs } from 'react-pdf'

import { SignPnContext } from '../../SignPnEs'
import SignPnZoomButtons from './SignPnZoomButtons'
import SkeletonLoader from 'components/skeletonloader/SkeletonLoader'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const SignPnDocumentViewer = ({ blob }: { blob: Blob }) => {
	const { t } = useTranslation()
	const { docScale } = useContext(SignPnContext)
	const [numPages, setNumPages] = useState(0)
	const [docWidth, setDocWidth] = useState<number>()
	const [docHeight, setDocHeight] = useState<number>()

	const getDeviceWidth = useCallback(() => {
		let element = document.getElementById('view-pn-container')
		if (element) {
			setDocHeight(element.offsetHeight)
			setDocWidth(element.offsetWidth - 15)
		}
	}, [])

	const onDocumentLoadSuccess = useCallback(
		({ numPages }: any) => {
			setNumPages(numPages)
			getDeviceWidth()
		},
		[getDeviceWidth]
	)

	const getDocument = useCallback(
		(blob: any) => {
			return (
				<React.Fragment>
					<Document
						file={window.URL.createObjectURL(blob)}
						onLoadSuccess={onDocumentLoadSuccess}
						renderTextLayer={false}
						error={<ErrorBox message={t('ErrorViewPn')} />}
						loading={
							<SkeletonLoader
								width={docWidth ? docWidth + 'px' : '100%'}
								height={docHeight ? docHeight + 'px' : '100%'}
							/>
						}
						noData={t('ErrorViewPn')}
						renderMode={'canvas'}>
						{Array.from(new Array(numPages), (el, index) => (
							<Page
								key={`page_${index + 1}`}
								pageNumber={index + 1}
								renderTextLayer={false}
								renderAnnotationLayer={false}
								width={docWidth}
								height={docHeight}
								scale={docScale}
								loading={
									<SkeletonLoader
										width={
											docWidth ? docWidth + 'px' : '100%'
										}
										height={
											docHeight
												? docHeight + 'px'
												: '100%'
										}
									/>
								}
							/>
						))}
					</Document>
					<SignPnZoomButtons />
				</React.Fragment>
			)
		},
		[docWidth, docHeight, docScale, onDocumentLoadSuccess, t, numPages]
	)

	return <div className="view-pn-container">{getDocument(blob)}</div>
}

export default SignPnDocumentViewer
