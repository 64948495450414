import Footer from 'components/footer-blocks/footer/Footer'
import Menu from 'components/menu/Menu'
import React from 'react'

import './ApplicationPage.scss'
import ApplicationContext from 'contexts/ApplicationContext'

const ApplicationPage = () => {
	return (
		<>
			<div id="application-page">
				<Menu />
				<ApplicationContext />
				<Footer />
			</div>
		</>
	)
}

export default ApplicationPage
