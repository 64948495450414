export enum CookieType {
	CustomerName = 'customerName',
	CustomerInfo = 'customerInfo',
	RedirectUrl = 'redirectUrl',
	KycRenewal = 'timeToRenew',
	LoanInfo = 'loanInfo',
	ClaCustomer = 'ClaCustomer',
	Sbl = 'sbl',
	ApplicationQuery = 'applicationQuery',
}
