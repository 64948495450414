/* eslint-disable no-fallthrough */
import appConfig from 'app.config'
import { logError, logEvent } from 'appinsights/appinsights'
import { IAfterbanksParameters } from 'interfaces/i-afterbanks'

declare global {
	interface Window {
		parameters: IAfterbanksParameters
	}
}

export const afterbanksSettings = {
	init: (applicationId: string, useSandBox: number) =>
		(window.parameters = {
			id: applicationId,
			action: 'read',
			// Widget Settings
			countryCode: 'ES',
			banksShownPSD2: '',
			banksShownV3: 'ALL',
			defaultBank: '',
			autoStart: 0,
			defaultLanguage: 'ES',
			showSandbox: useSandBox,
			showSplashScreen: 0,
			showCheckTerms: 1,
			URLredirectAfterOK: '',
			URLredirectAfterKO: '',
			// Parameters READ PSD2
			dailyFrequency: 4,
			validUntil: new Date().getDate(),
		}),
	function: function receiveFromFrame(ev: any) {
		if (
			ev.data !== '' &&
			ev.data.code !== undefined &&
			ev.data.message !== undefined
		) {
			let code = ev.data.code
			let message = ev.data.message
			let widgetContainer = document.getElementById('arcopayContainer')!
			const submitBtn = document.getElementById(
				'submit-application-button'
			)
			const errorContainer = document.getElementById(
				'afterbanks-error-container'
			)!

			// console.warn(`${code} - ${message}`)
			switch (code) {
				// Success
				case 200:
					/*@ts-expect-error*/
					if (parameters.action === 'read') {
						// const e = document.getElementById('frameResponse')!
						// e.innerHTML = 'Lectura finalizada correctamente.'

						sessionStorage.setItem('psd2Status', code)

						if (submitBtn) {
							submitBtn.classList.add('show')
							submitBtn.scrollIntoView({ behavior: 'smooth' })
							submitBtn.click()
						}
						
						logEvent(
							'Afterbanks result:  Reading finished successfully',
							{
								Success: `Reading finished successfully for applicationId ${window.parameters.id}`,
							}
						)
					}
					break
				case 400: /*In any of these cases (433, 420, 430), notification is triggered from application insights, following this we need to contact afterbanks with error messages. */
				case 420:
				case 430:
					errorContainer.classList.add('show')
					errorContainer.scrollIntoView({ behavior: 'smooth' })
					logError({
						name: 'ApplicationPage Spain: Afterbanks (arcopay) error',
						message: `Afterbanks ERROR: ${code}. ApplicationId: ${window.parameters.id}. Message: ${message}`,
					})
					break
				// case 404: // Not valid credentials from user
				// 	break
				// Widget size change
				case 9999:
					widgetContainer.style.height = '450px'
					widgetContainer.style.width = '100%'
					widgetContainer.style.display = 'block'
					const w = document.getElementById(
						'iframeArcopay'
					) as HTMLIFrameElement
					w.contentWindow?.postMessage(
						{ action: 'changeIframeHeight' },
						'https://www.afterbanks.com/'
					)
					break
				// Debugger
				default:
					// document.getElementById(
					// 	'frameResponse'
					// )!.innerHTML = `${code} - ${message}`
					logEvent(
						'Afterbanks result:' +
							`{Code: ${code}, Message: ${message}}`,
						{ Code: code, Message: message }
					)
					break
			}
		}
	},
	eventListener: (listenerFunction: any) => {
		return window.addEventListener('message', listenerFunction, false)
	},
	baseScript: appConfig.afterbanksScriptUrl,
}
