import Axios from 'axios'
import { setUpApplicationInterceptorsFor } from './interceptors'
import { IUpdateBankInfo } from 'interfaces/i-application-information'
import { cdaApiRoutes } from 'constants/routes/cdaApiRoutes'
import { getHeadersWithJwtToken } from 'utils/authHelpers'

const transportWithCredentials = setUpApplicationInterceptorsFor(
	Axios.create({})
)

transportWithCredentials.defaults.headers.post['Content-Type'] =
	'application/json'
transportWithCredentials.defaults.headers.post['Access-Control-Allow-Origin'] =
	'*'
transportWithCredentials.defaults.headers.post['Access-Control-Allow-Headers'] =
	'Origin, X-Requested-With, Content-Type, Accept'

const updateBankInfoServices = {
	async updateBankInfo(bankInfo: IUpdateBankInfo) {
		let data = await transportWithCredentials.post(
			cdaApiRoutes.updateBankInfo,
			bankInfo,
			await getHeadersWithJwtToken()
		)

		if (data.status === 200) {
			return data.status
		}
		throw new Error('Something went wrong updating bankinfo.')
	},

	async removeDirectDebit(applicationId: number) {
		let data = await transportWithCredentials.post(
			cdaApiRoutes.removedirectdebit,
			{ applicationId: applicationId },
			await getHeadersWithJwtToken()
		)
		if (data.status === 200) {
			return data.status
		}
		throw new Error(
			'Something went wrong removing the direct debit account information.'
		)
	},
}

export default updateBankInfoServices
