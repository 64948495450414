import React from 'react'
import { useTranslation } from 'react-i18next'
import './Heading.scss'
interface IHeading {
	title: string
	description?: string
}
const Heading = ({ title, description }: IHeading) => {
	const { t } = useTranslation()

	return (
		<div className="heading">
			<h5 className="title">{t(title)}</h5>
			<div className="description">{t(description!)}</div>
		</div>
	)
}
export default Heading
