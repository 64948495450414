import appConfig from 'app.config'
import React, {
	createContext,
	Context,
	useState,
	PropsWithChildren,
} from 'react'
import { userManager } from 'utils/authHelpers'
interface IAuthContext {
	isAuthenticated: boolean
	market: string
}

const AuthContext: Context<IAuthContext> = createContext<IAuthContext>({
	isAuthenticated: false,
	market: appConfig.country,
})

/**
 * Sets isAuthenticated prop in context if customer is authenticated or not on page
 * @param children
 * @returns isAuthenticated
 */
export function UseAuthProvider({ children }: PropsWithChildren<any>) {
	const checkAuth: any = React.useCallback(async () => {
		const user = await userManager.getUser()
		if (!Boolean(user) || user?.expired) {
			return setIsAuthenticated(false)
		}
		return setIsAuthenticated(true)
	}, [])
	const market = appConfig.country
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(checkAuth)

	return (
		<AuthContext.Provider value={{ isAuthenticated, market }}>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthContext
