import React from 'react'
import { UserManager } from 'oidc-client'
import { Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { CookieType } from 'constants/enums/cookieTypes'
import { logError } from 'appinsights/appinsights'
import { getClientSettings } from 'utils/authHelpers'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

import tfbank21_logo_white from 'img/tf-logos/tfbank21-logo-white.svg'
import './CallbackPage.scss'

function CallbackPage() {
	const { t } = useTranslation()
	const [error, setError] = React.useState<Error>()

	React.useEffect(
		() => {
			signin()
		},
		// eslint-disable-next-line
		[]
	)

	async function signin() {
		const userManager = new UserManager(getClientSettings('query'))

		try {
			if (window.location.search.indexOf('code') === -1) {
				userManager.signinRedirect()
				return
			} else if (window.location.search.indexOf('code') > -1) {
				await userManager.signinCallback()

				let value =
					sessionStorage.getItem(CookieType.RedirectUrl) ?? '/'
				window.location.replace(value)
			}
		} catch (error) {
			logError(error as Error)
			setError(error as Error)
		}
	}
	const ErrorButton = () => {
		return (
			<button
				className="btn btn-outline-danger"
				onClick={() => setError(undefined)}>
				{t('Close')}
			</button>
		)
	}

	return (
		<div id="callback-page">
			<Image
				src={tfbank21_logo_white}
				className="tfbank-logo logout"
				alt="TF Bank AB"
			/>
			<div className="callback-container">
				{error ? (
					<div className="error-container">
						<ErrorBox
							header="Callback Error"
							message={error.message}
							button={<ErrorButton />}
						/>
					</div>
				) : (
					<div className="spinner-container">
						<CircleSpinner />
					</div>
				)}
			</div>
		</div>
	)
}

export default CallbackPage
