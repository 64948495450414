import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TranslationFile } from 'i18n'

import { checkIsMobile, stringifyNum } from 'utils/commonhelpers'
import { HeaderTooltip } from 'components/tooltips/header-tooltip/HeaderTooltip'
import { ReadMoreExpander } from 'components/read-more-expander/ReadMoreExpander'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { InfoBox } from 'components/message-boxes/information-box/InformationBox'
import { getCurrency } from 'utils/countryHelpers'
import { getLoanHeaderValues } from 'constants/values/header-values/loanHeaderValues'

import './LoanOfferHeader.scss'

interface ILoanOfferHeader {
	text?: string
}

const LoanOfferHeader = ({ text }: ILoanOfferHeader) => {
	const { t } = useTranslation([
		TranslationFile.Translation,
		TranslationFile.Forms,
		TranslationFile.Dropdown,
	])
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)
	const [contentText, setContentText] = useState<string>('LoanOfferText')

	const getContent = useCallback(() => {
		if (text) {
			setContentText(text)
		}
		return
	}, [text])

	useEffect(() => {
		getContent()
	}, [text, getContent])

	return (
		<div className="loan-offer-header">
			<h1 className="top-header">{t('LoanOfferHeader')}</h1>
			<hr />
			<InfoBox infoType="info" message={t(contentText)} />

			<div className="loan-offer-box">
				<div className="loan-offer-box-amount">
					<span>{t('LoanAmount')}</span>
					<br />
					<span>
						{stringifyNum(
							loanApplication.applicationData?.approvedAmount,
							{
								currency: getCurrency(),
								style: 'currency',
							}
						)}
					</span>
				</div>
				<div className="loan-offer-box-installment">
					<span>{t('MonthlyInstallment')}</span>
					<br />
					<span>
						{stringifyNum(
							loanApplication.applicationData?.instalment,
							{
								currency: getCurrency(),
								style: 'currency',
							}
						)}
					</span>
				</div>
			</div>
			<ReadMoreExpander
				expanded={!checkIsMobile()}
				showExpander={checkIsMobile()}
				children={
					<div className="loan-offer-table">
						{getLoanHeaderValues(
							t,
							loanApplication.applicationData
						).map((item, key) => {
							return (
								<div
									key={`loan-offer-item-${key}`}
									className="loan-offer-item">
									<div className="loan-offer-item-information">
										{t(item.text)}
										{item.tooltip ? (
											<HeaderTooltip
												translationString={
													item.tooltip.text
												}
												value={item.tooltip.value}
												secondValue={
													item.tooltip.secondValue
												}
											/>
										) : null}
									</div>
									<div className="loan-offer-item-value">
										{item.value}
									</div>
								</div>
							)
						})}
					</div>
				}
			/>
		</div>
	)
}

export default LoanOfferHeader
