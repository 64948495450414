import React, { Suspense } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'

import appConfig from 'app.config'
import ErrorFallbackPage from 'pages/error-fallback/ErrorFallbackPage'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import { logError } from 'appinsights/appinsights'
import RootRoute from 'pages/route/RootRoute'

import './App.scss'

import {
	faSortDown,
	faSortUp,
	faChevronDown,
	faChevronUp,
	faDownload,
	faPhoneAlt,
	faEnvelope,
	faSignInAlt,
	faCookieBite,
	faInfoCircle,
	faTimesCircle,
	faTrashAlt,
	faSearchDollar,
	faGhost,
} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { UseAuthProvider } from 'contexts/AuthContext'
library.add(
	faChevronDown,
	faChevronUp,
	faDownload,
	faPhoneAlt,
	faEnvelope,
	faSignInAlt,
	faCookieBite,
	faInfoCircle,
	faSortUp,
	faSortDown,
	faTimesCircle,
	faTrashAlt,
	faSearchDollar,
	faGhost
)

const MetaTags = ({ isEnvProd }: any) => {
	const description = document.querySelector('meta[name="description"]')
	if (description) {
		description.remove()
	}
	const getTitle = () => {
		switch (appConfig.country) {
			case 'dk':
				return 'My Pages | TF Bank'
			case 'ee':
				return 'TF Bank iseteenindus'
			case 'fi':
				return 'Omat sivut | TF Bank'
			case 'es':
				return 'Application Page | TF Bank Spain'
			case 'no':
				return 'TF Bank Minside'
			default:
				return 'Application Page | TF Bank'
		}
	}

	const getDesc = () => {
		switch (appConfig.country) {
			case 'dk':
				return 'My Pages | TF Bank'
			case 'ee':
				return 'Vaata infot oma arve, maksegraafiku või laenujäägi kohta'
			case 'fi':
				return 'Tarkista oman laina- tai säästötuotteesi tiedot TF Bankin Omilta sivuilta vuorokauden ympäri. Kirjaudu sisään!'
			case 'es':
				return 'My Pages TF Bank Spain'
			case 'no':
				return 'TF Bank Minside'
			default:
				return 'Application Pages TF Bank AB'
		}
	}

	const getMeta = () => {
		if (isEnvProd) {
			return [
				{
					name: 'description',
					content: `${getDesc()}`,
				},
			]
		} else {
			return [
				{
					name: 'description',
					content: `${getDesc()}`,
				},
				{
					name: 'robots',
					content: 'none',
				},
			]
		}
	}

	return <Helmet title={getTitle()} meta={getMeta()} />
}

function errorFallback({ error, resetErrorBoundary }: FallbackProps) {
	return (
		<ErrorFallbackPage
			error={error}
			resetErrorBoundary={resetErrorBoundary}
		/>
	)
}

const App: React.FC = () => {
	const isEnvProd = process.env.REACT_APP_ENVIRONMENT === 'production'

	return (
		<HelmetProvider>
			<BrowserRouter>
				<div className={'App ' + appConfig.country}>
					<Suspense fallback={<CircleSpinner />}>
						<ErrorBoundary
							fallbackRender={errorFallback}
							onError={(e) => logError(e)}>
							<UseAuthProvider>
								<MetaTags isEnvProd={isEnvProd} />
								<RootRoute />
							</UseAuthProvider>
						</ErrorBoundary>
					</Suspense>
				</div>
			</BrowserRouter>
		</HelmetProvider>
	)
}

export default App
