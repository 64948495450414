import { TranslationFile } from 'i18n'
import { useTranslation } from 'react-i18next'

import appConfig from 'app.config'
import { IApplicationProps } from 'interfaces/i-application-props'
import { GetAllCountriesWithTranslation } from 'utils/countryHelpers'
import { formatToStringLocale } from 'utils/dateAndTimeHelpers'
import { Gender } from 'constants/enums/genderTypes'
import { FormatEnumToList } from 'utils/commonhelpers'
import { MaritalStatus } from 'constants/enums/maritalStatusTypes'
import { PropertyType } from 'constants/enums/propertyTypes'

export const ApplicationPersonalInformationForm = (data: IApplicationProps) => {
	const { t } = useTranslation([
		TranslationFile.Translation,
		TranslationFile.Forms,
		TranslationFile.Countries,
	])

	return {
		fieldSetName: '',
		formName: 'PersonalInformationForm',
		description: t('forms:PersonalInformationDescription'),
		collapsable: false,
		show: true,
		visible: true,
		market: appConfig.country,
		fullSize: true,
		col: 12,
		controls: [
			{
				required: true,
				controlName: 'firstName',
				type: 'text',
				label: t('forms:FirstName'),
				value: data.applicationData?.personalInfo?.firstName,
				visible: true,
				// disabled: true,
				col: 12,
			},
			{
				required: true,
				controlName: 'lastName',
				type: 'text',
				label: t('forms:LastName'),
				value: data.applicationData?.personalInfo?.lastName,
				visible: true,
				// disabled: true,
				col: 12,
			},
			{
				required: true,
				controlName: 'ssn',
				type: 'text',
				label: t('forms:Ssn'),
				value: data.personalData?.ssn,
				visible: true,
				disabled: true,
				col: 12,
			},
			{
				required: true,
				controlName: 'nationality',
				type: 'dropdown',
				label: t('forms:Nationality'),
				value: data.applicationData?.personalInfo?.nationality,
				options: GetAllCountriesWithTranslation(appConfig.country),
				visible: true,
				col: 12,
			},
			{
				required: true,
				controlName: 'birthDate',
				type: 'text',
				label: t('forms:BirthDate'),
				value: formatToStringLocale(data.personalData?.birthDate!),
				visible: true,
				disabled: true,
				col: 12,
			},
			{
				required: true,
				controlName: 'countryOfBirth',
				type: 'dropdown',
				label: t('forms:CountryOfBirth'),
				options: GetAllCountriesWithTranslation(appConfig.country),
				value: data.applicationData?.personalInfo?.countryOfBirth,
				visible: true,
				col: 12,
			},
			{
				required: true,
				controlName: 'gender',
				type: 'dropdown',
				label: t('forms:Gender'),
				options: [
					{
						value: Gender.Female,
						label: t('dropdown:Female'),
					},
					{
						value: Gender.Male,
						label: t('dropdown:Male'),
					},
				],
				value:
					data.applicationData?.personalInfo?.gender ===
					Gender.Unknown
						? null
						: data.applicationData?.personalInfo?.gender,
				visible: true,
				col: 12,
			},
			{
				required: true,
				controlName: 'maritalStatus',
				type: 'dropdown',
				label: t('forms:MaritalStatus'),
				options: FormatEnumToList(
					MaritalStatus,
					PropertyType.MaritalStatus,
					TranslationFile.Dropdown
				),
				value:
					data.applicationData?.personalInfo?.maritalStatus ===
					MaritalStatus.Unknown
						? null
						: data.applicationData?.personalInfo?.maritalStatus,
				visible: true,
				col: 12,
			},
			{
				required: true,
				controlName: 'childrenInFamily',
				type: 'text',
				label: t('forms:ChildrenInFamily'),
				validations: [
					{
						code: 'MustMatchPattern',
						value: '\\b^([0-9]|[1-9][0-9]|100)\\b$',
						message: t('forms:ValidationMsgChildrenInFamily'),
					},
				],
				value: data.applicationData?.accommodation?.childrenInFamily,
				visible: true,
				col: 12,
			},
		],
	}
}
