import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Menu from 'components/menu/Menu'
import Footer from 'components/footer-blocks/footer/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './NotFoundPage.scss'

const NotFoundPage = () => {
	const { t } = useTranslation()
	const [isDecember, setIsDecember] = useState<boolean>(false)

	useEffect(() => {
		let today = new Date()
		let month = today.getMonth()
		if (month === 11) {
			setIsDecember(true)
		} else {
			setIsDecember(false)
		}
	}, [])

	return (
		<div id="not-found-page" className={isDecember ? 'x-mas' : ''}>
			{isDecember ? <Winter /> : null}
			<Menu />
			<div className="not-found-container">
				<div className="not-found-head">
					<span>4</span>
					<FontAwesomeIcon icon="ghost" />
					<span>4</span>
				</div>
				<p className="not-found-text">{t('404Message')}</p>
			</div>
			<Footer />
		</div>
	)
}

const Winter = () => {
	/* With thanks to Johannes */
	return (
		<div className="snow-wrap">
			<div className="snow" />
		</div>
	)
}

export default NotFoundPage
