import React, { useContext } from 'react'

import Multistep from 'components/multistep/Multistep'
import { LifeCycleStatus } from 'constants/enums/lifeCycleStatus'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { IMultistepComponent } from 'interfaces/i-multistep'
import { CheckIsLifeCycleStatus, isPsd2StatusSigned } from 'utils/loanHelpers'
import MissingKyc from 'components/application-blocks/application-offer-blocks/MissingKyc/MissingKyc'
import { PpiDk } from 'components/application-blocks/application-offer-blocks/Ppi/PpiDk'
import { UpdateBankInfoDk } from 'components/application-blocks/application-offer-blocks/UpdateBankInfo/UpdateBankInfoDk'
import { SignPsd2Dk } from 'components/application-blocks/application-offer-blocks/SignPsd2/SignPsd2Dk'
import { UploadDocumentsDk } from 'components/application-blocks/application-offer-blocks/UploadDocuments/UploadDocumentsDk'
import { SignPnDk } from 'components/application-blocks/application-offer-blocks/SignPn/SignPnDk'
import ThankYou from 'components/application-blocks/common-blocks/ThankYou/ThankYou'

export const LoanApplicationStepsDk = () => {
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)

	const loanApplicationSteps: IMultistepComponent[] = [
		{
			id: 1,
			header: 'forms:ApplicationMissingKyc',
			body: <MissingKyc />,
			show:
				loanApplication.applicationData?.personalInfo?.missingKyc !==
					null &&
				CheckIsLifeCycleStatus(
					[
						LifeCycleStatus.PrintPromissoryNote,
						LifeCycleStatus.ReceiveSigning,
						LifeCycleStatus.AwaitAccept,
					],
					loanApplication.applicationData?.lifeCycleStatus!
				),
			stepCompleted: false,
		},
		{
			id: 2,
			header: 'Ppi',
			body: <PpiDk />,
			show:
				CheckIsLifeCycleStatus(
					[
						LifeCycleStatus.PrintPromissoryNote,
						LifeCycleStatus.AwaitAccept,
					],
					loanApplication.applicationData?.lifeCycleStatus!
				) && !loanApplication.applicationData?.ppi,
			stepCompleted: false,
		},
		{
			id: 3,
			header: 'MarketingHeading',
			body: <UpdateBankInfoDk />,
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.PrintPromissoryNote,
					LifeCycleStatus.AwaitAccept,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
		{
			id: 4,
			header: 'PSD2',
			body: <SignPsd2Dk />,
			show:
				CheckIsLifeCycleStatus(
					[
						LifeCycleStatus.PrintPromissoryNote,
						LifeCycleStatus.ReceiveSigning,
						LifeCycleStatus.AwaitAccept,
					],
					loanApplication.applicationData?.lifeCycleStatus!
				) && !isPsd2StatusSigned(loanApplication.applicationData!),
			stepCompleted: isPsd2StatusSigned(loanApplication.applicationData!),
		},
		{
			id: 5,
			header: 'UploadDocument',
			body: <UploadDocumentsDk />,
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.PrintPromissoryNote,
					LifeCycleStatus.ReceiveSigning,
					LifeCycleStatus.AwaitAccept,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
		{
			id: 6,
			header: 'BeforeLoanDisbursement',
			body: <SignPnDk />,
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.PrintPromissoryNote,
					LifeCycleStatus.ReceiveSigning,
					LifeCycleStatus.AwaitAccept,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
		{
			id: 8,
			header: 'UploadDocument',
			body: <UploadDocumentsDk />,
			show: CheckIsLifeCycleStatus(
				[LifeCycleStatus.DocumentsUpload],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
		{
			id: 9,
			header: 'ThankYou',
			body: <ThankYou />,
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.PrintPromissoryNote,
					LifeCycleStatus.ReceiveSigning,
					LifeCycleStatus.AwaitAccept,
					LifeCycleStatus.DocumentsUpload,
					LifeCycleStatus.InternalCheck,
					LifeCycleStatus.SendToBo,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
	]

	return (
		<Multistep
			multisteps={loanApplicationSteps}
			resetInitialState={false}
		/>
	)
}
