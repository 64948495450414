import { TFunction } from 'i18next'
import Axios from 'axios'
import { setUpApplicationInterceptorsFor } from './interceptors'
import { authAndSignApiRoutes } from 'constants/routes/authAndSignApiRoutes'
import { getHeadersWithJwtToken } from 'utils/authHelpers'
import { IAttachVideoIdentification, ICreateVideoIdentity } from 'interfaces/i-video-id'

const transportWithCredentials = setUpApplicationInterceptorsFor(
	Axios.create({})
)

transportWithCredentials.defaults.headers.post['Content-Type'] =
	'application/json'
transportWithCredentials.defaults.headers.post['Access-Control-Allow-Origin'] =
	'*'
transportWithCredentials.defaults.headers.post['Access-Control-Allow-Headers'] =
	'Origin, X-Requested-With, Content-Type, Accept'

const videoIdServices = {
	async attachVideoIdentification(
		videoIdentificationData: IAttachVideoIdentification,
		t: TFunction
	) {
		const data = await transportWithCredentials.post(
			authAndSignApiRoutes.attachVideoIdentification,
			videoIdentificationData,
			await getHeadersWithJwtToken()
		)
		if (data.status === 200) {
			return data
		}
		throw new Error(t('VideoIdError'))
	},
		
	async createVideoIdentity(
		videoIdentityData: ICreateVideoIdentity,
		t: TFunction
	) {
		const data = await transportWithCredentials.post(
			authAndSignApiRoutes.createVideoIdentity,
			videoIdentityData,
			await getHeadersWithJwtToken()
		)
		if (data.status === 200) {
			return data
		}
		throw new Error(t('VideoIdError'))
	},
}

export default videoIdServices
