export enum DocumentTypes {
	Other = 'Other',
	SignedPromissoryNote = 'SignedPromissoryNote',
	IdCard = 'IdCard',
	TaxReturn = 'TaxReturn',
	BankStatement = 'BankStatement',
	EmploymentCertificate = 'EmploymentCertificate',
	PromissoryNote = 'PromissoryNote',
	RepaymentPlan = 'RepaymentPlan',
	Payroll = 'Payroll',
	Analytic = 'Analytic',
	CarDocument = 'CarDocument',
	ApplicantConsent = 'ApplicantConsent',
	SpouseConsent = 'SpouseConsent',
	ApplicantSignedConsent = 'ApplicantSignedConsent',
	SpouseSignedConsent = 'SpouseSignedConsent',
	OriginalData = 'OriginalData',
	IdNowIdentification = 'IdNowIdentification',
	PpiContract = 'PpiContract',
	PpiContractSigned = 'PpiContractSigned',
	ActivationSlip = 'ActivationSlip',
	Passport = 'Passport',
	IdOther = 'IdOther',
	AddressProof = 'AddressProof',
	ResidencePermit = 'ResidencePermit',
	RegistrationCertificate = 'RegistrationCertificate',
	VideoId = 'VideoId',
}
