import React, { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { SignPnContext } from '../SignPnEs'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { OtpDistributionChannel } from 'constants/enums/signPnTypes'
import pnServices from 'services/pnServices'
import { IInitiateESigningES } from 'interfaces/i-sign-pn'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import { otpSignMethods } from 'constants/values/signPnValues'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

export const SignPnOtpSignMethodSelect = () => {
	const { t } = useTranslation()

	const {
		otpDistributionChannel,
		setOtpDistributionChannel,
		nextStep,
		otpDistributionRetry,
	} = useContext(SignPnContext)
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<boolean>(false)
	const [next, setNext] = useState<boolean>(false)
	const isActive = (method: OtpDistributionChannel) =>
		method === otpDistributionChannel
	const product = loanApplication.applicationData?.product

	const signPnInit = async () => {
		setLoading(true)
		setError(false)
		try {
			let response = await pnServices.signPnInit({
				applicationId: loanApplication.applicationData?.loanNumber,
				signingMethod: otpDistributionChannel,
				product: product!.toString()
			} as IInitiateESigningES)
			if (response === 200) setNext(true)
		} catch (error) {
			setError(true)
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (next) {
			nextStep()
		}
		return () => {
			setNext(false)
		}
	}, [next, nextStep])

	return (
		<div className="sign-pn-otp-sign-method-select">
			<div>{t('SelectOtpMethodDescription')}</div>
			{loading ? (
				<div className="col-spinner">
					<CircleSpinner />
				</div>
			) : (
				<>
					{otpDistributionRetry ? (
						<div className="otp-select-btn-section">
							{otpSignMethods.map((item) => {
								if (item.show) {
									return (
										<div
											className={`otp-method-btn ${
												isActive(
													item.otpDistributionChannel
												)
													? ' active'
													: ''
											}`}
											onClick={() =>
												setOtpDistributionChannel(
													item.otpDistributionChannel
												)
											}
											key={item.otpDistributionChannel}>
											<img
												src={`${
													isActive(
														item.otpDistributionChannel
													)
														? item.iconActive
														: item.iconInactive
												}`}
												className={item.className}
												alt={`${item.className} icon`}
											/>{' '}
											<Trans t={t} i18nKey={item.title}>
												Button <wbr /> name
											</Trans>
										</div>
									)
								}
								// eslint-disable-next-line array-callback-return
								return
							})}
						</div>
					) : (
						<div className="otp-select-btn-section">
							{otpSignMethods.map((item) => {
								if (
									item.otpDistributionChannel ===
									OtpDistributionChannel.SMS
								) {
									return (
										<div
											className={`otp-method-btn ${
												isActive(
													item.otpDistributionChannel
												)
													? ' active'
													: ''
											}`}
											onClick={() =>
												setOtpDistributionChannel(
													item.otpDistributionChannel
												)
											}
											key={item.otpDistributionChannel}>
											<img
												src={`${
													isActive(
														item.otpDistributionChannel
													)
														? item.iconActive
														: item.iconInactive
												}`}
												className={item.className}
												alt={`${item.className} icon`}
											/>{' '}
											<Trans t={t} i18nKey={item.title}>
												Button <wbr /> name
											</Trans>
										</div>
									)
								}
								// eslint-disable-next-line array-callback-return
								return
							})}
						</div>
					)}
					{error ? (
						<div className="error-box-container">
							<ErrorBox message={t('ErrorMsg')} />
						</div>
					) : null}
					<div className="send-otp-button-section">
						<button
							type="button"
							className="btn btn-primary"
							disabled={
								otpDistributionChannel ===
								OtpDistributionChannel.Unknown
							}
							onClick={() => signPnInit()}>
							{t('OtpSend')}
						</button>
					</div>
				</>
			)}
		</div>
	)
}
