import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import SmoothCollapse from 'react-smooth-collapse'

import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import { DocumentTypes } from 'constants/enums/documentTypes'
import { IApplicationProps } from 'interfaces/i-application-props'
import {
	IDocumentDto,
	IDocumentPageProps,
	IUploadDocuments,
} from 'interfaces/i-upload-documents'
import { CheckHasApplicationDocuments } from 'utils/loanHelpers'
import documentServices from 'services/documentServices'
import { downloadBlob } from 'utils/documentHelpers'
import { ReadMoreExpander } from 'components/read-more-expander/ReadMoreExpander'
import { checkIsMobile } from 'utils/commonhelpers'
import DropZone from './DropZone'
import { InfoBox } from 'components/message-boxes/information-box/InformationBox'
import ButtonSpinner from 'components/button-spinner/ButtonSpinner'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'
import appConfig from 'app.config'

interface IProps {
	documentTypes: IUploadDocuments[]
	documentsNeedUpload: DocumentTypes[]
	applicationData: IApplicationProps
	psd2Status?: boolean
	needPsd2AndDocuments?: boolean
}

export const UploadDocumentsDropZoneItems = ({
	documentTypes,
	documentsNeedUpload,
	applicationData,
	psd2Status = false,
	needPsd2AndDocuments = false,
}: IProps) => {
	const { t } = useTranslation()
	const { msp, dispatchMsp } = useContext(MspDispatchContext)
	const [files, setFiles] = useState<IDocumentDto[]>([])
	const [pageProps, setPageProps] = useState<IDocumentPageProps>({
		disabled: true,
		loading: false,
		error: undefined,
	})
	const [isSuccess, setIsSuccess] = useState(false)
	const [counter, setCounter] = useState<number>()
	const [documents, setDocuments] = useState()
	const [expanded, setExpanded] = useState<boolean>(false)
	const [disableNextButton, setDisableNextButton] = useState<boolean>(true)
	const isCountryDk = appConfig.country === 'dk';

	useEffect(() => {
		if (files!.length > 0) {
			setPageProps({
				...pageProps,
				disabled: false,
			})
		} else {
			setPageProps({
				...pageProps,
				disabled: true,
			})
		}
		// eslint-disable-next-line
	}, [files, pageProps.disabled])

	useEffect(() => {
		checkCanCustomerGoToNextStep()
		//eslint-disable-next-line
	}, [
		applicationData,
		documentsNeedUpload,
		psd2Status,
		pageProps.disabled,
		documents,
	])

	function checkCanCustomerGoToNextStep() {
		if (isCountryDk){
			setDisableNextButton(false)	
		}    

		if (needPsd2AndDocuments) {
			checkIsDocumentUploadDone() && psd2Status
				? setDisableNextButton(false)
				: setDisableNextButton(true)
		 } else {
			checkIsDocumentUploadDone() || psd2Status
				? setDisableNextButton(false)
				: setDisableNextButton(true)
		}
	}
	
	const checkIsDocumentUploadDone = () => {
		if (documents !== undefined) {
			return CheckHasApplicationDocuments(documents!, documentsNeedUpload)
		} else {
			return false
		}
	}

	const onSubmitDocuments = () => {
		setPageProps({ ...pageProps, loading: true })
		setCounter(0)

		files.map(async (file: IDocumentDto) => {
			// let fileEnding = file.type.split('/').pop()
			const formData = new FormData()
			formData.append('documentType', file.documentType)
			formData.append('document.content', file.content)
			formData.append('document.contentType', file.content.type)
			formData.append('document.fileName', file.content.name)

			try {
				await documentServices
					.UploadDocument(
						formData,
						applicationData.applicationData?.loanNumber!,
						t
					)
					.then(() => setCounter((preState: any) => preState + 1))
			} catch (e) {
				console.warn(e)
				setPageProps({
					...pageProps,
					error: new Error(t('UploadDocumentError')), //name on file
				})
			}
		})
		//IF here and no errors, complete
	}
	useEffect(() => {
		if (counter === files.length) {
			setIsSuccess(true)
			setPageProps({
				...pageProps,
				loading: false,
			})
			setExpanded(true)
			setFiles([])
			getDownloadDocuments()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [counter])

	const getDownloadDocuments = useCallback(async () => {
		let applicationId = `${applicationData.applicationData?.loanNumber}`
		try {
			const data = await documentServices.getDownloadDocuments(
				applicationId as string,
				t
			)
			setDocuments(data)
		} catch (error) {
			console.warn(error)
		}
	}, [applicationData, t])

	useEffect(() => {
		getDownloadDocuments()
		// eslint-disable-next-line
	}, [applicationData])

	const getDownloadDocumentById = async (
		documentId: any,
		fileName: string
	) => {
		try {
			const { blob }: any =
				await documentServices.getDownloadDocumentById(documentId)
			downloadBlob(blob as Blob, fileName)
		} catch (error) {
			console.log(error)
			setPageProps({
				...pageProps,
				error: new Error(t('DownloadError')),
			})
		}
	}

	const next = () => {
		dispatchMsp({
			type: MSP_ACTIONS.SET_INDEX,
			payload: {
				index: msp.index + 1,
			},
		})
	}

	return (
		<div className="upload-documents-dropzone">
			<div className="upload-documents-dropzone-items">
				{documentTypes.map((item, index) => {
					return (
						<div
							className={`dropzone-item-` + index}
							key={`dropzone-item-` + index}>
							<ReadMoreExpander
								expanded={!checkIsMobile() || index === 0}
								showExpander={checkIsMobile()}
								collapsedHeight="10px"
								header={t(item.header)}
								children={
									<>
										<hr />
										<DropZone
											files={files}
											setFiles={setFiles}
											accept={item.typeOfDocuments}
											text={item.text}
											documentType={item.documentTypes}
											maxFiles={item.maxFiles}
											dropHeading={item.header}
											documentsUploaded={documents}
											downloadDocument={(
												documentId: any,
												fileName: any
											) =>
												getDownloadDocumentById(
													documentId,
													fileName
												)
											}
											bottomText={item.bottomText}
										/>
									</>
								}
							/>
						</div>
					)
				})}
			</div>
			{pageProps.error && <ErrorBox message={pageProps.error.message} />}
			<div className="upload-documets-dropzone-buttons">
				<SmoothCollapse
					className="document-upload-success"
					expanded={expanded}
					children={
						<>
							{isSuccess && (
								<InfoBox
									infoType="success"
									message={t('UploadDocumentSuccess')}
								/>
							)}
						</>
					}
				/>
				<div>
				  <button
					type="button"
					disabled={pageProps.disabled}
					className="btn btn-primary document-upload-button"
					onClick={() => onSubmitDocuments()}>
					{pageProps.loading ? (
						<div className="document-upload-spinner-container">
							<ButtonSpinner />
						</div>
					) : (
						t('ConfirmUpoadedDocuments')
					)}
				   </button>
				</div>				
			</div>
			<div
				className={`upload-documents-next-button ${
					disableNextButton ? 'inactive' : 'active'
				}`}>
				<button
					type="button"
					className="btn btn-primary"
					disabled={!pageProps.disabled || disableNextButton}
					onClick={() => next()}>
					{t('Next')}
				</button>
			</div>
		</div>
	)
}
