import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import './TrafficLights.scss'

interface ITrafficProps {
	rdi: number | undefined
	className?: string
}

enum TrafficLightsColor {
	Red,
	Yellow,
	Green,
	None,
}

interface ITrafficLights {
	type: TrafficLightsColor
	color: string
	text: string
}

const trafficLights: ITrafficLights[] = [
	{
		type: TrafficLightsColor.Red,
		color: 'red',
		text: 'TrafficLightsRedText',
	},
	{
		type: TrafficLightsColor.Yellow,
		color: 'yellow',
		text: 'TrafficLightsYellowText',
	},
	{
		type: TrafficLightsColor.Green,
		color: 'green',
		text: 'TrafficLightsGreenText',
	},
]

const TrafficLights = (props: ITrafficProps) => {
	const { t } = useTranslation()
	const [activeItem, setActiveItem] = useState<ITrafficLights>({
		type: TrafficLightsColor.None,
		color: '',
		text: 'TrafficLightsUndefinedText',
	})

	const updatecolor = (rdi: number | undefined) => {
		if (rdi !== undefined) {
			if (rdi < -1000) {
				setActiveItem(trafficLights[TrafficLightsColor.Red])
			} else if (rdi >= -1000 && rdi <= 1000) {
				setActiveItem(trafficLights[TrafficLightsColor.Yellow])
			} else {
				setActiveItem(trafficLights[TrafficLightsColor.Green])
			}
			return
		} else {
			setActiveItem(activeItem)
		}
	}

	useEffect(
		() => {
			updatecolor(props.rdi)
		},
		[props.rdi] //eslint-disable-line
	)

	return (
		<div id="traffic-lights">
			<h6>{t('TrafficLightsHeader')}</h6>
			<div className="traffic-lights-container">
				<div className="traffic-lights">
					{trafficLights.map((light, index) => {
						if (light.color === activeItem.color) {
							return (
								<span
									className={`${light.color} active`}
									key={index}
								/>
							)
						} else {
							return (
								<span
									className={`${light.color}`}
									key={index + '-traffic-item'}
								/>
							)
						}
					})}
				</div>
				<div className="traffic-lights-information">
					<p>
						<Trans t={t} i18nKey={activeItem.text}>
							<strong>Color: </strong> The risk of you not
							complying with your obligations under the agreement
							is considered to be xxx, at the same time that you
							are assumed to be able to handle a xxx in the
							interest rate.
						</Trans>
					</p>
				</div>
			</div>
		</div>
	)
}

export default TrafficLights
