import React, { useEffect } from 'react'
import { Container, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { userManager } from 'utils/authHelpers'
import { FooterLoggedOut } from 'components/footer-blocks/footer-logged-out/FooterLoggedOut'
import { appRoutes } from 'constants/routes/appRoutes'
import { setFullMobileViewHeight } from 'utils/commonhelpers'

import tfbank21_logo_white from 'img/tf-logos/tfbank21-logo-white.svg'
import './LogoutCallbackPage.scss'

function LogoutCallbackPage() {
	setFullMobileViewHeight()

	const { t } = useTranslation()
	const logo = tfbank21_logo_white
	const navigate = useNavigate()

	useEffect(() => {
		userManager.signoutRedirectCallback().then(() => {
			sessionStorage.clear()
		})
		userManager.clearStaleState()
	}, [])

	return (
		<div id="logout-callback-page">
			<div className="background-img">
				<div className="logout-container">
					<Image
						src={logo}
						className="tfbank-logo logout"
						alt="TF Bank AB"
					/>
					<Container className="logout-body">
						<h2>{t('LoggedOut')}</h2>
						<button
							className="btn btn-outline-lightgray"
							onClick={() => navigate(appRoutes.login)}>
							{t('Login')} <FontAwesomeIcon icon="sign-in-alt" />
						</button>
					</Container>
					<FooterLoggedOut />
				</div>
			</div>
		</div>
	)
}

export default LogoutCallbackPage
