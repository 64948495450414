import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import creditDecisionServices from 'services/creditDecisionServices'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import getApplicationServices from 'services/getApplicationServices'
import documentServices from 'services/documentServices'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import Psd2iFrame from 'components/afterbanks/Psd2iFrame'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

import './ApplicationBlocks.scss'
import { ProductType } from 'constants/enums/productTypes'
import { cdaApiRoutes } from 'constants/routes/cdaApiRoutes'

const FinancialData = () => {
	const { t } = useTranslation()
	const [error, setError] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)
	const applicationId = loanApplication.applicationData?.loanNumber
	const [psd2StatusError, setPsd2StatusError] = useState<boolean>(false)
	const cdaApiRoute =
		loanApplication.applicationData?.product === ProductType.EspCashLoan
			? cdaApiRoutes.runmulticashcreditdecision
			: cdaApiRoutes.runcreditcardcreditdecision

	const Loading = (loading: boolean) => {
		dispatchLoanApplication({
			type: LOAN_APPLICATION_ACTIONS.SET_ERROR_AND_LOADING,
			payload: {
				...loanApplication,
				loading: loading,
			},
		})
	}

	const fetchApplicationAndDocumentData = async (applicationId: number) => {
		setLoading(true)
		try {
			await getApplicationServices
				.GetApplicationInformation(applicationId, t)
				.then((response) => {
					loanApplication.setApplicationData(response)
				})
			await documentServices
				.getDownloadDocuments(applicationId.toString(), t)
				.then((response) => {
					loanApplication.setDocumentData(response)
				})
		} catch (error) {
			console.warn(error)
			setError(true)
		} finally {
			setLoading(false)
		}
	}

	const runCreditDecision = async () => {
		Loading(true)
		try {
			let response = await creditDecisionServices.runcreditdecision(
				applicationId!.toString(),
				cdaApiRoute
			)
			if (response?.status === 200) {
				dispatchLoanApplication({
					type: LOAN_APPLICATION_ACTIONS.SET_LOANOFFERDATA,
					payload: {
						...loanApplication,
						loanOfferData: response.data,
					},
				})
				await fetchApplicationAndDocumentData(applicationId!)
				dispatchMsp({
					type: MSP_ACTIONS.SET_INDEX,
					payload: {
						...msp,
						index: 0,
					},
				})
			}
		} catch (error) {
			console.warn(error)
			setError(true)
			Loading(false)
		}
	}

	const submit = () => {
		setPsd2StatusError(false)
		const psd2Status = sessionStorage.getItem('psd2Status')
		if (psd2Status === '200') {
			runCreditDecision()
		} else {
			setPsd2StatusError(true)
		}
	}

	return (
		<div className="financial-data">
			{loanApplication.loading || loading ? (
				<div className="col-spinner">
					<CircleSpinner />
				</div>
			) : (
				<>
					<div className="psd2-section">
						<p className="psd2-description">
							{loanApplication.applicationData?.product === ProductType.EspCashLoan
								? <Trans t={t} i18nKey="Psd2Description"></Trans>
								: <Trans t={t} i18nKey="Psd2DescriptionCC"></Trans>
							}
						</p>
						<Psd2iFrame showPsd2={true} />
					</div>
					{psd2StatusError ? (
						<ErrorBox message={t('Psd2MissingError')} />
					) : null}
					{/*The afterbanks-error-container is not displayed in ui unless customer gets error from Afterbanks */}
					<div id="afterbanks-error-container">
						<ErrorBox
							message={
								<Trans t={t} i18nKey="Psd2AfterbanksError">
									Something went wrong trying to connect to
									Afterbanks, please contact customer services
									on
									<a
										className="link-darkgray"
										href="tel:+34919931015">
										91 993 10 15
									</a>
									or email
									<a
										className="link-darkgray"
										href="mailto:hola@tfbank.es">
										hola@tfbank.es
									</a>
									with your application id:
									<strong>
										{{ applicationId: applicationId }}
									</strong>
									, and we will help you.
								</Trans>
							}
						/>
					</div>
					<div className="submit-application-button-section">
						<button
							type="button"
							id="submit-application-button"
							className="btn btn-primary"
							onClick={() => submit()}>
							{t('Submit')}
						</button>
					</div>
					{error ? <ErrorBox message={t('ErrorMsg')} /> : null}
				</>
			)}
		</div>
	)
}

export default FinancialData
