import { useEffect, useState } from 'react'

export const useExternalScript = (resourceUrl: any, name: any) => {
	const [library, setLib] = useState({})
	const [isLoaded, setIsLoaded] = useState(false)

	useEffect(() => {
		async function load() {
			const script = document.createElement('script') as HTMLScriptElement

			script.src = resourceUrl
			script.async = true

			script.onload = () => {
				setLib({ [name]: (window as { [key: string]: any })[name] })
				setIsLoaded(true)
			}

			await document.body.appendChild(script)

			return () => {
				document.body.removeChild(script)
			}
		}
		load()
	}, [resourceUrl, name])

	return { library, isLoaded }
}

export const useInlineScript = (script: string) => {
	const [isLoaded, setIsLoaded] = useState(false)

	useEffect(() => {
		async function load() {
			const inlineScript = document.createElement(
				'script'
			) as HTMLScriptElement
			inlineScript.innerHTML = script

			inlineScript.onload = () => {
				setIsLoaded(true)
			}

			document.body.append(inlineScript)

			return () => {
				inlineScript.remove()
			}
		}
		load()
	}, [script])

	return { isLoaded }
}
