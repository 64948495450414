import React from 'react'

import email_active from 'img/sign-pn-icons/email-active.svg'
import email_inactive from 'img/sign-pn-icons/email-inactive.svg'
import sms_active from 'img/sign-pn-icons/sms-active.svg'
import sms_inactive from 'img/sign-pn-icons/sms-inactive.svg'

import { SignPnContract } from 'components/application-blocks/application-offer-blocks/SignPn/SignPnEs/SignPnBlocks/SignPnContract/SignPnContract'
import { SignPnOtpSignMethodSelect } from 'components/application-blocks/application-offer-blocks/SignPn/SignPnEs/SignPnBlocks/SignPnOtpSignMethodSelect'
import { SignPnOtpValidate } from 'components/application-blocks/application-offer-blocks/SignPn/SignPnEs/SignPnBlocks/SignPnOtpValidate'
import { SignPnSuccessMessage } from 'components/application-blocks/application-offer-blocks/SignPn/SignPnEs/SignPnBlocks/SignPnSuccessMessage'

import { ISignPnOtpButtons, ISignPnStep } from 'interfaces/i-sign-pn'
import {
	OtpDistributionChannel,
	SignPnStepName,
} from 'constants/enums/signPnTypes'

export const SignPnSteps: ISignPnStep[] = [
	{
		step: 1,
		name: SignPnStepName.DownloadContract,
		content: <SignPnContract />,
		// show: true,
	},
	{
		step: 2,
		name: SignPnStepName.MethodSelect,
		content: <SignPnOtpSignMethodSelect />,
		// show: false,
	},
	{
		step: 3,
		name: SignPnStepName.OtpValidate,
		content: <SignPnOtpValidate />,
		// show: false,
	},
	{
		step: 4,
		name: SignPnStepName.Success,
		content: <SignPnSuccessMessage />,
		// show: false,
	},
]

export const otpSignMethods: ISignPnOtpButtons[] = [
	{
		otpDistributionChannel: OtpDistributionChannel.SMS,
		iconActive: sms_active,
		iconInactive: sms_inactive,
		title: 'OtpMethodSms',
		className: 'sms-otp',
		show: true,
	},
	{
		otpDistributionChannel: OtpDistributionChannel.Email,
		iconActive: email_active,
		iconInactive: email_inactive,
		title: 'OtpMethodEmail',
		className: 'email-otp',
		show: true,
	},
]
