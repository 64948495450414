import { useCallback, useEffect, useState } from 'react'
import queryString from 'query-string'
import { TFunction } from 'i18next'

import appConfig from 'app.config'
import { AxiosError } from 'axios'
import { SigningUrlType, UrlStatus } from 'constants/enums/signingUrlTypes'
import { IDocuments } from 'interfaces/i-documents'
import { IApplicationUrlsDto } from 'interfaces/i-signing-urls'
import { IApplicationInformation } from 'interfaces/i-application-information'
import getApplicationServices from 'services/getApplicationServices'
import documentServices from 'services/documentServices'
import { getErrorWithFullMessage } from 'utils/authHelpers'
import { IgnoreLifeCycleStatus } from 'constants/enums/lifeCycleStatus'

interface UseApplicationDataProps {
	applicationId: string | null
	t: TFunction
}

const getSigningUrlsTypes = (data: IApplicationInformation) => {
	const signingUrlTypes: SigningUrlType[] = []
	const queryValues = queryString.parse(window.location.search.toLowerCase())
	//If any status are included in IgnoreLifeCycleStatus, skip fetching signingUrls.
	if (
		Object.values(IgnoreLifeCycleStatus).includes(
			data?.lifeCycleStatus! as unknown as IgnoreLifeCycleStatus
		)
	) {
		return signingUrlTypes
	}
	if (appConfig.country === 'es') {
		return signingUrlTypes
	} // Temporary solution just so we won't call the getSigningUrls for Spain.
	if (
		UrlStatus.Signed !== data.signingUrls.pnSigning.status &&
		!data.isCoApplicant
	) {
		signingUrlTypes.push(SigningUrlType.ApplicantSigning)
	}
	if (
		data.signingUrls?.psD2?.status! !== UrlStatus.Signed &&
		!data.isCoApplicant
	) {
		if (
			(appConfig.country === 'fi' || appConfig.country === 'dk') &&
			queryValues.psd2status === 'success'
		) {
			return signingUrlTypes
		} else signingUrlTypes.push(SigningUrlType.ApplicantPsd2)
	}
	if (
		UrlStatus.Signed !== data.signingUrls.pnSigning.status &&
		data.isCoApplicant
	) {
		signingUrlTypes.push(SigningUrlType.CoapplicantSigning)
	}
	if (
		data.signingUrls?.psD2?.status !== UrlStatus.Signed &&
		data.isCoApplicant
	) {
		if (
			(appConfig.country === 'fi' || appConfig.country === 'dk') &&
			queryValues.psd2status === 'success'
		) {
			return signingUrlTypes
		} else signingUrlTypes.push(SigningUrlType.CoapplicantPsd2)
	}
	if (
		UrlStatus.Signed === data.signingUrls.pnSigning.status ||
		queryValues.status === 'success'
	) {
		signingUrlTypes.push(SigningUrlType.PpiContract)
	}

	return signingUrlTypes
}

export const useApplicationData = ({
	applicationId,
	t,
}: UseApplicationDataProps) => {
	const [applicationData, setApplicationData] =
		useState<IApplicationInformation>()
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState<Error>()
	const [signingUrls, setSigningUrls] = useState<IApplicationUrlsDto>()
	const [documentData, setDocumentData] = useState<IDocuments[]>()

	const getApplicationAndSigningUrls = useCallback(async () => {
		setLoading(true)
		try {
			const data: IApplicationInformation | undefined =
				await getApplicationServices.GetApplicationInformation(
					parseInt(applicationId!),
					t
				)

			if (data !== undefined) {
				setApplicationData(data)
				console.log(data)

				const signUrlTypes = getSigningUrlsTypes(data)
				const signingUrls = {} as IApplicationUrlsDto
				signUrlTypes.forEach(async (signingUrlType) => {
					const data: IApplicationUrlsDto =
						await getApplicationServices.GetSigningUrls(
							+applicationId!,
							signingUrlType,
							t
						)
					switch (signingUrlType) {
						case SigningUrlType.ApplicantSigning:
							signingUrls.applicantSigning = data.applicantSigning
							break
						case SigningUrlType.ApplicantPsd2:
							signingUrls.applicantPsd2 = data.applicantPsd2
							break
						case SigningUrlType.CoapplicantSigning:
							signingUrls.coapplicantSigning =
								data.coapplicantSigning
							break
						case SigningUrlType.CoapplicantPsd2:
							signingUrls.coapplicantPsd2 = data.coapplicantPsd2
							break
						case SigningUrlType.OnlineIdentification:
							signingUrls.onlineIdentification =
								data.onlineIdentification
							break
						case SigningUrlType.PpiContract:
							signingUrls.ppiContract = data.ppiContract
							break
					}
				})
				setSigningUrls(signingUrls)
				const documents: IDocuments[] =
					await documentServices.getDownloadDocuments(
						applicationId as string,
						t
					)
				setDocumentData(documents)
			} else {
				setError(
					new Error(
						'Application information has returned as undefined.'
					)
				)
			}
		} catch (e) {
			const error = e as Error | AxiosError
			setError(getErrorWithFullMessage(error))
		}
	}, [applicationId, t])

	useEffect(() => {
		getApplicationAndSigningUrls().finally(() => setLoading(false))
	}, []) //  eslint-disable-line react-hooks/exhaustive-deps

	return {
		applicationData,
		loading,
		error,
		signingUrls,
		documentData,
		setError,
		setLoading,
		setApplicationData,
		setDocumentData,
		setSigningUrls,
	}
}
