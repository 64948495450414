import React from 'react'

import './ValidationErrorBox.scss'

interface IValidationError {
	message: string
	css?: string
}
export const ValidationError = ({ css, message }: IValidationError) => (
	<div className={`validation-error ${css}`}>{message}</div>
)
