import React from 'react'

import './SuccessBox.scss'

interface ISuccessBox {
	css: string
	message: string | object
}

/**
 * Used to present success message and checkmark when application is finished
 * @param message What success message should be
 * @param css classname that applies specific styling to box that is specific for market
 * @returns
 */

const SuccessBox = ({ message, css }: ISuccessBox) => {
	return (
		<div className={'success-box ' + css}>
			<div className="success-img-box circle">
				<div className="checkmark draw"></div>
			</div>
			{message}
		</div>
	)
}

export default SuccessBox
