export const idleTimerHelper = {
	onAction: (setTimer: any) => {
		setTimer((prev: any) => ({
			...prev,
			isTimedOut: false,
		}))
	},
	onActive: (setTimer: any) => {
		console.log('user is active')
		setTimer((prev: any) => ({
			...prev,
			isTimedOut: false,
		}))
	},
	onIdle: (setTimer: any, timer: any) => {
		if (!timer.isTimedOut) {
			setTimer((prev: any) => ({
				...prev,
				showModal: true,
				isTimedOut: true,
			}))
		} else {
			//never reach this code with current settings
			setTimer((prev: any) => ({
				...prev,
				showModal: false,
			}))
		}
	},
	handleClose: (idleTimer: any, setTimer: any) => {
		setTimer((prev: any) => ({
			...prev,
			showModal: false,
			isTimedOut: false,
		}))
		console.log('..user closed modal')
		idleTimer.current.reset() //reset idle timer to initial settings
	},
	handleLogout: (navigate: any, setTimer: any) => {
		setTimer((prev: any) => ({
			...prev,
			showModal: false,
		}))
		navigate()
		console.log('..user is logged out')
	},
}
