import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import './PreContractualInfo.scss'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'

const PreContractualInfo = () => {
	const { t } = useTranslation()
	const { msp, dispatchMsp } = useContext(MspDispatchContext)
	const [confirmedTerms, setConfirmedTerms] = useState<boolean>(false)

	const next = () => {
		dispatchMsp({
			type: MSP_ACTIONS.SET_INDEX,
			payload: {
				index: msp.index + 1,
			},
		})
	}

	return (
		<div className="pre-contractual-info">
			<div className="pre-contractual-header">
				<h2>
					<Trans t={t} i18nKey={'PreContractualHeader'}></Trans>
				</h2>
			</div>
			<div className="pre-contractual-text">
				<Trans t={t} i18nKey={'PreContractualText'}></Trans>
			</div>
			<div className="pre-contractual-link">
				<a
					href="https://tfbank.es/media/sufnipfd/ine-tarjeta-de-cr%C3%A9dito-tf-bank.pdf"
					target="_blank"
					rel="noopener noreferrer"
					download>
					{t('PreContractualLink')}
				</a>		
			</div>
			<br />
			<div className="pre-contractual-terms">
				<label className="checkbox-container">
					<input
						type="checkbox"
						checked={confirmedTerms}
						onChange={() => setConfirmedTerms(!confirmedTerms)}
					/>
					<span className="checkmark"></span>
					<div>{t('PreContractualTerms')}</div>
				</label>
			</div>
			<div className="psd2-button-section">
				<button
					type="button"
					className="btn btn-primary"
					disabled={!confirmedTerms}
					onClick={() => next()}>
					{t('ProceedToPSD2')}
				</button>
			</div>
		</div>
	)
}

export default PreContractualInfo
