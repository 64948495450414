export enum ProductSettingTypes {
	Card = 'Card',
	Deposit = 'Deposit',
	Loan = 'Loan',
	All = 'All',
}

export enum ProductType {
	FinHalens = 'FinHalens',
	FinCredento = 'FinCredento',
	SweHalens = 'SweHalens',
	SweCredento = 'SweCredento',
	PolCredento = 'PolCredento',
	EstTfbank = 'EstTfbank',
	NorCredento = 'NorCredento',
	PolDirectSales = 'PolDirectSales',
	EstCreditLine = 'EstCreditLine',
	EstCreditLineInvoice = 'EstCreditLineInvoice',
	EstDirectSales = 'EstDirectSales',
	LvaCashLoan = 'LvaCashLoan',
	EstCarLoan = 'EstCarLoan',
	LvaCarLoan = 'LvaCarLoan',
	LtuCashLoan = 'LtuCashLoan',
	AutCashLoan = 'AutCashLoan',
	SweCashLoan = 'SweCashLoan',
	LtuDirectSales = 'LtuDirectSales',
	LtuCarLoan = 'LtuCarLoan',
	LtuRenovation = 'LtuRenovation',
	DeuCreditCard = 'DeuCreditCard',
	EstRefin = 'EstRefin',
	LvaRefin = 'LvaRefin',
	LtuRefin = 'LtuRefin',
	FinRefin = 'FinRefin',
	EstMortgage = 'EstMortgage',
	LvaDirectSales = 'LvaDirectSales',
	LtuCreditLine = 'LtuCreditLine',
	LvaCreditLine = 'LvaCreditLine',
	LtuCreditLineTf = 'LtuCreditLineTf',
	LvaCreditLineTf = 'LvaCreditLineTf',
	EstCreditLineTf = 'EstCreditLineTf',
	DnkCashLoan = 'DnkCashLoan',
	EspCashLoan = 'EspCashLoan',
	NorRefin = 'NorRefin',
	FinCashLoan = 'FinCashLoan',
	NorCreditCardGold = 'NorCreditCardGold',
	NorCreditCard = 'NorCreditCard',
	FinHalensLoan = 'FinHalensLoan',
	FinRefinLoan = 'FinRefinLoan',
	FinRefinPpi = 'FinRefinPpi',
	FinHalensLoanPpi = 'FinHalensLoanPpi',
	FinCashLoanPpi = 'FinCashLoanPpi',
	FinRefinLoanPpi = 'FinRefinLoanPpi',
	EspCreditCard = 'EspCreditCard'
}
