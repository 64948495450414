import React, { useContext, useEffect } from 'react'
import { Route, Routes } from 'react-router'
import IdleTimer from 'react-idle-timer'
import { Navigate, useNavigate } from 'react-router-dom'

import { appRoutes } from 'constants/routes/appRoutes'
import AuthContext from 'contexts/AuthContext'
import ApplicationPage from 'pages/application-page/ApplicationPage'
import CallBackPage from 'pages/callback-page/CallBackPage'
import LoginPage from 'pages/login-page/LoginPage'
import LogoutCallbackPage from 'pages/logout-callback-page/LogoutCallbackPage'
import LogoutPage from 'pages/logout-page/LogoutPage'
import NotFoundPage from 'pages/not-found-page/NotFoundPage'
import { ITimer } from 'interfaces/i-timer'
import { idleTimerHelper } from 'utils/idleTimerHelper'
import PrivateRoute from './PrivateRoute'
import { IdleTimeOutModal } from 'components/modals/idle-time-out-modal/IdleTimeOutModal'
import { MaintenanceContextProvider } from 'contexts/MaintenanceContext'
import SignicatCallbackPage from 'pages/signicat-callback.page/SignicatCallbackPage'
import NorwegianBankIdCallbackPage from 'pages/norwegian-bank-id-callback-page/NorwegianBankIdCallbackPage'
import CardApplicationPage from 'pages/de-card-application/CardApplicationPage'
import GermanyAuthentication from 'pages/germanyAuth/GermanyAuthentication'
import SpainAuthentication from 'pages/spanish-cardapplication-login-page/SpainAuthentication'
import appConfig from 'app.config'

const RootRoute = () => {
	const { isAuthenticated } = useContext(AuthContext)
	const navigate = useNavigate()
	const [timer, setTimer] = React.useState<ITimer>({
		timeout: 60000 * 15 * 1, //15 minutes
		showModal: false,
		userLoggedIn: false,
		isTimedOut: false,
		counter: 60, //seconds
	})
	const idleTimer = React.useRef<IdleTimer>(new IdleTimer(timer))

	useEffect(() => {
		console.log('Authi', isAuthenticated)
	}, [isAuthenticated])

	return (
		<React.Fragment>
			{isAuthenticated ? (
				<IdleTimer
					ref={idleTimer as any}
					onActive={() => idleTimerHelper.onActive}
					onAction={() => idleTimerHelper.onAction}
					onIdle={() => idleTimerHelper.onIdle(setTimer, timer)}
					element={document}
					debounce={250}
					timeout={timer.timeout}
					stopOnIdle={true}
				/>
			) : null}
			<Routes>
				<Route
					path={appRoutes.login}
					element={
						isAuthenticated ? (
							<Navigate to={appRoutes.startpage} />
						) : (
							<LoginPage />
						)
					}
				/>
				<Route path={appRoutes.logout} element={<LogoutPage />} />
				<Route
					path={appRoutes.logoutCallback}
					element={<LogoutCallbackPage />}
				/>
				<Route
					path={appRoutes.callbackPage}
					element={<CallBackPage />}
				/>
				<Route
					path={appRoutes.norwegianBankIdCallback}
					element={<NorwegianBankIdCallbackPage />}
				/>
				<Route
					path={appRoutes.signicatCallback}
					element={<SignicatCallbackPage />}
				/>
				<Route
					path={appRoutes.cardApplicationLogin}
					element={appConfig.country === 'de' ? <GermanyAuthentication /> : <SpainAuthentication />}
				/>
				<Route
					path={appRoutes.applicationPage}
					element={
						<PrivateRoute
							redirect={appRoutes.login}
							path={appRoutes.applicationPage}
							component={ApplicationPage}
						/>
					}
				/>
				<Route
					path={appRoutes.cardApplicationPage}
					element={
						<PrivateRoute
							redirect={appRoutes.cardApplicationLogin}
							path={appRoutes.cardApplicationPage}
							component={CardApplicationPage}
						/>
					}
				/>
				<Route
					path={appRoutes.cardApplicationLogin}
					element={
						isAuthenticated ? (
							<Navigate to={appRoutes.cardApplicationPage} />
						) : (
							<GermanyAuthentication />
						)
					}
				/>
				{/*Important: A route with path="/" will *always* match the URL because all URLs begin with a /. So that's why we put this one last of all */}
				<Route
					path={appRoutes.startpage}
					element={
						isAuthenticated ? (
							<Navigate replace to={appRoutes.applicationPage} />
						) : (
							<LoginPage />
						)
					}
				/>
				{/* If no match rout to 404 page */}
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
			<IdleTimeOutModal
				showModal={timer.showModal}
				handleClose={() =>
					idleTimerHelper.handleClose(idleTimer, setTimer)
				}
				handleLogout={() =>
					idleTimerHelper.handleLogout(
						navigate(appRoutes.logout),
						setTimer
					)
				}
				remainingTime={timer.counter}
			/>
			{isAuthenticated ? (
				<div className="authMaintenance">
					<MaintenanceContextProvider />
				</div>
			) : (
				<div className="nonAuthMaintenance">
					<MaintenanceContextProvider />
				</div>
			)}
		</React.Fragment>
	)
}

export default RootRoute
