import React, { useState } from 'react'
import { Container, Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import authenticationService from 'services/authServices'
import { getErrorWithFullMessage } from 'utils/authHelpers'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import { appRoutes } from 'constants/routes/appRoutes'
import { AxiosError } from 'axios'

import './NorwegianBankIdCallbackPage.scss'

function NorwegianBankIdCallbackPage() {
	const [error, setError] = useState<Error>()
	const [isWaiting, setIsWaiting] = useState(false)
	const { t } = useTranslation()
	React.useEffect(
		() => {
			signin()
		},
		// eslint-disable-next-line
		[]
	)
	async function signin() {
		const search = window.location.search
		const params = new URLSearchParams(search)
		setIsWaiting(true)
		try {
			if (window.location.search.indexOf('error_description') !== -1) {
				const errorMsg = params.get('error_description')
				setError(new Error(errorMsg ? errorMsg : ''))
			} else if (window.location.search.indexOf('code') > -1) {
				let authResult =
					await authenticationService.norwegianBankIdApplicationResultGet(
						search,
						t
					)
				authenticationService.redirectTo(
					window.location.origin + appRoutes.callbackPage,
					authResult.secret
				)
			}
		} catch (error) {
			setError(getErrorWithFullMessage(error as Error | AxiosError))
		}
	}
	return (
		<Container id="norwegian-bankid-callback-page">
			{error ? (
				<Alert
					variant="danger"
					dismissible
					onClose={() => (window.location.href = appRoutes.login)}>
					{error.message}
				</Alert>
			) : isWaiting ? (
				<CircleSpinner />
			) : null}
		</Container>
	)
}
export default NorwegianBankIdCallbackPage
