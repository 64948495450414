import React, {
	Context,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { OtpDistributionChannel } from 'constants/enums/signPnTypes'
import { ISignPnStep } from 'interfaces/i-sign-pn'
import { IDocuments } from 'interfaces/i-documents'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { SignPnSteps } from 'constants/values/signPnValues'
import { DocumentTypes } from 'constants/enums/documentTypes'
import { ScrollToTop } from 'utils/commonhelpers'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

import '../SignPn.scss'

interface ISignPnContext {
	loading: boolean | undefined
	setLoading: Function
	error: boolean | undefined
	setError: Function
	signPnStep: ISignPnStep | undefined
	setSignPnStep: Function
	otpDistributionChannel: OtpDistributionChannel | undefined
	setOtpDistributionChannel: Function
	otpDistributionRetry: boolean
	setOtpDistributionRetry: Function
	nextStep: Function
	previousStep: Function
	pnDocument: IDocuments | undefined
	docScale: number
	setScale: Function
}

export const SignPnContext: Context<ISignPnContext> =
	createContext<ISignPnContext>({
		loading: false,
		setLoading: () => {},
		error: false,
		setError: () => {},
		signPnStep: undefined,
		setSignPnStep: () => {},
		otpDistributionChannel: undefined,
		setOtpDistributionChannel: () => {},
		otpDistributionRetry: false,
		setOtpDistributionRetry: () => {},
		nextStep: () => {},
		previousStep: () => {},
		pnDocument: undefined,
		docScale: 1.0,
		setScale: () => {},
	})

export const SignPnEs = () => {
	const { t } = useTranslation()
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)
	const [error, setError] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [signPnStep, setSignPnStep] = useState<ISignPnStep>(SignPnSteps[0])
	const [otpDistributionChannel, setOtpDistributionChannel] =
		useState<OtpDistributionChannel>(OtpDistributionChannel.Unknown)
	const [otpDistributionRetry, setOtpDistributionRetry] =
		useState<boolean>(false)
	const [pnDocument, setPnDocument] = useState<IDocuments>()
	const [docScale, setScale] = useState<number>(1.0)

	function GetContent() {
		if (signPnStep.content !== undefined) {
			return signPnStep.content
		}
	}

	const getDocuments = useCallback(() => {
		let documents: IDocuments[] = loanApplication.documentData!
		documents.map((document) => {
			if (document.type === DocumentTypes.PromissoryNote) {
				return setPnDocument(document)
			}
			return pnDocument
		})
	}, [loanApplication.documentData, pnDocument])

	useEffect(() => {
		GetContent()
		getDocuments()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signPnStep])

	function nextStep() {
		let currentStep = signPnStep.step
		SignPnSteps.map((step) => {
			if (step.step === currentStep + 1) {
				return setSignPnStep(step)
			}
			return signPnStep
		})
		ScrollToTop({ top: 300, left: 0 })
	}

	function previousStep() {
		let currentStep = signPnStep.step
		SignPnSteps.map((step) => {
			if (step.step === currentStep - 1) {
				return setSignPnStep(step)
			}
			return signPnStep
		})
		ScrollToTop({ top: 300, left: 0 })
	}

	return (
		<SignPnContext.Provider
			value={{
				loading,
				setLoading,
				error,
				setError,
				signPnStep,
				setSignPnStep,
				otpDistributionChannel,
				setOtpDistributionChannel,
				otpDistributionRetry,
				setOtpDistributionRetry,
				nextStep,
				previousStep,
				pnDocument,
				docScale,
				setScale,
			}}>
			<div className="sign-pn">
				{loading ? (
					<CircleSpinner />
				) : (
					<>
						{error ? (
							<div className="error-box-container">
								<ErrorBox message={t('ErrorMsg')} />
							</div>
						) : (
							GetContent()
						)}
					</>
				)}
			</div>
		</SignPnContext.Provider>
	)
}
