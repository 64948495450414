import { TFunction } from 'i18next'
import appConfig from 'app.config'
import { IApplicationInformation } from 'interfaces/i-application-information'
import { stringifyNum } from 'utils/commonhelpers'
import { getCurrency, getLanguageLocale } from 'utils/countryHelpers'
import { showBaseAndMarginRatesFinland } from 'utils/loanHelpers'

export function getLoanHeaderValues(
	t: TFunction,
	application?: IApplicationInformation
) {
	let applicationArray
	switch (appConfig.country) {
		case 'dk':
			applicationArray = [
				{
					text: 'LoanTerm',
					value: `${application?.approvedRepaymentPeriod} ${t(
						'Months'
					)}`,
				},
				{
					text: 'MonthlyFee',
					value: stringifyNum(application?.monthlyFee!, {
						currency: getCurrency(),
						style: 'currency',
					}),
				},
				{
					text: 'OpeningFee',
					value: stringifyNum(application?.openingFee!, {
						currency: getCurrency(),
						style: 'currency',
					}),
				},
				{
					text: 'InterestFee',
					value: stringifyNum(application?.interestRate, {
						style: 'percent',
					}),
				},
				{
					text: 'APR',
					value: stringifyNum(application?.apr, {
						style: 'percent',
					}),
				},
				{
					text: 'TotalAmountRepaid',
					value: stringifyNum(application?.totalAmountToBeRepaid!, {
						currency: getCurrency(),
						style: 'currency',
					}),
				},
			]
			break
		case 'fi':
			applicationArray = [
				{
					text: 'LoanTerm',
					value: `${application?.approvedRepaymentPeriod} ${t(
						'Months'
					)}`,
				},
				{
					text: 'MonthlyFee',
					value: stringifyNum(application?.monthlyFee!, {
						currency: getCurrency(),
						style: 'currency',
					}),
				},
				{
					text: 'OpeningFee',
					value: stringifyNum(application?.openingFee!, {
						currency: getCurrency(),
						style: 'currency',
					}),
				},
				{
					text: 'InterestFee',
					value: showBaseAndMarginRatesFinland(application)
						? stringifyNum(application?.totalInterestRate, {
								style: 'percent',
						  })
						: stringifyNum(application?.interestRate, {
								style: 'percent',
						  }),
					tooltip: showBaseAndMarginRatesFinland(application)
						? {
								text: 'BaseInterestTooltip',
								value: `(${stringifyNum(
									application?.interestRate,
									{
										style: 'percent',
									}
								)})`,
								secondValue: `(${stringifyNum(
									application?.baseInterestRate,
									{
										style: 'percent',
									}
								)})`,
						  }
						: {
								text: 'EuriborTooltip',
								value: `(${stringifyNum(application?.euribor, {
									style: 'percent',
								})})`,
						  },
				},
				{
					text: 'APR',
					value: stringifyNum(application?.apr, {
						style: 'percent',
					}),
				},
				{
					text: 'TotalAmountRepaid',
					value: stringifyNum(application?.totalAmountToBeRepaid!, {
						currency: getCurrency(),
						style: 'currency',
					}),
				},
			]
			break
		case 'no':
			applicationArray = [
				{
					text: 'ApplicationRegistered',
					value: new Date(
						application?.dateCreated!
					).toLocaleDateString(getLanguageLocale()),
				},
				{
					text: 'Loannumber',
					value: stringifyNum(application?.loanNumber!),
				},
				{
					text: 'LoanTerm',
					value: `${application?.approvedRepaymentPeriod} ${t(
						'Months'
					)}`,
				},
				{
					text: 'OpeningFee',
					value: stringifyNum(application?.openingFee!, {
						currency: getCurrency(),
						style: 'currency',
					}),
				},
				{
					text: 'InterestFee',
					value: stringifyNum(application?.interestRate, {
						style: 'percent',
					}),
				},
				{
					text: 'MonthlyFee',
					value: stringifyNum(application?.monthlyFee!, {
						currency: getCurrency(),
						style: 'currency',
					}),
				},
				{
					text: 'APR',
					value: stringifyNum(application?.apr, {
						style: 'percent',
					}),
				},
				{
					text: 'TotalAmountRepaid',
					value: stringifyNum(application?.totalAmountToBeRepaid!, {
						currency: getCurrency(),
						style: 'currency',
					}),
				},
			]
			break
		default:
			applicationArray = [
				{
					text: 'LoanTerm',
					value: `${application?.approvedRepaymentPeriod} ${t(
						'Months'
					)}`,
				},
				{
					text: 'MonthlyFee',
					value: stringifyNum(application?.monthlyFee!, {
						currency: getCurrency(),
						style: 'currency',
					}),
				},
				{
					text: 'OpeningFee',
					value: stringifyNum(application?.openingFee!, {
						currency: getCurrency(),
						style: 'currency',
					}),
				},
				{
					text: 'InterestFee',
					value: stringifyNum(application?.interestRate, {
						style: 'percent',
					}),
				},
				{
					text: 'APR',
					value: stringifyNum(application?.apr, {
						style: 'percent',
					}),
				},
				{
					text: 'TotalAmountRepaid',
					value: stringifyNum(application?.totalAmountToBeRepaid!, {
						currency: getCurrency(),
						style: 'currency',
					}),
				},
			]
	}

	return applicationArray
}
