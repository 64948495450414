import Axios, { AxiosResponse } from 'axios'
import { TFunction } from 'i18next'
import appConfig from 'app.config'

import { IMobileIdAuthResponse } from 'interfaces/i-auth/i-mobileid'
import { IUserData } from 'interfaces/i-auth/i-user-data'
import { SmartIdInitiateAuthDto } from 'interfaces/i-auth/i-smart-id'
import { ISignicatIdAuthResponse } from 'interfaces/i-auth/i-signicatid'
import { IBankIdAuthResponse } from 'interfaces/i-auth/i-bankid'
import {
	getHeadersWithJwtToken,
	getNorwegianBankIdReturnUrl,
	getReturnUrl,
	getSignicatReturnUrl,
} from '../utils/authHelpers'
import { authAndSignApiRoutes } from 'constants/routes/authAndSignApiRoutes'
import { cdaApiRoutes } from 'constants/routes/cdaApiRoutes'
import AuthMethodType from 'constants/values/authMethods'
import {
	IApplicationOtpStartRequest,
	ILoanOtpStartRequest,
	IOtpCompleteRequest,
	IOtpStartResponse,
} from 'interfaces/i-auth/i-otpid'
import { setupInterceptorsFor } from 'appinsights/interceptors'

const transportWithCredentials = setupInterceptorsFor(Axios.create({}))
export const cancelSource = Axios.CancelToken.source()

const authenticationService = {
	async smartIdAuthInit(authRequest: {
		countryCode: string
		nationalIdentityNumber: string
		returnUrl: string
	}) {
		const { data } =
			await transportWithCredentials.post<SmartIdInitiateAuthDto>(
				authAndSignApiRoutes.smartIdAuthInitiate,
				authRequest
			)

		return data
	},

	async smartIdAuthComplete(
		authRequest: SmartIdInitiateAuthDto,
		retryCount: number = 0
	): Promise<{ redirectUrl: string; secret: string }> {
		try {
			console.log('retryC: ', retryCount)
			const { data } = await transportWithCredentials.post<{
				redirectUrl: string
				secret: string
			}>(authAndSignApiRoutes.smartIdAuthComplete, authRequest)

			return data
		} catch (e) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			if ((e as any).request) {
				if (retryCount < 3) {
					return await this.smartIdAuthComplete(
						authRequest,
						retryCount + 1
					)
				}
			}

			throw e
		}
	},

	async mobileIdAuthInitiate(
		phoneNumber: string,
		personalNumber: string,
		returnUrl: string
	) {
		phoneNumber = phoneNumber.replace(/\s+/g, '')
		const { data } =
			await transportWithCredentials.post<IMobileIdAuthResponse>(
				authAndSignApiRoutes.mobileIdAuthInitiate,
				{
					phoneNumber,
					returnUrl,
					personalNumber,
				}
			)

		return data
	},

	async mobileIdAuthComplete(userData: IUserData | undefined, t: TFunction) {
		const { data } = await transportWithCredentials.post<{
			redirectUrl: string
			secret: string
		}>(
			authAndSignApiRoutes.mobileIdAuthComplete,
			userData,
			await getHeadersWithJwtToken()
		)

		if (!data) {
			throw new Error(t('AuthenticationError'))
		}

		return data
	},

	async cardIdAuthenticate(
		requestId: string,
		certificateContent: string,
		t: TFunction
	) {
		const { data } = await transportWithCredentials.post(
			authAndSignApiRoutes.idCardAuth(requestId),
			{ certificateContent }
		)

		return data as string
	},

	async signicatRedirectLinkGet() {
		const route =
			authAndSignApiRoutes.signicatAuthLink +
			`?state=noState&locale=${
				appConfig.country
			}&callbackUrl=${getSignicatReturnUrl()}`

		const { data } = await transportWithCredentials.get<string>(route)
		return data
	},

	async signicatMitIdRedirectLinkGet() {
		const route =
			authAndSignApiRoutes.signicatAuthMitId +
			`?state=noState&callbackUrl=${getSignicatReturnUrl()}`

		const { data } = await transportWithCredentials.get<string>(route)
		return data
	},

	async signicatResultGet(queryParams: string, t: TFunction) {
		const data =
			await transportWithCredentials.get<ISignicatIdAuthResponse>(
				authAndSignApiRoutes.signicatAuthResult +
					`${queryParams}&callbackUrl=${getSignicatReturnUrl()}`
			)

		if (data.status === 200) {
			return data.data
		}
		throw new Error(t('AuthenticationError'))
	},

	async norwegianBankIdRedirectLinkGet(authMethod: AuthMethodType) {
		let method = undefined
		switch (authMethod) {
			case AuthMethodType.norwegianBankId:
				method = 'nbid-high'
				break
			case AuthMethodType.norwegianBankIdBiometric:
				method = 'nbid-biometric'
				break
			case AuthMethodType.norwegianBankIdMobile:
				method = 'nbid-mobil'
				break
			default:
				throw new Error('Invalid authentication method')
		}

		const route =
			authAndSignApiRoutes.norwegianBankIdAuthLink +
			`?state=noState&locale=${
				appConfig.country
			}&callbackUrl=${getNorwegianBankIdReturnUrl()}&method=${method}`
		const { data } = await transportWithCredentials.get<string>(route)
		return data
	},

	async norwegianBankIdApplicationResultGet(
		queryParams: string,
		t: TFunction
	) {
		const data =
			await transportWithCredentials.get<ISignicatIdAuthResponse>(
				authAndSignApiRoutes.norwegianBankIdApplicationAuthResult +
					`${queryParams}&callbackUrl=${getNorwegianBankIdReturnUrl()}`
			)
		if (data.status === 200) {
			return data.data
		}
		throw new Error(t('AuthenticationError'))
	},

	async bankIdAuthInitiate(ssn: string, returnUrl: string) {
		const { data } =
			await transportWithCredentials.post<IBankIdAuthResponse>(
				authAndSignApiRoutes.bankIdAuthInitiate,
				{
					returnUrl,
					ssn,
				}
			)

		return data
	},

	async bankIdAuthCancel(orderRef: string) {
		try {
			const response = await transportWithCredentials.post(
				authAndSignApiRoutes.bankIdAuthCancel(orderRef)
			)
			return response.status
		} catch (error) {
			throw error
		}
	},

	async bankIdAuthComplete(
		orderRef: string,
		ssn: string,
		redirectUrl: string,
		t: TFunction
	) {
		try {
			const { data } = await transportWithCredentials.post<{
				redirectUrl: string
				secret: string
				isCompleted: boolean
			}>(
				authAndSignApiRoutes.bankIdAuthComplete,
				{
					redirectUrl,
					orderRef,
					ssn,
				},
				{
					cancelToken: cancelSource.token,
				}
			)

			if (!data) {
				throw new Error(t('AuthenticationError'))
			}

			return data
		} catch (error) {
			if (Axios.isCancel(error)) {
				cancelSource.cancel('Request canceled by user')
			}
		}
	},

	async getArtifact(requestId: string, taskId: string, t: TFunction) {
		let route
		switch (appConfig.country) {
			case 'dk':
				route = authAndSignApiRoutes.getArtifactDk(requestId, taskId)
				break
			case 'fi':
				route = authAndSignApiRoutes.getArtifactFi(requestId, taskId)
				break
			case 'no':
				route = authAndSignApiRoutes.getArtifactBbBank(
					requestId,
					taskId
				)
				break
			default:
				route = authAndSignApiRoutes.getArtifactFi(requestId, taskId)
		}
		console.log(route)
		const artifact = await transportWithCredentials.get<string>(route)

		if (artifact.status === 200) {
			return artifact.data
		}
		throw new Error(t('NoArtifact'))
	},

	async authenticate(
		apiRoute: string,
		ssn: string,
		birthDate: string,
		applicationId: string,
		t: TFunction
	) {
		const resp = await transportWithCredentials.post<{
			secret: string
			returnUrl: string
		}>(apiRoute, {
			ssn,
			birthDate,
			applicationId,
			returnUrl: getReturnUrl(),
		})

		if (resp.status !== 200) {
			throw new Error(t('AuthenticationError'))
		}
		console.log(resp.data)
		return resp.data
	},

	async otpStart(request: IApplicationOtpStartRequest, t: TFunction) {
		const route = () => {
			switch (appConfig.country) {
				case 'es':
					return authAndSignApiRoutes.spainOtpStart
				default:
					return authAndSignApiRoutes.spainOtpStart
			}
		}

		const resp = await transportWithCredentials.post<{
			secret: string
			customerId: string
		}>(route(), request)

		return resp.data
	},

	async otpLoanStart(request: ILoanOtpStartRequest, t: TFunction) {
		const resp = await transportWithCredentials.post<
			ILoanOtpStartRequest,
			AxiosResponse<IOtpStartResponse>
		>(authAndSignApiRoutes.loanOtpStart, request)

		if (resp.status !== 200) {
			throw new Error(t('AuthenticationError'))
		}

		return resp.data
	},

	async otpComplete(request: IOtpCompleteRequest, t: TFunction) {
		const route = () => {
			switch (appConfig.country) {
				case 'es':
					return authAndSignApiRoutes.spainOtpComplete
				default:
					return authAndSignApiRoutes.spainOtpComplete
			}
		}

		const resp = await transportWithCredentials.post<{
			redirectUrl: string
			secret: string
		}>(route(), request)

		return resp.data
	},

	async electronicIDAuthorizationToken(
		applicationId: string,
		product: string
	) {
		const { data } = await transportWithCredentials.get<{
			id: string
			authorization: string
		}>(authAndSignApiRoutes.electronicIDToken(applicationId, product))
		console.log('received auth: ', data)
		return data
	},

	async electronicIDVerificationRequest(videoId: string, product: string) {
		const { data } = await transportWithCredentials.post(
			cdaApiRoutes.electronicIDVerificationRequest,
			{ videoId: videoId, product: product },
			await getHeadersWithJwtToken()
		)
		return data
	},

	redirectTo(url: string, secretValue: string) {
		const form = document.createElement('form')
		form.style.display = 'none'
		form.action = authAndSignApiRoutes.redirectTo
		form.method = 'POST'

		var secret = document.createElement('input')
		secret.name = 'secret'
		secret.value = secretValue

		var redirectUrl = document.createElement('input')
		redirectUrl.name = 'redirectUrl'
		redirectUrl.value = url

		form.appendChild(secret)
		form.appendChild(redirectUrl)

		document.body.appendChild(form)
		form.submit()
	},
}

export default authenticationService
