import { TFunction } from 'i18next'
import Axios from 'axios'

import { setUpApplicationInterceptorsFor } from './interceptors'
import { getHeadersWithJwtToken } from 'utils/authHelpers'
import { cdaApiRoutes } from 'constants/routes/cdaApiRoutes'
import { IApplicationInformation } from 'interfaces/i-application-information'
import { SigningUrlType } from 'constants/enums/signingUrlTypes'
import { IApplicationUrlsDto } from 'interfaces/i-signing-urls'

const transportWithCredentials = setUpApplicationInterceptorsFor(
	Axios.create({})
)

transportWithCredentials.defaults.headers.post['Content-Type'] =
	'application/json'
transportWithCredentials.defaults.headers.post['Access-Control-Allow-Origin'] =
	'*'
transportWithCredentials.defaults.headers.post['Access-Control-Allow-Headers'] =
	'Origin, X-Requested-With, Content-Type, Accept'

/* Service used when fetching initial application data from phoenix - used in useApplicationData hook */
const getApplicationServices = {
	async GetApplicationInformation(applicationId: number, t: TFunction) {
		const data =
			await transportWithCredentials.get<IApplicationInformation>(
				cdaApiRoutes.getApplicationInformation(applicationId),
				await getHeadersWithJwtToken()
			)

		if (data.status === 200) {
			if (data.data) {
				//----for testing purpose---
				// response.data.lifeCycleStatus = LifeCycleStatus.Disbursed
				// response.data.signingUrls.pnSigning.status = Status.Signed
				// response.data.signingUrls.psD2.status = Status.Created
				// response.data.signingUrls.psD2.isMandatory = true
				//-----test end-----
				return data.data
			}
		} else if (data.status === 400) {
			console.error(data.data)
			throw new Error(t('NoApplicationInformationError'))
		} else {
			console.error(data.data)
			throw new Error(t('ApplicationInformationError'))
		}
	},

	async GetSigningUrls(
		applicationId: number,
		signingUrl: SigningUrlType,
		t: TFunction
	) {
		const data = await transportWithCredentials.get<IApplicationUrlsDto>(
			cdaApiRoutes.getSigningUrl(applicationId, signingUrl),
			await getHeadersWithJwtToken()
		)

		if (data.status === 200) {
			if (data.data) return data.data
			else throw new Error(t('NoSigningUrlsError'))
		}

		throw new Error(t('SigningUrlsError'))
	},
}

export default getApplicationServices
