import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MarketingConsentCheckbox } from '../MarketingConsent/MarketingConsent'

import { IApplicationInformation } from 'interfaces/i-application-information'
import {
	getQueryValues,
	isPnStatusSigned,
	isPsd2Mandatory,
	isPsd2StatusSigned,
	onPnSignClick,
} from 'utils/loanHelpers'
import { UrlStatus } from 'constants/enums/signingUrlTypes'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { InfoBox } from 'components/message-boxes/information-box/InformationBox'
import updateApplicationServices from 'services/updateApplicationServices'
import ButtonSpinner from 'components/button-spinner/ButtonSpinner'

import './SignPn.scss'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

const isPnSentByPost = (applicationData: IApplicationInformation) =>
	applicationData?.signingUrls?.pnSigning?.status! === UrlStatus.SendByPost

const isPsd2StatusDeclined = () => getQueryValues().psd2status === 'skip'

export const SignPnFi = () => {
	const { t } = useTranslation()
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<boolean>(false)

	const ResponseMessage = () => {
		const { t } = useTranslation()

		return (
			<div className="pn-response-container">
				{isPsd2StatusDeclined() &&
					isPsd2Mandatory(loanApplication.applicationData!) && (
						<InfoBox
							infoType="info"
							css="psd2-skipped"
							message={t('SigningSkippedMessagePSD2')}
						/>
					)}
				{isPnSentByPost(loanApplication.applicationData!) && (
					<InfoBox
						infoType={'post'}
						message={t('SendByPostMessage')}
					/>
				)}
				{isPsd2Mandatory(loanApplication.applicationData!) ? (
					isPnStatusSigned(loanApplication.applicationData!) &&
					isPsd2StatusSigned(loanApplication.applicationData!) ? (
						<InfoBox
							infoType={'success'}
							message={t('SuccessMessageSigning')}
						/>
					) : isPnStatusSigned(loanApplication.applicationData!) ? (
						<InfoBox
							infoType={'success'}
							message={t('SuccessMessagePN')}
						/>
					) : null
				) : isPnStatusSigned(loanApplication.applicationData!) ? (
					<InfoBox
						infoType={'success'}
						message={t('SuccessMessageSigning')}
					/>
				) : null}
				{(getQueryValues().status === 'fail' ||
					getQueryValues().status === 'abort') && (
					<ErrorBox message={t('SigningFailedMessagePN')} />
				)}
			</div>
		)
	}

	const updateAllowMarketing = async () => {
		if (loanApplication.applicationData?.customerConsents?.allowMarketing)
			return
		try {
			await updateApplicationServices.updateCustomerConsent(
				loanApplication.customerConsents!,
				t
			)
		} catch (error) {
			console.warn(error)
			setError(true)
		}
	}

	const submit = async () => {
		setError(false)
		setLoading(true)
		try {
			await updateAllowMarketing().then(() => {
				onPnSignClick(
					loanApplication.applicationData!,
					loanApplication.signingUrls!,
					t
				)
			})
		} catch (error) {
			console.warn(error)
			setError(true)
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			<div className="sign-pn finland">
				{isPnStatusSigned(loanApplication.applicationData!) ||
				isPnSentByPost(loanApplication.applicationData!) ? (
					<ResponseMessage />
				) : (
					<>
						<ResponseMessage />
						<div className="signing-section">
							<h6>{t('PromissoryNote')}</h6>
							<div className="sign-pn-information-section">
								<div>{t('PromissoryNoteSigningLine1')}</div>
								<div>{t('PromissoryNoteSigningLine2')}</div>
							</div>
							{!loanApplication.applicationData?.customerConsents
								.allowMarketing && <MarketingConsentCheckbox />}
							{error && (
								<div className="error-msg">{t('ErrorMsg')}</div>
							)}

							<div className="sign-pn-button-section">
								<button
									className="btn btn-primary"
									onClick={() => {
										submit()
									}}>
									{loading ? (
										<div className="sign-pn-spinner-container">
											<ButtonSpinner />
										</div>
									) : (
										<>{t('SIGNPN')}</>
									)}
								</button>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	)
}
