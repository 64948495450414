import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import upload_document from 'img/document-icons/upload-document.svg'
import idcard_front from 'img/id-card/idcard-front.svg'
import idcard_back from 'img/id-card/idcard-back.svg'
import green_success_checkmark from 'img/document-icons/green-success-checkmark.svg'
import { IApplicationInformation } from 'interfaces/i-application-information'
import { checkIsMobile } from 'utils/commonhelpers'
import { IDocumentDto, IDocumentPageProps } from 'interfaces/i-upload-documents'
import { IDocuments } from 'interfaces/i-documents'
import { DocumentTypes } from 'constants/enums/documentTypes'
import documentServices from 'services/documentServices'
import { downloadBlob } from 'utils/documentHelpers'
import DropZone from 'components/application-blocks/common-blocks/UploadDocuments/DropZone'
import { NationalityType } from 'constants/enums/countryTypes'
import { IdentificationType } from 'interfaces/i-identification-type'

let isMobile = checkIsMobile()

interface IUploadIdentification {
	application: IApplicationInformation
	setSuccess: Function
}

export const UploadIdentificationDe = ({
	application,
	setSuccess,
}: IUploadIdentification) => {
	const { t } = useTranslation(['translation', 'dropdown'])
	const [files, setFiles] = useState<IDocumentDto[]>([])
	const [counter, setCounter] = useState<number>()
	const [isChecked, setChecked] = useState<boolean>(false)
	const [uploadedDocuments, setUploadedDocuments] = useState<IDocuments[]>([])
	const [option, setOption] = useState<IdentificationType | undefined>(
		IdentificationType.Default
	)
	const [pageProps, setPageProps] = useState<IDocumentPageProps>({
		disabled: true,
		loading: false,
		error: undefined,
	})
	const [identificationMethod, setIdentificationMethod] =
		useState<IIdentificationMethod>()

	const applicationId = sessionStorage.getItem('applicationid')

	const isUploaded = (type: DocumentTypes) =>
		uploadedDocuments.some((doc) => doc.type === type)

	const setDocumentsThatShouldBeUploaded = () => {
		const filter = (method: IIdentificationMethod) =>
			method.nationalityType ===
				application.personalInfo?.nationalityType! &&
			method.identificationType === option

		setIdentificationMethod(IdentificationMethods.find(filter))
	}

	const onSubmitDocuments = () => {
		setPageProps({ ...pageProps, loading: true })
		setCounter(0)

		files.map(async (file: IDocumentDto) => {
			const formData = new FormData()
			formData.append('documentType', file.documentType)
			formData.append('document.content', file.content)
			formData.append('document.contentType', file.content.type)
			formData.append('document.fileName', file.content.name)

			try {
				await documentServices
					.UploadDocument(formData, parseInt(applicationId!), t)
					.then(() => setCounter((preState: any) => preState + 1))
					.then(() =>
						uploadedDocuments.push({
							type: file.documentType,
							fileName: file.content.name,
						} as IDocuments)
					)
			} catch (e) {
				console.log(e)
				setPageProps({
					...pageProps,
					error: new Error(t('UploadDocumentError')), //name on file
				})
			}
		})
		//IF here and no errors, complete
	}

	const getDownloadDocumentById = async (
		documentId: string,
		fileName: string
	) => {
		try {
			const { blob } =
				await documentServices.getDownloadDocumentById(documentId)
			downloadBlob(blob, fileName)
		} catch (error) {
			console.log(error)
			setPageProps({
				...pageProps,
				error: new Error(t('DownloadError')),
			})
		}
	}

	const downloadDocuments = async (applicationId: string) => {
		try {
			const documents = await documentServices.getDownloadDocuments(
				applicationId,
				t
			)
			setUploadedDocuments(documents)
		} catch (error) {
			console.log(error)
		}
	}

	const isUploadsComplete = () => {
		let requiredUploads: DocumentTypes[] = []
		//Return unique items to avoid comparence between optional items
		let uploaded = uploadedDocuments.map(
			(doc) => DocumentTypes[doc.type as DocumentTypes]
		)

		//Sort out not optional options
		identificationMethod?.uploadBlocks.forEach((s: IDropZoneItem) => {
			if (s.isMandatory) requiredUploads.push(s.documentType)
		})
		if (identificationMethod?.additionalContent !== undefined && isChecked)
			requiredUploads.push(
				identificationMethod.additionalContent.documentType
			)

		return (
			// Compare arrays
			uploaded.length >= requiredUploads.length &&
			requiredUploads.length > 0 &&
			requiredUploads.every((item) => uploaded.indexOf(item) > -1)
		)
	}

	const createDropZoneObject = (item: IDropZoneItem) => {
		return (
			<DropZone
				files={files}
				setFiles={setFiles}
				accept={item.accept}
				icon={
					isUploaded(item.documentType)
						? green_success_checkmark
						: item.icon
				}
				text={
					isUploaded(item.documentType)
						? 'DocumentUploadedSuccess'
						: item.text
				}
				documentType={item.documentType}
				maxFiles={item.maxFiles}
				dropHeading={item.dropHeading}
				disabled={isUploaded(item.documentType)}
				documentsUploaded={uploadedDocuments}
				downloadDocument={(documentId: string, fileName: string) =>
					getDownloadDocumentById(documentId, fileName)
				}
			/>
		)
	}

	const onDropdownChange = (e: any) => {
		setOption(e.target.value)
		setFiles([])
	}

	//Init effect
	useEffect(() => {
		setDocumentsThatShouldBeUploaded()
		downloadDocuments(applicationId!.toString())
	}, [option])

	useEffect(() => {
		setSuccess(isUploadsComplete())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [identificationMethod, files, isChecked])

	useEffect(() => {
		if (counter === files.length) {
			setPageProps({
				...pageProps,
				loading: false,
				disabled: false,
			})
			setFiles([])
			downloadDocuments(applicationId!.toString())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [counter])

	useEffect(() => {
		setPageProps({
			...pageProps,
			disabled:
				identificationMethod?.identificationType ===
				IdentificationType.ResidencePermit
					? files.length !== 2
					: !files.length,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [files])

	return (
		<>
			<h5 className="">{t('UploadIdCard')}</h5>
			<hr />
			<div className="my-4">{t('UploadIdCardText')}</div>
			<div className="d-flex identification-select">
				<div className="d-flex flex-column pr-4">
					<h6>{t('IdentificationType')}</h6>
					<p>{t('ChooseIdentification')}</p>
				</div>
				<select
					className="form-select"
					aria-label="Default select example"
					value={option}
					onChange={onDropdownChange}>
					{getDropdownOptions(
						application.personalInfo?.nationalityType!
					)}
				</select>
				<div className="d-flex"></div>
			</div>
			<div
				className={
					isMobile
						? ''
						: 'd-flex flex-row mt-3 flex-wrap justify-content-center'
				}>
				{identificationMethod?.uploadBlocks.map(
					(value: IDropZoneItem, index: number) => {
						return (
							<div
								className={'mt-1 mr-1 col-12 col-md-5'}
								key={index}>
								{createDropZoneObject(value)}
							</div>
						)
					}
				)}
			</div>
			{identificationMethod?.additionalContent && (
				<div>
					<label className="checkbox-container mb-4">
						<input
							type="checkbox"
							checked={isChecked}
							disabled={false}
							onChange={() => {
								setChecked(!isChecked)
							}}
						/>
						<span className="checkmark"></span>
						{t('OlderRegistrationCertificate')} <br />
						<span className="checkmark-span">
							{t('OlderRegistrationCertificateText')}
						</span>
					</label>
					{isChecked && (
						<div
							className={
								isMobile
									? ''
									: 'd-flex flex-row mt-3 justify-content-center'
							}>
							<div className={'mt-1 mr-1 col-12 col-md-5'}>
								{createDropZoneObject(
									identificationMethod.additionalContent
								)}
							</div>
						</div>
					)}
				</div>
			)}
			{option !== IdentificationType.Default && (
				<div className="d-flex flex-row mt-3 mb-5">
					<button
						disabled={pageProps.disabled}
						style={{ width: isMobile ? '100%' : '' }}
						className="btn btn-default"
						onClick={() => onSubmitDocuments()}>
						{pageProps.loading ? (
							<div id="circle-spinner"></div>
						) : (
							t('UploadImages')
						)}
					</button>
				</div>
			)}
		</>
	)
}

const getDropdownOptions = (citizen: NationalityType) => {
	return (
		<>
			<option value={IdentificationType.Default}>
				Bitte wählen Sie:
			</option>
			{citizen !== NationalityType.NonEU ? (
				<>
					<option value={IdentificationType.Passport}>
						Reisepass
					</option>
					<option value={IdentificationType.IdCard}>
						Personalausweis
					</option>
				</>
			) : (
				<>
					<option value={IdentificationType.ResidencePermit}>
						Aufenthaltstitel, -erlaubnis, und karte (als Karte)
					</option>
					<option value={IdentificationType.Passport}>
						Aufenthaltstitel als Etikett
					</option>
				</>
			)}
		</>
	)
}

export interface IDropZoneItem {
	text: string
	maxFiles: number
	documentType: DocumentTypes
	icon?: string
	accept?: string
	dropHeading?: string
	isMandatory?: boolean
}

export interface IUploadBlocks {
	idCard: {
		objectProps: IDropZoneItem[]
	}
	passport: {
		objectProps: IDropZoneItem[]
	}
	addressProf: {
		objectProps: IDropZoneItem[]
	}
	registrationCertificate: {
		objectProps: IDropZoneItem[]
	}
	residencePermit: {
		objectProps: IDropZoneItem[]
	}
	etikett: {
		objectProps: IDropZoneItem[]
	}
}

export const uploadBlocks: IUploadBlocks = {
	idCard: {
		objectProps: [
			{
				accept: 'image/jpeg, image/png, application/pdf',
				icon: idcard_front,
				text: 'IdCardSelectImgFrontText',
				documentType: DocumentTypes.IdCard,
				maxFiles: 1,
				isMandatory: true,
				dropHeading: 'IdCardSelectImgFront',
			},
			{
				accept: 'image/jpeg, image/png, application/pdf',
				icon: idcard_back,
				text: 'IdCardSelectImgBackText',
				documentType: DocumentTypes.IdCard,
				maxFiles: 1,
				isMandatory: false,
				dropHeading: 'IdCardSelectImgBack',
			},
		],
	},
	passport: {
		objectProps: [
			{
				accept: 'image/jpeg, image/png, application/pdf',
				icon: idcard_front,
				text: 'PassportSelectImgFrontText',
				documentType: DocumentTypes.Passport,
				maxFiles: 1,
				dropHeading: 'PassportSelectImgFront',
				isMandatory: true,
			},
			{
				accept: 'image/jpeg, image/png, application/pdf',
				icon: idcard_back,
				text: 'PassportSelectImgBackText',
				documentType: DocumentTypes.Passport,
				maxFiles: 1,
				isMandatory: false,
				dropHeading: 'PassportSelectImgBack',
			},
		],
	},
	addressProf: {
		objectProps: [
			{
				accept: 'image/jpeg, image/png, application/pdf',
				icon: upload_document,
				text: 'AddressProofText',
				documentType: DocumentTypes.AddressProof,
				maxFiles: 1,
				dropHeading: 'AddressProof',
				isMandatory: true,
			},
		],
	},
	registrationCertificate: {
		//Meldebescheinigung
		objectProps: [
			{
				accept: 'image/jpeg, image/png, application/pdf',
				icon: upload_document,
				text: 'RegistrationCertificateText',
				documentType: DocumentTypes.RegistrationCertificate,
				maxFiles: 1,
				dropHeading: 'RegistrationCertificate',
				isMandatory: true,
			},
		],
	},
	residencePermit: {
		objectProps: [
			{
				accept: 'image/jpeg, image/png, application/pdf',
				icon: upload_document,
				text: 'ResidencePermitText',
				documentType: DocumentTypes.ResidencePermit,
				maxFiles: 1,
				dropHeading: 'ResidencePermit',
				isMandatory: true,
			},
			{
				accept: 'image/jpeg, image/png, application/pdf',
				icon: upload_document,
				text: 'ResidencePermitTextBack',
				documentType: DocumentTypes.ResidencePermit,
				maxFiles: 1,
				dropHeading: 'ResidencePermitBack',
				isMandatory: true,
			},
		],
	},
	etikett: {
		objectProps: [
			{
				accept: 'image/jpeg, image/png, application/pdf',
				icon: upload_document,
				text: 'EtikettText',
				documentType: DocumentTypes.ResidencePermit,
				maxFiles: 1,
				dropHeading: 'Etikett',
				isMandatory: true,
			},
		],
	},
}

export interface IIdentificationMethod {
	nationalityType: NationalityType
	identificationType: IdentificationType | undefined
	uploadBlocks: IDropZoneItem[]
	additionalContent: IDropZoneItem | undefined
}

export const IdentificationMethods: IIdentificationMethod[] = [
	// German citizenship
	{
		nationalityType: NationalityType.German,
		identificationType: IdentificationType.IdCard,
		uploadBlocks: uploadBlocks['idCard'].objectProps,
		additionalContent: undefined,
	},
	{
		nationalityType: NationalityType.German,
		identificationType: IdentificationType.Passport,
		uploadBlocks: uploadBlocks['passport'].objectProps.concat(
			uploadBlocks['addressProf'].objectProps
		),
		additionalContent: undefined,
	},
	{
		nationalityType: NationalityType.German,
		identificationType: undefined,
		uploadBlocks: uploadBlocks['addressProf'].objectProps,
		additionalContent: undefined,
	},
	// EU citizenship, non German
	{
		nationalityType: NationalityType.EU,
		identificationType: IdentificationType.IdCard,
		uploadBlocks: uploadBlocks['idCard'].objectProps.concat(
			uploadBlocks['registrationCertificate'].objectProps
		),
		additionalContent: uploadBlocks['addressProf'].objectProps[0],
	},
	{
		nationalityType: NationalityType.EU,
		identificationType: IdentificationType.Passport,
		uploadBlocks: uploadBlocks['passport'].objectProps.concat(
			uploadBlocks['registrationCertificate'].objectProps
		),
		additionalContent: uploadBlocks['addressProf'].objectProps[0],
	},
	{
		nationalityType: NationalityType.EU,
		identificationType: undefined,
		uploadBlocks: uploadBlocks['registrationCertificate'].objectProps,
		additionalContent: uploadBlocks['addressProf'].objectProps[0],
	},
	// Non EU citizenship
	{
		nationalityType: NationalityType.NonEU,
		identificationType: IdentificationType.ResidencePermit,
		uploadBlocks: uploadBlocks['residencePermit'].objectProps,
		additionalContent: undefined,
	},
	{
		nationalityType: NationalityType.NonEU,
		identificationType: IdentificationType.Passport,
		uploadBlocks: uploadBlocks['passport'].objectProps
			.concat(uploadBlocks['registrationCertificate'].objectProps)
			.concat(uploadBlocks['etikett'].objectProps),
		additionalContent: uploadBlocks['addressProf'].objectProps[0],
	},
]
