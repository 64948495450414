import appConfig from 'app.config'
import { Education } from 'constants/enums/educationTypes'
import { EmploymentSector } from 'constants/enums/employmentSectorTypes'
import { OccupationType } from 'constants/enums/occupationTypes'
import { PropertyType } from 'constants/enums/propertyTypes'
import { TranslationFile } from 'i18n'
import { IApplicationInformation } from 'interfaces/i-application-information'
import { useTranslation } from 'react-i18next'
import { FormatEnumToList } from 'utils/commonhelpers'
import { formatToStringLocale } from 'utils/dateAndTimeHelpers'

export const ApplicationEmploymentForm = (data: IApplicationInformation) => {
	const { t } = useTranslation([TranslationFile.Forms])
	return {
		fieldSetName: '',
		formName: 'ApplicationEmploymentForm',
		description: t('forms:EmploymentDescription'),
		collapsable: false,
		show: true,
		visible: true,
		market: appConfig.country,
		fullSize: true,
		col: 12,
		controls: [
			{
				required: true,
				controlName: 'education',
				type: 'dropdown',
				label: t('forms:Education'),
				options: FormatEnumToList(
					Education,
					PropertyType.Education,
					TranslationFile.Dropdown
				),
				value:
					data.employment?.education === Education.Unspecified
						? null
						: data.employment?.education,
				visible: true,
				col: 12,
			},
			{
				required: true,
				controlName: 'occupationType',
				type: 'dropdown',
				label: t('forms:Occupation'),
				options: FormatEnumToList(
					OccupationType,
					PropertyType.Occupation,
					TranslationFile.Dropdown
				),
				value: data.employment?.occupationType,
				visible: true,
				col: 12,
			},
			{
				required: false,
				controlName: 'employerName',
				type: 'text',
				label: t('forms:EmployerName'),
				value: data.employment?.employerName,
				visible: true,
				col: 12,
			},
			{
				required: true,
				controlName: 'employedSince',
				type: 'text',
				label: t('forms:EmployedSince'),
				description: 'DD/MM/YYYY',
				value: formatToStringLocale(data.employment?.employedSince),
				validations: [
					{
						code: 'MustMatchPattern',
						value: '^(\\b([0][1-9]|[12][0-9]|3[0-1]|[1-9])\\b)([/])(\\b([0][1-9]|[1][0-2]|[0-9]|[1-9])\\b)([/])(\\b([12]([9]|[0])[0-9][0-9])\\b)$',
						message: t('forms:ValidationMsgBirthdate'),
					},
				],
				visible: true,
				col: 12,
			},
			{
				required: true,
				controlName: 'employmentSector',
				type: 'dropdown',
				label: t('forms:EmploymentSector'),
				options: FormatEnumToList(
					EmploymentSector,
					PropertyType.EmploymentSector,
					TranslationFile.Dropdown
				),
				value: data.employment?.employmentSector,
				visible: true,
				col: 12,
			},
			{
				required: true,
				controlName: 'monthlyNetIncome',
				type: 'text',
				label: t('forms:Income'),
				value: data.employment?.monthlyNetIncome,
				description: t('forms:OtherIncomeDescription'),
				validations: [
					{
						code: 'MustMatchPattern',
						value: '^[0-9]*$',
						message: t('forms:ValidationMsgValue'),
					},
				],
				visible: true,
				col: 12,
			},
			{
				required: false,
				controlName: 'otherIncome',
				type: 'text',
				label: t('forms:OtherIncome'),
				value: data.employment?.otherIncome,
				validations: [
					{
						code: 'MustMatchPattern',
						value: '^[0-9]*$',
						message: t('forms:ValidationMsgValue'),
					},
				],
				visible: true,
				col: 12,
			},
		],
	}
}
