import React, {
	Context,
	PropsWithChildren,
	ReactElement,
	ReactNode,
	createContext,
	useState,
	useEffect,
	useContext,
} from 'react'
import appConfig from 'app.config'
import { useTranslation } from 'react-i18next'

import { IFooter } from 'interfaces/i-footer'
import { footerValues } from 'constants/values/footerValues'
import AuthContext from './AuthContext'
import { ApplicationType } from 'constants/enums/applicationTypes'
import { IApplicationInformation } from 'interfaces/i-application-information'
import { IApplicationUrlsDto } from 'interfaces/i-signing-urls'
import { IDocuments } from 'interfaces/i-documents'
import { HeaderType } from 'constants/enums/headerTypes'
import { TranslationFile } from 'i18n'
import { useApplicationData } from 'hooks/useApplicationData'
import { ProductType } from 'constants/enums/productTypes'
import { CheckIsLifeCycleStatus } from 'utils/loanHelpers'
import { LifeCycleStatus } from 'constants/enums/lifeCycleStatus'
import { CountryTypes } from 'constants/enums/countryTypes'
import { applicationContextConfigLoans } from 'constants/values/application-context-values/acvLoans'
import { applicationContextConfigCreditCards } from 'constants/values/application-context-values/acvCreditCards'

interface IAppContext {
	market: string
	applicationType: ApplicationType | undefined
	loading: boolean
	error: Error | undefined
	header: JSX.Element | undefined
	setHeader: Function
	multistep: JSX.Element | undefined
	applicationInformation: IUseApplicationData | undefined
	footerContent: IFooter | undefined
}

interface IUseApplicationData {
	applicationData: IApplicationInformation | undefined
	error: any | undefined
	loading: boolean | undefined
	signingUrls: IApplicationUrlsDto | undefined
	documentData: IDocuments[] | undefined
	setApplicationData: Function
	setError: Function
	setLoading: Function
	setSigningUrls: Function
	setDocumentData: Function
}

/**
 * This is the main context of the application page project. Here we set the main settings per market and apply the components and settings we use depending on market.
 * @returns Context settings
 */

const AppContext: Context<IAppContext> = createContext<IAppContext>({
	market: appConfig.country,
	applicationType: undefined,
	loading: false,
	error: undefined,
	header: undefined,
	setHeader: () => {},
	multistep: undefined,
	footerContent: undefined,
	applicationInformation: undefined,
})

export function AppContextProvider({
	children,
}: PropsWithChildren<ReactNode>): ReactElement {
	const { isAuthenticated, market } = useContext(AuthContext)
	const [footerContent, setFooterContent] = useState<IFooter | undefined>(
		undefined
	)
	const [applicationType, setApplicationType] = useState<ApplicationType>()
	const [multistep, setMultistep] = useState<JSX.Element>()
	const [header, setHeader] = useState<JSX.Element>()
	const [headerType, setHeaderType] = useState<HeaderType>()
	const [applicationInformation, setUseApplicationInformation] = useState<
		IUseApplicationData | undefined
	>(undefined)
	const searchParams = new URLSearchParams(
		window.location.search.toLowerCase()
	)
	const applicationId =
		searchParams.get('applicationid') ?? searchParams.get('userid') ?? sessionStorage.getItem("applicationid") ?? localStorage.getItem("applicationId")

	if (applicationId) {
		localStorage.setItem("applicationId", applicationId)	
	}

	const { t } = useTranslation([
		TranslationFile.Translation,
		TranslationFile.Forms,
		TranslationFile.Dropdown,
	])
	const {
		applicationData,
		error,
		loading,
		signingUrls,
		documentData,
		setApplicationData,
		setError,
		setLoading,
		setSigningUrls,
		setDocumentData,
	} = useApplicationData({
		applicationId,
		t,
	})

	useEffect(() => {
		setUseApplicationInformation({
			...applicationInformation,
			applicationData: applicationData,
			error: error,
			loading: loading,
			signingUrls: signingUrls,
			documentData: documentData,
			setApplicationData: setApplicationData,
			setError: setError,
			setLoading: setLoading,
			setSigningUrls: setSigningUrls,
			setDocumentData: setDocumentData,
		})
	}, [applicationData, error, loading, signingUrls, documentData]) //eslint-disable-line

	const getApplicationType = (product: ProductType | undefined) => {
		switch (product) {
			case ProductType.DeuCreditCard:
			case ProductType.EspCreditCard:
			case ProductType.NorCreditCardGold:
			case ProductType.NorCreditCard:
				setApplicationType(ApplicationType.Card)
				break
			case ProductType.EspCashLoan:
			case ProductType.DnkCashLoan:
			case ProductType.FinCredento:
			case ProductType.FinHalens:
			case ProductType.FinRefin:
			case ProductType.NorRefin:
			case ProductType.NorCredento:
			case ProductType.FinCashLoan:
			case ProductType.FinCashLoanPpi:
			case ProductType.FinHalensLoan:
			case ProductType.FinHalensLoanPpi:
			case ProductType.FinRefinLoan:
			case ProductType.FinRefinLoanPpi:
			case ProductType.FinRefinPpi:
				// case ProductType.EstCarLoan:
				// case ProductType.EstTfbank:
				// case ProductType.EstRefin:
				// case ProductType.EstMortgage:
				setApplicationType(ApplicationType.Loan)
				break
			default:
				setApplicationType(undefined)
		}
	}

	const getLoanHeaderType = (
		applicationData: IApplicationInformation,
		country: string | undefined
	) => {
		if (applicationData?.lifeCycleStatus !== undefined) {
			switch (country) {
				case CountryTypes.dk:
				case CountryTypes.fi:
					applicationData?.topUp?.number === undefined
						? setHeaderType(HeaderType.LoanOffer)
						: setHeaderType(HeaderType.LoanOfferTopUp)
					break
				case CountryTypes.es:
					CheckIsLifeCycleStatus(
						[
							LifeCycleStatus.ApplicationReceived,
							LifeCycleStatus.MultiStepUpdateLoanData,
							LifeCycleStatus.CreditDecisionError,
							LifeCycleStatus.CreditDecisionInvestigation,
						],
						applicationData!.lifeCycleStatus!
					)
						? setHeaderType(HeaderType.LoanApplication)
						: setHeaderType(HeaderType.LoanOffer)
					break
				case CountryTypes.no:
					setHeaderType(HeaderType.LoanOffer)
					break
				default:
					setHeaderType(HeaderType.LoanOffer)
					break
			}
		}
	}

	const getApplicationComponents = (
		country: string,
		applicationType: ApplicationType
	) => {
		switch (applicationType) {
			case ApplicationType.Loan:
				applicationContextConfigLoans.map((item) => {
					if (item.country === country) {
						setMultistep(item.multistep)
						item.header.map((item) => {
							if (item.headerType === headerType) {
								return setHeader(item.headerContent)
							} else {
								return null
							}
						})
					}
					return null
				})
				break
			case ApplicationType.Card:
				applicationContextConfigCreditCards.map((item) => {
					if (item.country === country) {
						setHeader(item.header)
						setMultistep(item.multistep)
					}
					return null
				})
				break
			case ApplicationType.Deposit:
			default:
				setMultistep(<></>)
				setHeader(<></>)
		}
	}

	const getFooterValues = (market: string) => {
		let values = footerValues.find((item) => {
			if (item.country === market) {
				return item
			} else {
				return undefined
			}
		})
		setFooterContent(values)
	}

	/* If user is authenticated - we fetch all content needed for the application page */
	/* Header, Footer, ApplicationInformation etc */
	useEffect(() => {
		if (isAuthenticated) {
			getFooterValues(market)
		}
		return () => {}
	}, [market, isAuthenticated])

	useEffect(
		() => {
			if (market !== undefined && applicationType !== undefined) {
				getApplicationComponents(market, applicationType)
			}
		},
		[market, applicationType] //eslint-disable-line
	)

	useEffect(() => {
		if (applicationData) {
			getLoanHeaderType(applicationData, market)
		}
	}, [applicationData, market])

	useEffect(() => {
		if (applicationData !== undefined) {
			getApplicationType(applicationData?.product)
		}
	}, [applicationData])

	return (
		<AppContext.Provider
			value={{
				market,
				applicationType,
				loading,
				error,
				applicationInformation,
				header,
				multistep,
				setHeader,
				footerContent,
			}}>
			{children}
		</AppContext.Provider>
	)
}

export default AppContext
