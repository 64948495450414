import React, { useContext, useEffect, useState } from 'react'
import { FormBuilder, formKeySelector } from '@tf/formbuilder'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { IApplicationContext } from 'interfaces/i-application-context-props'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import { Decision } from 'constants/enums/decision'
import { FormFactory } from 'formBuilder/formFactory'
import { CustomerForm } from 'constants/enums/formTypes'
import { getLanguageLocale, getMarket } from 'utils/countryHelpers'
import { IApplicationInformation } from 'interfaces/i-application-information'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import creditDecisionServices from 'services/creditDecisionServices'
import { CreditDecisionMessage } from 'components/creditdecisionmessage/CreditDecisionMessage'
import updateApplicationServices from 'services/updateApplicationServices'

import './ApplicationBlocks.scss'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'
import { cdaApiRoutes } from 'constants/routes/cdaApiRoutes'

const Expenses = () => {
	const { t } = useTranslation()
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<boolean>(false)
	const [errorMessage, setErrorMessage] = useState<string>('ErrorMsg')
	const [comment, setComment] = useState<string | undefined>()
	const [preliminaryDecision, setPreliminaryDecision] = useState<
		Decision | undefined
	>(loanApplication.applicationData?.decision)
	const [hidePreviousButton, setHidePreviousButton] = useState<boolean>(
		msp.hidePreviousBtn
	)
	const product = loanApplication.applicationData?.product
	const applicationId = loanApplication.applicationData?.loanNumber

	let formSchema = FormFactory(
		CustomerForm.ApplicationExpensesForm,
		{
			formName: 'applicationExpensesForm',
			blockName: 'applicationExpensesForm',
			market: getMarket(),
			locale: getLanguageLocale(),
			getDataUrl: '',
			validationErrorList: false,
			requiredMessage: t('ValidateRequired'),
			submitButtonText: t('Next'),
		},
		loanApplication.applicationData as IApplicationInformation
	)

	const formStateValues = useSelector(
		formKeySelector(formSchema.formMeta.formName, 'controlsState')
	)

	useEffect(
		() => {
			dispatchLoanApplication({
				type: LOAN_APPLICATION_ACTIONS.SET_UPDATE_APPLICATIONDATA,
				payload: {
					...loanApplication,
					updateApplicationData: {
						...loanApplication.updateApplicationData,
						applicationId: applicationId,
						applicant: {
							...loanApplication.updateApplicationData?.applicant,
							loans: {
								otherTotal: formStateValues?.otherTotal?.value,
								otherMonthlyCost:
									formStateValues?.otherMonthlyCost?.value,
								houseTotal: formStateValues?.houseTotal?.value,
								houseMonthlyCost:
									formStateValues?.houseMonthlyCost?.value,
							},
							contacts: {
								...loanApplication.updateApplicationData
									?.applicant?.contacts,
								cellPhone:
									formStateValues?.mobilePhoneNumber?.value.replace(
										/[- )(]/g,
										''
									),
								officePhone:
									formStateValues?.mobilePhoneNumber
										?.value !==
									loanApplication.applicationData?.contacts
										?.cellPhone
										? loanApplication.applicationData
												?.contacts?.cellPhone
										: '',
								email: formStateValues?.email?.value,
							},
						},
						product: product,
					},
				},
			})
		},
		[formStateValues] // eslint-disable-line react-hooks/exhaustive-deps
	)

	const updateComment = async () => {
		if (comment !== undefined && comment !== '') {
			try {
				await updateApplicationServices.addPublicNote(
					{
						applicationId: applicationId!,
						note: comment,
					},
					t
				)
			} catch (error) {
				console.warn('Comment could not be submitted. Error: ', error)
				setError(true)
			}
		}
	}

	const submitLoanApplicationData = async () => {
		setLoading(true)
		setHidePreviousButton(true)
		try {
			await updateApplicationServices
				.updateLoanApplication(
					loanApplication.updateApplicationData!,
					t
				)
				.then((response) => {
					if (response?.status === 200) {
						runPreliminaryCreditDecision()
					}
				})
			await updateComment()
		} catch (error) {
			console.warn(error)
			setErrorMessage('UpdateApplicationError')
			setError(true)
			setLoading(false)
		}
	}

	const runPreliminaryCreditDecision = async () => {
		try {
			await creditDecisionServices
				.runPreliminaryCreditDecision(
					applicationId!.toString(),
					cdaApiRoutes.runmulticashpreliminarydecision
				)
				.then((response) => {
					if (response.status === 200) {
						response.data.decision === Decision.Approved ||
						response.data.decision === Decision.Investigation
							? goNext()
							: setPreliminaryDecision(
									response.data.decision as Decision
							  )
					}
				})
		} catch (error) {
			console.warn(error)
			setErrorMessage('ErrorMsg')
			setError(true)
		} finally {
			setLoading(false)
		}
	}

	function goNext() {
		dispatchMsp({
			type: MSP_ACTIONS.SET_INDEX,
			payload: {
				index: msp.index + 1,
			},
		})
	}

	useEffect(() => {
		setComment(formStateValues?.comment.value)
	}, [formStateValues?.comment])

	/* Hiding previous button on load */
	useEffect(() => {
		let button = document.querySelector('.pagination-container')
		if (hidePreviousButton) {
			if (button !== null) {
				button.setAttribute('class', 'hide')
			}
		}
		return
	}, [hidePreviousButton])

	return (
		<div className="expenses">
			{loading ? (
				<div className="col-spinner">
					<CircleSpinner />
				</div>
			) : (
				<>
					{preliminaryDecision === Decision.Approved ||
					preliminaryDecision === Decision.Investigation ? (
						<FormBuilder
							formSchema={formSchema!}
							apiError={undefined}
							showErrorsInForm={true}
							showSubmitButton={true}
							onSubmit={() => submitLoanApplicationData()}
						/>
					) : (
						<CreditDecisionMessage
							creditDecision={preliminaryDecision}
						/>
					)}
				</>
			)}
			{error ? (
				<div className="error-box-container">
					<ErrorBox message={t(errorMessage)} />
				</div>
			) : null}
		</div>
	)
}

export default Expenses
