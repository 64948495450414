/* This component can be removed when wwe have implemented cookie bot */
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CookieConsent from 'react-cookie-consent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AuthContext from 'contexts/AuthContext'

import './CookieConsentBar.scss'

interface ICookies {
	country: string
	cookieLink: string
}

const cookieTypes: ICookies[] = [
	{
		country: 'dk',
		cookieLink: 'https://tfbank.dk/om-tf-bank/cookies-og-sikkerhed',
	},
	{
		country: 'es',
		cookieLink: 'https://tfbank.es/sobre-tf-bank/seguridad-y-cookies',
	},
	{
		country: 'fi',
		cookieLink: 'https://tfbank.fi/evasteet',
	},
	{
		country: 'no',
		cookieLink: 'https://www.tfbank.no/personvernerklaring/',
	},
]

export function CookieConsentBar() {
	const { t } = useTranslation()
	const { market } = useContext(AuthContext)
	const [content, setContent] = useState<string>(
		'https://tfbank.se/om-tf-bank'
	)

	function getCookieLink() {
		cookieTypes.find((val) => {
			if (val.country === market) {
				setContent(val.cookieLink)
			} else {
				setContent(content)
			}
		})
	}

	useEffect(() => {
		getCookieLink()
	}, [])

	return (
		<CookieConsent
			buttonText={t('CookieButton')}
			containerClasses="cookie-consent"
			disableStyles={true}>
			<div className="cookie-text">
				<FontAwesomeIcon icon="cookie-bite" />
				<br />
				{t('CookieText')}
				<a target="_blank" href={content} rel="noopener noreferrer">
					{t('CookieLink')}
				</a>
			</div>
		</CookieConsent>
	)
}
