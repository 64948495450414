export enum UrlStatus {
	NotCreated = 'NotCreated',
	Created = 'Created',
	Signed = 'Signed',
	Canceled = 'Canceled',
	SendByPost = 'SendByPost',
	Declined = 'Declined',
}

export enum SigningUrlType {
	ApplicantSigning = 'ApplicantSigning',
	CoapplicantSigning = 'CoapplicantSigning',
	OnlineIdentification = 'OnlineIdentification',
	ApplicantPsd2 = 'ApplicantPsd2',
	CoapplicantPsd2 = 'CoapplicantPsd2',
	PpiContract = 'PpiContract',
}
