import React, { createContext, useEffect, useReducer, useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TranslationFile } from 'i18n'
import { Container, Row, Col } from 'react-bootstrap'

import { IApplicationContextProps } from 'interfaces/i-application-context-props'
import { getBirthdateFromClaims, getSubFromClaims } from 'utils/authHelpers'
import { InfoBox } from 'components/message-boxes/information-box/InformationBox'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import { CheckIsValidApplication } from 'utils/loanHelpers'
import { LifeCycleStatus } from 'constants/enums/lifeCycleStatus'
import AppContext from './AppContext'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

import 'common/styling/_forms.scss'

export const intitialState: IApplicationContextProps = {
	applicationData: undefined,
	setApplicationData: undefined,
	documentData: undefined,
	setDocumentData: undefined,
	signingUrls: undefined,
	setSigningUrls: undefined,
	loading: false,
	setLoading: undefined,
	error: undefined,
	setError: undefined,
	multisteps: [],
	personalData: undefined,
	updateApplicationData: undefined,
	loanOfferData: undefined,
	customerConsents: undefined,
	hasSignedPsd2: false,
	hasAddedPpi: false,
	isOnThankYouPage: false,
	isPep: false,
	showPepModal: false,
}

export const LOAN_APPLICATION_ACTIONS = {
	SET_APPLICATIONDATA: 'SET_APPLICATIONDATA',
	SET_MULTISTEPS: 'SET_MULTISTEPS',
	SET_ERROR_AND_LOADING: 'SET_ERROR_OR_LOADING',
	SET_PERSONALDATA: 'SET_PERSONALDATA',
	SET_UPDATE_APPLICATIONDATA: 'SET_UPDATE_APPLICATIONDATA',
	SET_LOANOFFERDATA: 'SET_LOANOFFERDATA',
	SET_UPDATE_CUSTOMERCONSENTS: 'SET_UPDATE_CUSTOMERCONSENTS',
	SET_UPDATE_PSD2: 'SET_UPDATE_PSD2',
	SET_UPLOAD_DOCUMENT: 'SET_UPLOAD_DOCUMENT',
	SET_UPDATE_PPI: 'SET_UPDATE_PPI',
	SET_IS_ON_THANKYOU_PAGE: 'SET_IS_ON_THANKYOU_PAGE',
	SET_IS_PEP: 'SET_IS_PEP',
}

function reducer(loanApplication: IApplicationContextProps, action: any) {
	switch (action.type) {
		case LOAN_APPLICATION_ACTIONS.SET_APPLICATIONDATA:
			return {
				...loanApplication,
				applicationData: action.payload.applicationData,
				setApplicationData: action.payload.setApplicationData,
				documentData: action.payload.documentData,
				setDocumentData: action.payload.setDocumentData,
				signingUrls: action.payload.signingUrls,
				setSigningUrls: action.payload.setSigningUrls,
				loading: action.payload.loading,
				setLoading: action.payload.setLoading,
				error: action.payload.error,
				setError: action.payload.setError,
			}
		case LOAN_APPLICATION_ACTIONS.SET_MULTISTEPS:
			return {
				...loanApplication,
				multisteps: action.payload.multisteps,
			}
		case LOAN_APPLICATION_ACTIONS.SET_ERROR_AND_LOADING:
			return {
				...loanApplication,
				error: action.payload.error,
				loading: action.payload.loading,
			}
		case LOAN_APPLICATION_ACTIONS.SET_PERSONALDATA:
			return {
				...loanApplication,
				personalData: action.payload.personalData,
			}
		case LOAN_APPLICATION_ACTIONS.SET_UPDATE_APPLICATIONDATA:
			return {
				...loanApplication,
				updateApplicationData: action.payload.updateApplicationData,
			}
		case LOAN_APPLICATION_ACTIONS.SET_LOANOFFERDATA:
			return {
				...loanApplication,
				loanOfferData: action.payload.loanOfferData,
			}
		case LOAN_APPLICATION_ACTIONS.SET_UPDATE_CUSTOMERCONSENTS:
			return {
				...loanApplication,
				customerConsents: action.payload.customerConsents,
			}
		case LOAN_APPLICATION_ACTIONS.SET_UPDATE_PSD2:
			return {
				...loanApplication,
				hasSignedPsd2: action.payload.hasSignedPsd2,
			}
		case LOAN_APPLICATION_ACTIONS.SET_UPDATE_PPI:
			return {
				...loanApplication,
				hasAddedPpi: action.payload.hasAddedPpi,
			}
		case LOAN_APPLICATION_ACTIONS.SET_IS_ON_THANKYOU_PAGE:
			return {
				...loanApplication,
				isOnThankYouPage: action.payload.isOnThankYouPage,
			}
		case LOAN_APPLICATION_ACTIONS.SET_IS_PEP:
			return {
				...loanApplication,
				isPep: action.payload.isPep,
				showPepModal: action.payload.showPepModal,
			}
		default:
			return loanApplication
	}
}

export const ApplicationDispatchContext = createContext<{
	loanApplication: IApplicationContextProps
	dispatchLoanApplication: any
}>({
	loanApplication: intitialState,
	dispatchLoanApplication: reducer,
})

const ApplicationContext = () => {
	const [loanApplication, dispatchLoanApplication] = useReducer(
		reducer,
		intitialState
	)
	const { market } = useContext(AppContext)
	const { multistep, header, applicationInformation } = useContext(AppContext)

	const { t } = useTranslation([
		TranslationFile.Translation,
		TranslationFile.Forms,
		TranslationFile.Dropdown,
	])

	const status = applicationInformation?.applicationData?.lifeCycleStatus
	const product = applicationInformation?.applicationData?.product;

	useEffect(() => {
		if (applicationInformation !== undefined) {
			dispatchLoanApplication({
				type: LOAN_APPLICATION_ACTIONS.SET_APPLICATIONDATA,
				payload: {
					...loanApplication,
					applicationData: applicationInformation.applicationData,
					setApplicationData:
						applicationInformation.setApplicationData,
					documentData: applicationInformation.documentData,
					setDocumentData: applicationInformation.setDocumentData,
					signingUrls: applicationInformation.signingUrls,
					setSigningUrls: applicationInformation.setSigningUrls,
					loading: applicationInformation.loading,
					setLoading: applicationInformation.setLoading,
					error: applicationInformation.error,
					setError: applicationInformation.setError,
				},
			})
		}
	}, [applicationInformation]) //eslint-disable-line

	useEffect(
		() => {
			const setSsnAndBirthDate = async () => {
				let ssn = await getSubFromClaims()
				let birthDate = await getBirthdateFromClaims()
				dispatchLoanApplication({
					type: LOAN_APPLICATION_ACTIONS.SET_PERSONALDATA,
					payload: {
						...loanApplication,
						personalData: {
							...loanApplication.personalData,
							ssn: ssn,
							birthDate: birthDate,
						},
					},
				})
			}

			setSsnAndBirthDate()
		},
		[loanApplication.applicationData] // eslint-disable-line react-hooks/exhaustive-deps
	)

	return (
		<ApplicationDispatchContext.Provider
			value={{
				loanApplication,
				dispatchLoanApplication,
			}}>
			<div id="application-content-page" className={market}>
				<Container className="background-container">
					<Container> 	
						<Row>
							<Col xs={12}>
								{applicationInformation?.loading ? (
									<div className="col-spinner start-page-spinner">
										<CircleSpinner />
									</div>
								) : (
									<>
										{applicationInformation?.error ? (
											<div className="error-box-container">
												<ErrorBox
													message={t(
														'ApplicationInformationError'
													)}
												/>
											</div>
										) : (
											<>
												{CheckIsValidApplication(
													status, 
													product
												) ? (
													<>
														{header}
														{multistep}
													</>
												) : (
													<InfoBox
														infoType="info"
														css={
															'application-status-box-info'
														}
														message={
															status ===
															LifeCycleStatus.Invalid ? (
																<Trans
																	t={t}
																	i18nKey={
																		'StatusText' +
																		status
																	}>
																	Unfortunately
																	we
																	<span>
																		cannot
																	</span>
																	grant you
																	the loan at
																	this time,
																	please
																	contact TF
																	Bank if you
																	want more
																	information.
																</Trans>
															) : (
																t(
																	'StatusText' +
																		status
																)
															)
														}
													/>
												)}
											</>
										)}
									</>
								)}
							</Col>
						</Row>
					</Container>
				</Container>
			</div>
		</ApplicationDispatchContext.Provider>
	)
}

export default ApplicationContext
