import React from 'react'
import './ButtonSpinner.scss'

/**
 * Used for spinners in buttons (...)
 * @returns Spinner animation
 */

const ButtonSpinner = () => {
	return <div className="button-spinner" />
}

export default ButtonSpinner
