import { AccommodationType } from 'constants/enums/accommodationTypes'
import { Education } from 'constants/enums/educationTypes'
import { EmploymentSector } from 'constants/enums/employmentSectorTypes'
import { MaritalStatus } from 'constants/enums/maritalStatusTypes'
import { MoneySource } from 'constants/enums/moneySourceTypes'
import { OccupationType } from 'constants/enums/occupationTypes'
import { PurposeOfLoan } from 'constants/enums/purposeOfLoanTypes'

export const kycCurrenciesAndValues: IKycCurrencyAndValues[] = [
	{
		currency: 'DKK',
		country: 'dk',
		income: [200000, 450000, 700000, 1000000, 1000001], // These numerical values are probably wrong, but no matter just now.
		purposeOfLoan: [
			PurposeOfLoan.Consumption,
			PurposeOfLoan.DebtConsolidation,
			PurposeOfLoan.Renovation,
			PurposeOfLoan.HomeExpenses,
			PurposeOfLoan.Healthcare,
			PurposeOfLoan.Travel,
			PurposeOfLoan.PurchaseVehicle,
			PurposeOfLoan.Donation,
			PurposeOfLoan.RealEstatePurchase,
			PurposeOfLoan.Other,
		],
	},
	{
		currency: '€',
		country: 'fi',
		income: [20000, 45000, 70000, 100000, 100001],
		occupation: [
			OccupationType.Permanent,
			OccupationType.PartTime,
			OccupationType.Entrepreneur,
			OccupationType.Pensioner,
			OccupationType.Unemployed,
			OccupationType.Student,
			OccupationType.TemporarilyEmployed,
			OccupationType.Other,
		],
		moneySource: [
			MoneySource.Income,
			MoneySource.Savings,
			MoneySource.Investments,
			MoneySource.Inheritance,
			MoneySource.Gift,
			MoneySource.SalesOfProperty,
			MoneySource.SalesOfVehicle,
			MoneySource.SalesOfCulture,
			MoneySource.SaleOfCompany,
			MoneySource.Gambling,
			MoneySource.OtherBusiness,
			MoneySource.CapitalGains,
			MoneySource.InsuranceReimbursement,
			MoneySource.SocialInsuranceAllowance,
			MoneySource.Other,
		],
		purposeOfLoan: [
			PurposeOfLoan.Consumption,
			PurposeOfLoan.DebtConsolidation,
			PurposeOfLoan.Renovation,
			PurposeOfLoan.HomeExpenses,
			PurposeOfLoan.Healthcare,
			PurposeOfLoan.Travel,
			PurposeOfLoan.PurchaseVehicle,
			PurposeOfLoan.Gambling,
			PurposeOfLoan.Donation,
			PurposeOfLoan.RealEstatePurchase,
			PurposeOfLoan.PrivateInvestment,
			PurposeOfLoan.Other,
		],
	},
	{
		currency: 'NOK',
		country: 'no',
		income: [200000, 450000, 700000, 1000000, 1000001],
		occupation: [
			OccupationType.Permanent,
			OccupationType.Housewife,
			OccupationType.Entrepreneur,
			OccupationType.Retired,
			OccupationType.Student,
			OccupationType.SickLeave,
		],
		moneySource: [
			MoneySource.Income,
			MoneySource.Investments,
			MoneySource.Inheritance,
			MoneySource.Gift,
			MoneySource.SalesOfProperty,
			MoneySource.SaleOfCompany,
			MoneySource.Gambling,
			MoneySource.OtherBusiness,
			MoneySource.CapitalGains,
		],
	},
	{
		currency: '€',
		country: 'es',
		income: [20000, 45000, 70000, 100000, 100001],
		occupation: [
			OccupationType.Permanent,
			OccupationType.Farmer,
			OccupationType.Housewife,
			OccupationType.Entrepreneur,
			OccupationType.SickLeave,
			OccupationType.Unemployed,
			OccupationType.Student,
			OccupationType.PublicServant,
			OccupationType.Retired,
			OccupationType.Pensioner,
			OccupationType.FixedTerm,
			OccupationType.PartTime,
			OccupationType.Other,
		],
		education: [
			Education.ProfessEducation,
			Education.Basic,
			Education.HighSchool,
			Education.University,
			Education.Other,
		],
		employmentSector: [
			EmploymentSector.Administration,
			EmploymentSector.Agriculture,
			EmploymentSector.Art,
			EmploymentSector.Finance,
			EmploymentSector.ConstructionRealEstate,
			EmploymentSector.Culture,
			EmploymentSector.EducationScience,
			EmploymentSector.PublicSector,
			EmploymentSector.IndustryEnergy,
			EmploymentSector.Gambling,
			EmploymentSector.LegalOrJudicial,
			EmploymentSector.Business,
			EmploymentSector.Technician,
			EmploymentSector.Health,
			EmploymentSector.Security,
			EmploymentSector.Services,
			EmploymentSector.Technology,
			EmploymentSector.Transport,
			EmploymentSector.Tourism,
			EmploymentSector.StallHolder,
			EmploymentSector.Other,
		],
		maritalStatus: [
			MaritalStatus.Married,
			MaritalStatus.Divorced,
			MaritalStatus.Cohabitee,
			MaritalStatus.Single,
			MaritalStatus.Widow,
		],
		accommodationType: [
			AccommodationType.Renter,
			AccommodationType.Tenant,
			AccommodationType.WithParents,
			AccommodationType.Owner,
			AccommodationType.Other,
		],
	},
	{
		currency: '€',
		country: 'de',
		income: [20000, 45000, 70000, 100000, 100001],
		occupation: [
			OccupationType.Permanent,
			OccupationType.Pensioner,
			OccupationType.Student,
			OccupationType.TemporarilyEmployed,
			OccupationType.Housewife,
			OccupationType.Unemployed,
		],
		moneySource: [
			MoneySource.Income,
			MoneySource.Inheritance,
			MoneySource.SalesOfProperty,
			MoneySource.Donation,
			MoneySource.SaleOfCompany,
			MoneySource.Gambling,
			MoneySource.OtherBusiness,
			MoneySource.CapitalGains,
		],
		purposeOfLoan: [
			PurposeOfLoan.Consumption,
			PurposeOfLoan.DebtConsolidation,
			PurposeOfLoan.Renovation,
			PurposeOfLoan.HomeExpenses,
			PurposeOfLoan.Healthcare,
			PurposeOfLoan.Travel,
			PurposeOfLoan.PurchaseVehicle,
			PurposeOfLoan.Gambling,
			PurposeOfLoan.Donation,
			PurposeOfLoan.RealEstatePurchase,
			PurposeOfLoan.PrivateInvestment,
		],
	},
]

export interface IKycCurrencyAndValues {
	currency: string
	country: string
	income: number[]
	occupation?: OccupationType[]
	purposeOfLoan?: PurposeOfLoan[]
	moneySource?: MoneySource[]
	maritalStatus?: MaritalStatus[]
	accommodationType?: AccommodationType[]
	education?: Education[]
	employmentSector?: EmploymentSector[]
}
