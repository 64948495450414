import React from 'react'
import { useTranslation } from 'react-i18next'
import { IApplicationInformation } from 'interfaces/i-application-information'
import { UploadIdentificationDe } from '../../UploadDocuments/UploadIdentificationDe'
import { NationalityType } from 'constants/enums/countryTypes'
import { LifeCycleStatus } from 'constants/enums/lifeCycleStatus'
import { UrlStatus } from 'constants/enums/signingUrlTypes'
import Heading from 'components/heading/Heading'
import { ReadMoreExpander } from 'components/read-more-expander/ReadMoreExpander'
import { InfoBox } from 'components/message-boxes/information-box/InformationBox'

interface ISignSection {
	application: IApplicationInformation
	applicationId: number
	signingLink: string
	success: boolean
	setSuccess: Function
}

const SignSection = ({
	application,
	signingLink,
	success,
	setSuccess,
}: ISignSection) => {
	const { t } = useTranslation()

	return (
		<div className="container my-5">
			{!success && (
				<section id="sign-section-de">
					<Heading title="IDNow" />

					{application?.signingUrls.onlineIdentification.status ===
						UrlStatus.SendByPost &&
						application.lifeCycleStatus ===
							LifeCycleStatus.InternalCheck && (
							<InfoBox
								infoType="post"
								css="post"
								message={t('SendByPostMessage')}
							/>
						)}

					{application?.signingUrls.onlineIdentification.status ===
						UrlStatus.Signed && (
						<InfoBox
							infoType="success"
							css="success"
							message={t('ThankYouIdNow')}
						/>
					)}

					{application?.signingUrls.onlineIdentification.status !==
						UrlStatus.Signed && (
						<>
							<div className="my-4 d-flex justify-content-center">
								{t('IDNowText')}
							</div>
							<div className="d-flex flex-row justify-content-center">
								<a
									className="btn btn-primary my-5"
									href={signingLink}>
									{t('SignButton')}
								</a>
							</div>

							<ReadMoreExpander
								children={
									<div className="upload-doc">
										{application.personalInfo
											?.nationalityType ===
										NationalityType.Unknown ? (
											<div className="d-flex justify-content-center">
												{t('ApplicationCompletionMsg')}
											</div>
										) : (
											<UploadIdentificationDe
												application={application!}
												setSuccess={setSuccess}
											/>
										)}
									</div>
								}
								headerExpanded={t('CloseSection')}
								headerCollapsed={t('IssuesIdNow')}
								showIcons={false}
								contentTop={true}
								collapsedHeight={'0rem'}
								className='id-now-expander'
								
							/>
						</>
					)}
				</section>
			)}

			{application?.lifeCycleStatus === LifeCycleStatus.InternalCheck ||
				(success && (
					<InfoBox
						infoType="success"
						css="success"
						message={t('ThankYouId')}
					/>
				))}
		</div>
	)
}

export default SignSection
