import React, { useContext, useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { CookieType } from 'constants/enums/cookieTypes'
import AuthContext from 'contexts/AuthContext'
import useStateWithSessionStorage from 'hooks/useStateWithSessionStorage'
import { AppContextProvider } from 'contexts/AppContext'

interface props {
	component: any
	path: any
	redirect: string
}

function PrivateRoute({ component: Component, redirect }: props) {
	const { isAuthenticated } = useContext(AuthContext)
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [value, setValue] = useStateWithSessionStorage(CookieType.RedirectUrl)
	const url = window.location.pathname
	const args = window.location.search.toLowerCase()

	useEffect(() => {
		// console.log('Login', { auth: isAuthenticated, redir: url + args })
		setValue(url + args)
	})

	return (
		<React.Fragment>
			{isAuthenticated ? (
				<AppContextProvider>
					<Component />
				</AppContextProvider>
			) : (
				<Navigate
					to={{
						pathname: redirect,
					}}
				/>
			)}
		</React.Fragment>
	)
}

export default PrivateRoute
