import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { InfoBox } from 'components/message-boxes/information-box/InformationBox'
import { ReadMoreExpander } from 'components/read-more-expander/ReadMoreExpander'
import { checkIsMobile, stringifyNum } from 'utils/commonhelpers'
import { getCurrency } from 'utils/countryHelpers'
import { getLoanHeaderValues } from 'constants/values/header-values/loanHeaderValues'
import { HeaderTooltip } from 'components/tooltips/header-tooltip/HeaderTooltip'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { IApplicationContext } from 'interfaces/i-application-context-props'

import '../loan-offer-header/LoanOfferHeader.scss'
import './LoanOfferHeaderTopUp.scss'

const LoanOfferHeaderTopUp = () => {
	const { t } = useTranslation()
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)

	return (
		<div className="loan-offer-header top-up">
			<h1 className="top-header">{t('LoanOffer')}</h1>
			<hr />
			<InfoBox infoType="info" message={t('LoanOfferText')} />

			<div className="loan-offer-box">
				<div className="loan-offer-box-calculation">
					<div className="box-addition">
						<div className="current-balance">
							<span>{t('CurrentBalance')}</span>
							<span>
								{stringifyNum(
									loanApplication.applicationData?.topUp
										?.balance!,
									{
										currency: getCurrency(),
										style: 'currency',
									}
								)}
							</span>
						</div>
						<div className="plus-sign">
							<span className="plus"></span>
						</div>
						<div className="approved-amount">
							<span>{t('AmountTransferred')}</span>
							<span>
								{stringifyNum(
									loanApplication.applicationData
										?.approvedAmount!,
									{
										currency: getCurrency(),
										style: 'currency',
									}
								)}
							</span>
						</div>
					</div>

					<div className="box-equal">
						<div className="equal-sign">
							<span className="equal"></span>
						</div>
						<div className="new-balance">
							<span>{t('TotalNewBalance')}</span>
							<span>
								{stringifyNum(
									loanApplication.applicationData?.topUp
										?.balance! +
										loanApplication.applicationData
											?.approvedAmount!,
									{
										currency: getCurrency(),
										style: 'currency',
									}
								)}
							</span>
						</div>
					</div>
				</div>
				<div className="loan-offer-box-installment">
					<span>{t('MonthlyInstallment')}</span>
					<span>
						{stringifyNum(
							loanApplication.applicationData?.instalment,
							{
								currency: getCurrency(),
								style: 'currency',
							}
						)}
					</span>
				</div>
			</div>
			<ReadMoreExpander
				expanded={!checkIsMobile()}
				showExpander={checkIsMobile()}
				children={
					<div className="loan-offer-table">
						<div className="loan-offer-item existing-loan">
							<div>{t('ExistingLoan')}</div>
							<div>
								{loanApplication.applicationData?.topUp?.number}
							</div>
						</div>
						{getLoanHeaderValues(
							t,
							loanApplication.applicationData
						).map((item, key) => {
							return (
								<div
									key={`loan-offer-item-${key}`}
									className="loan-offer-item">
									<div className="loan-offer-item-information">
										{t(item.text)}
										{item.tooltip ? (
											<HeaderTooltip
												translationString={
													item.tooltip.text
												}
												value={item.tooltip.value}
												secondValue={
													item.tooltip.secondValue
												}
											/>
										) : null}
									</div>
									<div className="loan-offer-item-value">
										{item.value}
									</div>
								</div>
							)
						})}
					</div>
				}
			/>
		</div>
	)
}

export default LoanOfferHeaderTopUp
