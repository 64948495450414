import React, { FunctionComponent, useEffect, useState } from 'react'
import { DateTime, Duration } from 'luxon'
import { getLanguageLocale } from './countryHelpers'

/**
 * We are using Luxon for dealing with dates and times.
 * It works as a wrapper for Intl internationalization library
 * For more information https://moment.github.io/luxon/index.html#/
 * For set of Presets eg. DATE_SHORT etc see https://moment.github.io/luxon/index.html#/formatting?id=presets
 * or https://moment.github.io/luxon/api-docs/index.html
 */

/**
 * Translate date in given format to iso date for system locale
 * @param date string to parse
 * @param expectedFormat Expected format of the string to parse
 * @returns
 */
export const formatFromToIsoDate = (date: string, expectedFormat: string) => {
	let dt = DateTime.fromFormat(date, expectedFormat, {
		locale: getLanguageLocale(),
	})
	return dt.isValid ? dt.toISODate() : ''
}

/**
 * Format date string to locale
 * @param date string to format
 * @returns
 */
export const formatToStringLocale = (date: string) => {
	let dt = DateTime.fromISO(date).setLocale(getLanguageLocale())
	return dt.isValid
		? dt.toLocaleString({
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
		  })
		: ''
}

/**
 * Countdown timer
 * @param isTimedOut true when reaches zero
 * @param format format of counter value ex. ("dd 'days' mm:ss 'minutes'"")
 * @param time (Default value 10 minutes)
 * @param time.days
 * @param time.hours
 * @param time.minutes
 * @param time.seconds
 * @param time.expiryDate will ignore all other time properties if provided
 * @returns Countdown element
 */
export const CountdownTimer: FunctionComponent<ICountdownTimer> = ({
	isTimedOut,
	format,
	time = { minutes: 10 },
}) => {
	let duration: Duration

	if (time.expiryDate) {
		const dur = new Date(time.expiryDate).getTime() - new Date().getTime()
		duration = Duration.fromMillis(dur)
	} else {
		duration = Duration.fromObject(time)
	}

	const [difference, setDifference] = useState(
		DateTime.now().plus(duration).diff(DateTime.now())
	)

	const timeIsUp = difference.get('seconds') < -duration.as('seconds')

	useEffect(() => {
		let id = setInterval(
			() => setDifference((state) => state.minus({ second: 1 })),
			1000
		)
		return () => clearInterval(id)
	}, [])

	useEffect(() => {
		if (timeIsUp) isTimedOut(true)
	})

	return <>{difference.toFormat(format)}</>
}

/**
 * Add leading zero to single digits in dates
 * @param date string to format
 * @returns
 */
export const addLeadingZeroToDates = (date: string) =>
	date.replace(/(^|\D)(\d)(?!\d)/g, '$10$2')

//Interfaces

/**
 * ICountdownTimer interface
 */
interface ICountdownTimer {
	isTimedOut: Function
	format: string
	time?: {
		year?: number
		month?: number
		days?: number
		hours?: number
		minutes?: number
		seconds?: number
		expiryDate?: Date
	}
}
