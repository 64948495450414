import React, { useContext } from 'react'

import { Decision } from 'constants/enums/decision'
import { DocumentTypes } from 'constants/enums/documentTypes'
import { LifeCycleStatus } from 'constants/enums/lifeCycleStatus'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { IMultistepComponent } from 'interfaces/i-multistep'
import {
	CheckHasApplicationDocuments,
	CheckIsLifeCycleStatus,
} from 'utils/loanHelpers'

import AddressAndAccommodation from 'components/application-blocks/create-application-blocks/AddressAndAccommodation'
import PersonalInformation from 'components/application-blocks/create-application-blocks/PersonalInformation'
import EmploymentAndIncome from 'components/application-blocks/create-application-blocks/EmploymentAndIncome'
import Expenses from 'components/application-blocks/create-application-blocks/Expenses'
import FinancialData from 'components/application-blocks/create-application-blocks/FinancialData'
import Multistep from 'components/multistep/Multistep'
import LoanOfferEs from 'components/application-blocks/application-offer-blocks/LoanOffer/LoanOfferEs'
import { SignPnEs } from 'components/application-blocks/application-offer-blocks/SignPn/SignPnEs/SignPnEs'
import IdentificationEs from 'components/application-blocks/application-offer-blocks/Identification/Identification'
import { SignPsd2Es } from 'components/application-blocks/application-offer-blocks/SignPsd2/SignPsd2Es'
import { UploadDocumentsEs } from 'components/application-blocks/application-offer-blocks/UploadDocuments/UploadDocumentsEs'
import ThankYou from 'components/application-blocks/common-blocks/ThankYou/ThankYou'
import PreContractualInfo from 'components/application-blocks/application-offer-blocks/PreContractualInfo/PreContractualInfo'

import { Trans, useTranslation } from 'react-i18next'
import { TranslationFile } from 'i18n'

export const CreditCardOfferStepsEs = () => {
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)

	const creditCardOfferSteps: IMultistepComponent[] = [
		{
			id: 1,
			header: '',
			body: <PreContractualInfo />,
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.CustomerVerification,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
		{
			id: 2,
			header: 'IdentificationHeader',
			body: <IdentificationEs />,
			show:
				CheckIsLifeCycleStatus(
					[
						LifeCycleStatus.CustomerVerification,
					],
					loanApplication.applicationData?.lifeCycleStatus!
				),
			stepCompleted: false,
		},
		{
			id: 3,
			header: 'SignContractHeader',
			body: <SignPnEs />,
			show:
				CheckIsLifeCycleStatus(
					[
						LifeCycleStatus.CustomerVerification,
						LifeCycleStatus.ReceiveSigning,
					],
					loanApplication.applicationData?.lifeCycleStatus!
				) &&
				!CheckHasApplicationDocuments(loanApplication.documentData!, [
					DocumentTypes.SignedPromissoryNote,
				]),
			stepCompleted: false,
		},
		{
			id: 4,
			header: 'UploadDocumentsHeaderCard',
			body: <UploadDocumentsEs />, 
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.CustomerVerification,
					LifeCycleStatus.ReceiveSigning,
					LifeCycleStatus.DocumentsUpload,
					LifeCycleStatus.InternalCheck,
					LifeCycleStatus.ExternalRegisterCheck,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
		{
			id: 5,
			header: 'ThankYouHeader',
			body: <ThankYou />,
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.CustomerVerification,
					LifeCycleStatus.ReceiveSigning,
					LifeCycleStatus.ExternalRegisterCheck,
					LifeCycleStatus.DocumentsUpload,
					LifeCycleStatus.InternalCheck,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
	]

	return <Multistep multisteps={creditCardOfferSteps} resetInitialState={true} />
}

const UpdateCardApplicationSteps = () => {
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)
	const applicationStatus = () =>
		loanApplication.applicationData?.decision === Decision.Approved ||
		loanApplication.applicationData?.decision === Decision.Investigation

	const updateApplicationSteps: IMultistepComponent[] = [		
		{
			id: 1,
			header: 'PSD2CC',
			body: <FinancialData />,
			show:
				CheckIsLifeCycleStatus(
					[
						LifeCycleStatus.ApplicationReceived,
						LifeCycleStatus.PrintPromissoryNote
					],
					loanApplication.applicationData?.lifeCycleStatus!
				) && applicationStatus(),
			stepCompleted: false,
		},
	]
	return (
		<div className="card-application-block">
			<Multistep
				multisteps={updateApplicationSteps}
				resetInitialState={false}
			/>
		</div>
	)
}

export const CardApplicationStepsEs = () => {
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)

	const { t } = useTranslation([
		TranslationFile.Translation,
		TranslationFile.Forms,
		TranslationFile.Dropdown,
	])

	return (
		<>
			{CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.ApplicationReceived,
					LifeCycleStatus.PrintPromissoryNote
				],
				loanApplication.applicationData?.lifeCycleStatus!
			) && <UpdateCardApplicationSteps />}
			{CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.CustomerVerification,
					LifeCycleStatus.ReceiveSigning,
					LifeCycleStatus.ExternalRegisterCheck,
					LifeCycleStatus.DocumentsUpload,
					LifeCycleStatus.InternalCheck,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			) && <CreditCardOfferStepsEs />}
			{CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.Denied
				],
				loanApplication.applicationData?.lifeCycleStatus!
			) && <div id="multistep">
					<div>
						<div className="d-flex flex-column multistep-container">
							<div className="multistep-content">
								<h2 className="multistep-header">
									{<Trans t={t} i18nKey="StatusTextDeniedCCHeader"></Trans>}
								</h2>
							</div>
							<hr />
							<div className="multistep-content">{<Trans t={t} i18nKey="StatusTextDeniedCC"></Trans>}</div>
						</div>
					</div>
				</div>
			}
			</>
	)
}

const LoanOfferSteps = () => {
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)

	const loanOfferSteps: IMultistepComponent[] = [
		{
			id: 1,
			header: 'LoanOfferHeader',
			body: <LoanOfferEs />,
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.MultiStepUpdateLoanData,
					LifeCycleStatus.CreditDecisionError,
					LifeCycleStatus.CreditDecisionInvestigation,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
		{
			id: 2,
			header: 'SignContractHeader',
			body: <SignPnEs />,
			show:
				CheckIsLifeCycleStatus(
					[
						LifeCycleStatus.MultiStepUpdateLoanData,
						LifeCycleStatus.ReceiveSigning,
					],
					loanApplication.applicationData?.lifeCycleStatus!
				) &&
				!CheckHasApplicationDocuments(loanApplication.documentData!, [
					DocumentTypes.SignedPromissoryNote,
				]),
			stepCompleted: false,
		},
		{
			id: 3,
			header: 'IdentificationHeader',
			body: <IdentificationEs />,
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.MultiStepUpdateLoanData,
					LifeCycleStatus.ReceiveSigning,
					LifeCycleStatus.CustomerVerification,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
		{
			id: 4,
			header: 'Psd2Header',
			body: <SignPsd2Es />,
			// show: CheckIsLifeCycleStatus(
			// 	[
			// 		LifeCycleStatus.MultiStepUpdateLoanData,
			// 		LifeCycleStatus.ReceiveSigning,
			// 	],
			// 	loanApplication.applicationData?.lifeCycleStatus!
			// ),
			show: false,
			stepCompleted: true,
		},
		{
			id: 5,
			header: 'UploadDocumentsHeader',
			body: <UploadDocumentsEs />,
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.MultiStepUpdateLoanData,
					LifeCycleStatus.ReceiveSigning,
					LifeCycleStatus.DocumentsUpload,
					LifeCycleStatus.InternalCheck,
					LifeCycleStatus.ExternalRegisterCheck,
					LifeCycleStatus.CustomerVerification,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
		{
			id: 6,
			header: 'ThankYouHeader',
			body: <ThankYou />,
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.MultiStepUpdateLoanData,
					LifeCycleStatus.ReceiveSigning,
					LifeCycleStatus.DocumentsUpload,
					LifeCycleStatus.InternalCheck,
					LifeCycleStatus.ExternalRegisterCheck,
					LifeCycleStatus.CustomerVerification,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
	]

	return <Multistep multisteps={loanOfferSteps} resetInitialState={true} />
}

const UpdateApplicationSteps = () => {
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)
	const applicationStatus = () =>
		loanApplication.applicationData?.decision === Decision.Approved ||
		loanApplication.applicationData?.decision === Decision.Investigation

	const preliminaryScoreDone = () =>
		loanApplication.applicationData?.offer?.maxInstallment !== undefined &&
		loanApplication.applicationData?.offer?.maxInstallment > 0

	const updateApplicationSteps: IMultistepComponent[] = [
		{
			id: 1,
			header: 'PersonalInformationHeader',
			body: <PersonalInformation />,
			show:
				CheckIsLifeCycleStatus(
					[LifeCycleStatus.ApplicationReceived],
					loanApplication.applicationData?.lifeCycleStatus!
				) && applicationStatus(),
			stepCompleted: false,
		},
		{
			id: 2,
			header: 'AddressAndAccommodationHeader',
			body: <AddressAndAccommodation />,
			show:
				CheckIsLifeCycleStatus(
					[LifeCycleStatus.ApplicationReceived],
					loanApplication.applicationData?.lifeCycleStatus!
				) && applicationStatus(),
			stepCompleted: false,
		},
		{
			id: 3,
			header: 'EmploymentAndIncomeHeader',
			body: <EmploymentAndIncome />,
			show: CheckIsLifeCycleStatus(
				[LifeCycleStatus.ApplicationReceived],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
		{
			id: 4,
			header: applicationStatus()
				? 'ExpensesHeader'
				: 'ApplicationNoticeHeader',
			body: <Expenses />,
			show: CheckIsLifeCycleStatus(
				[LifeCycleStatus.ApplicationReceived],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
		{
			id: 5,
			header: 'PSD2',
			body: <FinancialData />,
			show:
				CheckIsLifeCycleStatus(
					[LifeCycleStatus.ApplicationReceived],
					loanApplication.applicationData?.lifeCycleStatus!
				) && applicationStatus(),
			stepCompleted: false,
		},
	]
	return (
		<div className="loan-application-block">
			<Multistep
				multisteps={updateApplicationSteps}
				hidePreviousButton={preliminaryScoreDone()}
				resetInitialState={false}
			/>
		</div>
	)
}

export const LoanApplicationStepsEs = () => {
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)

	return (
		<>
			{CheckIsLifeCycleStatus(
				[LifeCycleStatus.ApplicationReceived],
				loanApplication.applicationData?.lifeCycleStatus!
			) && <UpdateApplicationSteps />}
			{CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.CreditDecisionInvestigation,
					LifeCycleStatus.CreditDecisionError,
					LifeCycleStatus.PrintPromissoryNote,
					LifeCycleStatus.ManualSendPromissoryNote,
					LifeCycleStatus.ReceiveSigning,
					LifeCycleStatus.DocumentsUpload,
					LifeCycleStatus.InternalCheck,
					LifeCycleStatus.FinalCreditApproval,
					LifeCycleStatus.AccountNumberVerificationFailed,
					LifeCycleStatus.MultiStepUpdateLoanData,
					LifeCycleStatus.ExternalRegisterCheck,
					LifeCycleStatus.CustomerVerification,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			) && <LoanOfferSteps />}
		</>
	)
}
