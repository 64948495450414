import Axios from 'axios'
import { setUpApplicationInterceptorsFor } from './interceptors'
import { cdaApiRoutes } from 'constants/routes/cdaApiRoutes'
import { getHeadersWithJwtToken } from 'utils/authHelpers'
import { IUpdateLoanInfo } from 'interfaces/i-confirm-loan-offer'
import { TFunction } from 'i18next'

const transportWithCredentials = setUpApplicationInterceptorsFor(
	Axios.create({})
)

transportWithCredentials.defaults.headers.post['Content-Type'] =
	'application/json'
transportWithCredentials.defaults.headers.post['Access-Control-Allow-Origin'] =
	'*'
transportWithCredentials.defaults.headers.post['Access-Control-Allow-Headers'] =
	'Origin, X-Requested-With, Content-Type, Accept'

const creditDecisionServices = {
	async runPreliminaryCreditDecision(
		applicationId: string,
		cdaApiRoute: string
	) {
		const data = await transportWithCredentials.post(
			cdaApiRoute,
			{
				applicationId: applicationId,
			},
			await getHeadersWithJwtToken()
		)
		if (data.status === 200) {
			return data
		}
		throw new Error(
			'Something went wrong running preliminary credit decision.'
		)
	},

	async runcreditdecision(applicationId: string, cdaApiRoute: string) {
		const data = await transportWithCredentials.post(
			cdaApiRoute,
			{
				applicationId: applicationId,
			},
			await getHeadersWithJwtToken()
		)
		if (data.status === 200) {
			return data
		}
		throw new Error(
			'Something went wrong updating running credit decision.'
		)
	},

	async updateLoanData(
		applicationId: number,
		loanInfo: IUpdateLoanInfo,
		t: TFunction
	) {
		const data = await transportWithCredentials.post(
			cdaApiRoutes.updateLoanData,
			{
				applicationId: applicationId,
				loanInfo: loanInfo,
			},
			await getHeadersWithJwtToken()
		)
		if (data.status === 200) {
			return data
		}
		throw new Error(t('ErrorMsg'))
	},
}

export default creditDecisionServices
