import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { Image } from 'react-bootstrap'

import TF_logo from 'img/tf-logos/TF_logo_cropped.png'

import 'common/styling/modal-styling.scss'
import './IdleTimeOutModal.scss'

interface IIdleTimeOut {
	showModal: boolean
	handleClose: any
	handleLogout: any
	remainingTime: number
}

interface ITime {
	seconds: number
	minutes: number
}

export const IdleTimeOutModal = ({
	showModal,
	handleClose,
	handleLogout,
	remainingTime,
}: IIdleTimeOut) => {
	const { t } = useTranslation()
	const [time, setTime] = React.useState<ITime>({
		seconds: remainingTime,
		minutes: 0,
	})

	useEffect(() => {
		const interval = setInterval(() => {
			if (showModal) {
				if (time.seconds % 2) {
					document.title = t('IdleTimeout')
				} else {
					document.title = t('Title')
				}
				if (time.seconds > 0) {
					setTime((prev) => ({
						...prev,
						seconds: time.seconds - 1,
					}))
				} else {
					handleLogout()
				}
			}
		}, 1000)
		return () => clearInterval(interval)
	})

	function clearCounterAndHandleClose() {
		setTime((prev) => ({
			...prev,
			seconds: remainingTime,
		}))
		document.title = t('Title')
		handleClose()
	}

	return (
		<Modal
			show={showModal}
			onHide={clearCounterAndHandleClose}
			animation={false}
			centered
			className="idletimeout-modal"
			backdrop="static">
			<Modal.Header closeButton>
				<div>
					<Image src={TF_logo} className="logo" alt="logo" />
				</div>
			</Modal.Header>
			<Modal.Body>
				<div>{t('LogoutWarning', { time })}</div>
				<div className="loading-container">
					<div className="loading"></div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					type="button"
					className="btn btn-outline-darkgray"
					onClick={handleLogout}>
					{t('Logout')}
				</button>
				<button
					type="button"
					className="btn btn-primary"
					onClick={clearCounterAndHandleClose}>
					{t('Reactivate')}
				</button>
			</Modal.Footer>
		</Modal>
	)
}
