import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import appConfig from './app.config'
import { Locale } from 'constants/enums/countryTypes'

const supportedLngs = WhiteListedLngs()

i18n
	// load translation using xhr -> see /public/locales
	// learn more: https://github.com/i18next/i18next-xhr-backend
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		supportedLngs: supportedLngs,
		fallbackLng: false,
		lng: localStorage.getItem('i18nextLng') ?? supportedLngs[0] ?? 'en',
		debug: process.env.NODE_ENV === 'development',
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		detection: {
			order: [
				'localStorage',
				'htmlTag',
				'navigator',
				'path',
				'subdomain',
				'querystring',
				'cookie',
			],
		},
		backend: {
			requestOptions: {
				cache: 'no-store',
			},
		},
	})

i18n.on('languageChanged', (lng) => {
	const mainLng = getMainLng(lng)
	document.documentElement.lang = getMainLng(
		Locale[mainLng as keyof typeof Locale]
	)
})

export default i18n

/*
	Extract the main language from the countrycode, (i.e 'en' from 'en-US')
 */
export function getMainLng(lng: string) {
	if (lng === undefined) return lng

	return lng.split('-')[0]
}

function WhiteListedLngs() {
	const lngs = Object.keys(appConfig.languages)
	const variantLngs = lngs.filter((x) => x.includes('-'))

	variantLngs.forEach((x) => {
		const mainLng = getMainLng(x)

		if (!lngs.includes(mainLng)) lngs.push(mainLng)
	})

	lngs.push('common')
	return lngs
}

export enum TranslationFile {
	Dropdown = 'dropdown',
	Common = 'common',
	Forms = 'forms',
	Translation = 'translation',
	Countries = 'countries',
	Languages = 'languages',
}
