import React, { useContext, useEffect } from 'react'

import appConfig from 'app.config'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { useExternalScript } from 'hooks/useScript'
import { afterbanksSettings } from 'constants/values/afterbanksSettings'

const Psd2iFrame = ({ showPsd2 }: { showPsd2: boolean }) => {
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const applicationId = loanApplication.applicationData?.loanNumber.toString()
	const useSandbox = appConfig.environment === 'production' ? 0 : 1
	const displayStyle = showPsd2 ? 'block' : 'none'

	// init parameters
	afterbanksSettings.init(applicationId!, useSandbox)

	//add event listener with listener function
	afterbanksSettings.eventListener(afterbanksSettings.function)

	// load base script
	/* eslint-disable-next-line */
	const { library, isLoaded } = useExternalScript(
		afterbanksSettings.baseScript,
		'AfterBanks'
	)

	const psd2Status = sessionStorage.getItem('psd2Status')

	useEffect(() => {
		console.info('Parameters added: ', window.parameters)
		if (psd2Status === '200') {
			dispatchLoanApplication({
				type: LOAN_APPLICATION_ACTIONS.SET_UPDATE_PSD2,
				payload: {
					...loanApplication,
					hasSignedPsd2: true,
				},
			})
		}
	}, [])

	return (
		<>
			{/* Messages to user. For testing purpose*/}
			{/* <div id="frameResponse"></div> */}
			{/* Iframe container */}
			<div id="arcopayContainer" style={{ display: displayStyle }} />
		</>
	)
}

export default Psd2iFrame
