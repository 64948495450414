export enum CountryCode {
	ee = 'EST',
	se = 'SWE',
	fi = 'FIN',
	no = 'NOR',
	pl = 'POL',
	lt = 'LTU',
	lv = 'LVA',
	at = 'AUT',
	de = 'GER',
	dk = 'DNK',
	es = 'ESP',
}

export enum CountryTypes {
	de = 'de',
	ee = 'ee',
	en = 'en',
	se = 'se',
	fi = 'fi',
	no = 'no',
	at = 'at',
	dk = 'dk',
	es = 'es',
}

export enum Locale {
	ee = 'et-EE',
	se = 'sv-SE',
	en = 'en-GB',
	lt = 'lt-LT',
	lv = 'lv-LV',
	ru = 'ru-RU',
	fi = 'fi-FI',
	dk = 'da-DK',
	es = 'es-ES',
}

export enum NationalityType {
	Unknown = 'Unknown',
	German = 'German',
	EU = 'EU',
	NonEU = 'NonEU',
}

export enum CountryA3 {
	de = 'DEU',
	at = 'AUT',
	fi = 'FIN',
	ee = 'EST',
	se = 'SWE',
	no = 'NOR',
	pl = 'POL',
	lt = 'LTU',
	lv = 'LVA',
	dk = 'DNK',
	es = 'ESP',
}

export enum AllCountries {
	AD = 'Andorra',
	AE = 'UnitedArabEmirates',
	AF = 'Afghanistan',
	AG = 'AntiguaAndBarbuda',
	AI = 'Anguilla',
	AL = 'Albania',
	AM = 'Armenia',
	AN = 'NetherlandsAntilles',
	AO = 'Angola',
	AQ = 'Antarctica',
	AR = 'Argentina',
	AS = 'AmericanSamoa',
	AT = 'Austria',
	AU = 'Australia',
	AW = 'Aruba',
	AZ = 'Azerbaijan',
	BA = 'BosniaAndHerzegovina',
	BB = 'Barbados',
	BD = 'Bangladesh',
	BE = 'Belgium',
	BF = 'BurkinaFaso',
	BG = 'Bulgaria',
	BH = 'Bahrain',
	BI = 'Burundi',
	BJ = 'Benin',
	BM = 'Bermuda',
	BN = 'BruneiDarussalam',
	BO = 'Bolivia',
	BR = 'Brazil',
	BS = 'Bahamas',
	BT = 'Bhutan',
	BV = 'BouvetIsland',
	BW = 'Botswana',
	BY = 'Belarus',
	BZ = 'Belize',
	CA = 'Canada',
	CC = 'CocosKeelingIslands',
	CF = 'CentralAfricanRepublic',
	CG = 'Congo',
	CH = 'Switzerland',
	CI = 'IvoryCoast',
	CK = 'CookIslands',
	CL = 'Chile',
	CM = 'Cameroon',
	CN = 'China',
	CO = 'Colombia',
	CR = 'CostaRica',
	CU = 'Cuba',
	CV = 'CapeVerde',
	CX = 'ChristmasIsland',
	CY = 'Cyprus',
	CZ = 'CzechRepublic',
	DE = 'Germany',
	DJ = 'Djibouti',
	DK = 'Denmark',
	DM = 'Dominica',
	DO = 'DominicanRepublic',
	DZ = 'Algeria',
	EC = 'Ecuador',
	EE = 'Estonia',
	EG = 'Egypt',
	EH = 'WesternSahara',
	ER = 'Eritrea',
	ES = 'Spain',
	ET = 'Ethiopia',
	FI = 'Finland',
	FJ = 'Fiji',
	FK = 'FalklandIslands',
	FM = 'Micronesia',
	FO = 'FaroeIslands',
	FR = 'France',
	GA = 'Gabon',
	GB = 'UnitedKingdom',
	GD = 'Grenada',
	GE = 'Georgia',
	GF = 'FrenchGuiana',
	GH = 'Ghana',
	GI = 'Gibraltar',
	GL = 'Greenland',
	GM = 'Gambia',
	GN = 'Guinea',
	GP = 'Guadeloupe',
	GQ = 'EquatorialGuinea',
	GR = 'Greece',
	GS = 'SouthGeorgiaAndSandwichIsl',
	GT = 'Guatemala',
	GU = 'Guam',
	GW = 'GuineaBissau',
	GY = 'Guyana',
	HK = 'HongKong',
	HM = 'HeardIslandMcdonaldIslands',
	HN = 'Honduras',
	HR = 'Croatia',
	HT = 'Haiti',
	HU = 'Hungary',
	ID = 'Indonesia',
	IE = 'Ireland',
	IL = 'Israel',
	IN = 'India',
	IO = 'BritishIndianOceanTerritory',
	IQ = 'Iraq',
	IR = 'Iran',
	IS = 'Iceland',
	IT = 'Italy',
	JM = 'Jamaica',
	JO = 'Jordan',
	JP = 'Japan',
	KE = 'Kenya',
	KG = 'Kyrgyzstan',
	KH = 'Cambodia',
	KI = 'Kiribati',
	KM = 'Comoros',
	KN = 'SaintKittsAndNevis',
	KW = 'Kuwait',
	KY = 'CaymanIslands',
	KZ = 'Kazakhstan',
	LA = 'LaoPeoplesDemocraticRepublic',
	LB = 'Lebanon',
	LC = 'SaintLucia',
	LI = 'Liechtenstein',
	LK = 'SriLanka',
	LR = 'Liberia',
	LS = 'Lesotho',
	LT = 'Lithuania',
	LU = 'Luxembourg',
	LV = 'Latvia',
	LY = 'LibyanArabJamahiriya',
	MA = 'Morocco',
	MC = 'Monaco',
	MD = 'Moldova',
	ME = 'Montenegro',
	MG = 'Madagascar',
	MH = 'MarshallIslands',
	MK = 'Macedonia',
	ML = 'Mali',
	MM = 'Myanmar',
	MN = 'Mongolia',
	MO = 'Macao',
	MP = 'NorthernMarianaIslands',
	MQ = 'Martinique',
	MR = 'Mauritania',
	MS = 'Montserrat',
	MT = 'Malta',
	MU = 'Mauritius',
	MV = 'Maldives',
	MW = 'Malawi',
	MX = 'Mexico',
	MY = 'Malaysia',
	MZ = 'Mozambique',
	NA = 'Namibia',
	NC = 'NewCaledonia',
	NE = 'Niger',
	NF = 'NorfolkIsland',
	NG = 'Nigeria',
	NI = 'Nicaragua',
	NL = 'Netherlands',
	NO = 'Norway',
	NP = 'Nepal',
	NR = 'Nauru',
	NU = 'Niue',
	NZ = 'NewZealand',
	OM = 'Oman',
	PA = 'Panama',
	PE = 'Peru',
	PF = 'FrenchPolynesia',
	PG = 'PapuaNewGuinea',
	PH = 'Philippines',
	PK = 'Pakistan',
	PL = 'Poland',
	PM = 'SaintPierreAndMiquelon',
	PN = 'Pitcairn',
	PR = 'PuertoRico',
	PS = 'PalestinianTerritory',
	PT = 'Portugal',
	PW = 'Palau',
	PY = 'Paraguay',
	QA = 'Qatar',
	RE = 'Reunion',
	RO = 'Romania',
	RS = 'Serbia',
	RU = 'RussianFederation',
	RW = 'Rwanda',
	SA = 'SaudiArabia',
	SB = 'SolomonIslands',
	SC = 'Seychelles',
	SD = 'Sudan',
	SE = 'Sweden',
	SG = 'Singapore',
	SH = 'SaintHelena',
	SI = 'Slovenia',
	SJ = 'SvalbardAndJanMayen',
	SK = 'Slovakia',
	SL = 'SierraLeone',
	SM = 'SanMarino',
	SN = 'Senegal',
	SO = 'Somalia',
	SR = 'Suriname',
	ST = 'SaoTomeAndPrincipe',
	SV = 'ElSalvador',
	SY = 'SyrianArabRepublic',
	SZ = 'Swaziland',
	TC = 'TurksAndCaicosIslands',
	TD = 'Chad',
	TF = 'FrenchSouthernTerritories',
	TG = 'Togo',
	TH = 'Thailand',
	TJ = 'Tajikistan',
	TK = 'Tokelau',
	TM = 'Turkmenistan',
	TN = 'Tunisia',
	TO = 'Tonga',
	TR = 'Turkey',
	TT = 'TrinidadAndTobago',
	TV = 'Tuvalu',
	TW = 'Taiwan',
	TZ = 'Tanzania',
	UA = 'Ukraine',
	UG = 'Uganda',
	UM = 'UnitedStatesOutlyingIslands',
	US = 'UnitedStates',
	UY = 'Uruguay',
	UZ = 'Uzbekistan',
	VA = 'HolySeeVaticanCityState',
	VC = 'SaintVincentAndGrenadines',
	VE = 'Venezuela',
	VG = 'VirginIslandsBritish',
	VI = 'VirginIslandsUS',
	VN = 'Vietnam',
	VU = 'Vanuatu',
	WF = 'WallisAndFutuna',
	WS = 'Samoa',
	YE = 'Yemen',
	YT = 'Mayotte',
	ZA = 'SouthAfrica',
	ZM = 'Zambia',
	ZW = 'Zimbabwe',
}

export enum CustomerCountry {
	PL,
	EE,
	AF,
	AL,
	DZ,
	AS,
	AD,
	AO,
	AI,
	AQ,
	AG,
	AR,
	AM,
	AW,
	AU,
	AT,
	AZ,
	BS,
	BH,
	BD,
	BB,
	BY,
	BE,
	BZ,
	BJ,
	BM,
	BT,
	BO,
	BA,
	BW,
	BV,
	BR,
	IO,
	BN,
	BG,
	BF,
	BI,
	KH,
	CM,
	CA,
	CV,
	KY,
	CF,
	TD,
	CL,
	CN,
	CX,
	CC,
	CO,
	KM,
	CG,
	CK,
	CR,
	CI,
	HR,
	CU,
	CY,
	CZ,
	DK,
	DJ,
	DM,
	DO,
	TP,
	EC,
	EG,
	SV,
	GQ,
	ER,
	ET,
	FK,
	FO,
	FJ,
	FI,
	FR,
	GF,
	PF,
	TF,
	GA,
	GM,
	GE,
	DE,
	GH,
	GI,
	GR,
	GL,
	GD,
	GP,
	GU,
	GT,
	GN,
	GW,
	GY,
	HT,
	HM,
	VA,
	HN,
	HK,
	HU,
	IS,
	IN,
	ID,
	IR,
	IQ,
	IE,
	IL,
	IT,
	JM,
	JP,
	JO,
	KZ,
	KE,
	KI,
	KP,
	KR,
	KW,
	KG,
	LA,
	LV,
	LB,
	LS,
	LR,
	LY,
	LI,
	LT,
	LU,
	MO,
	MK,
	MG,
	MW,
	MY,
	MV,
	ML,
	MT,
	MH,
	MQ,
	MR,
	MU,
	YT,
	MX,
	FM,
	MD,
	MC,
	MN,
	MS,
	MA,
	MZ,
	MM,
	NA,
	NR,
	NP,
	NL,
	AN,
	NC,
	NZ,
	NI,
	NE,
	NG,
	NU,
	NF,
	MP,
	NO,
	OM,
	PK,
	PW,
	PA,
	PG,
	PY,
	PE,
	PH,
	PN,
	PT,
	PR,
	QA,
	RE,
	RO,
	RU,
	RW,
	KN,
	LC,
	VC,
	WS,
	SM,
	ST,
	SA,
	SN,
	SC,
	SL,
	SG,
	SK,
	SI,
	SB,
	SO,
	ZA,
	GS,
	ES,
	LK,
	SH,
	PM,
	SD,
	SR,
	SJ,
	SZ,
	SE,
	CH,
	SY,
	TW,
	TJ,
	TZ,
	TH,
	TG,
	TK,
	TO,
	TT,
	TN,
	TR,
	TM,
	TC,
	TV,
	UG,
	UA,
	AE,
	GB,
	US,
	UM,
	UY,
	UZ,
	VU,
	VE,
	VN,
	VG,
	VI,
	WF,
	EH,
	YE,
	ZR,
	ZM,
	ZW,
}
