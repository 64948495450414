import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { UploadDocumentsDropZoneItems } from 'components/application-blocks/common-blocks/UploadDocuments/UploadDocumentsDropZoneItems'
import { DocumentTypesDenmark } from 'constants/values/uploadDocumentsTypes'

import './UploadDocuments.scss'

export const UploadDocumentsDk = () => {
	const { t } = useTranslation()
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)

	return (
		<div className="upload-documents denmark">
			<div className="upload-documents-with-psd2">
				<div className="upload-documents-description-top"></div>
				<div className="upload-documents-information">
					{t('UploadDocumentText')}
				</div>
			</div>
			<UploadDocumentsDropZoneItems
				documentTypes={DocumentTypesDenmark}
				applicationData={loanApplication}
				documentsNeedUpload={[]}
			/>
		</div>
	)
}
