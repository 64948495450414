import { CookieType } from 'constants/enums/cookieTypes'
import { DocumentTypes } from 'constants/enums/documentTypes'
import { LifeCycleStatus } from 'constants/enums/lifeCycleStatus'
import { ProductType } from 'constants/enums/productTypes'
import { UrlStatus } from 'constants/enums/signingUrlTypes'
import { TFunction } from 'i18next'
import { IApplicationInformation } from 'interfaces/i-application-information'
import { IDocuments } from 'interfaces/i-documents'
import { IApplicationUrlsDto } from 'interfaces/i-signing-urls'
import queryString from 'query-string'
import authenticationService from 'services/authServices'

/**
 * Calculates loan values based on params from loan application
 * @param loanAmount
 * @param periodInMonths
 * @param apr
 * @param interestRate
 * @returns object with values loanAmount, periodInMonths, instalment, totalAmount, totalCost
 */
export function CalculateLoanExample(
	loanAmount: number,
	periodInMonths: number,
	apr: number, //APR or effective interest rate (TAE)
	interestRate: number //Interest rate (TIN)
) {
	let rate = interestRate

	//Monthly installment
	let repayment = calculateInstallment(loanAmount, periodInMonths, rate)
	let instalment = roundToDecimalUpwards(repayment, 0)

	//Total amount
	let totalAmount = calculateTotalAmount(loanAmount, periodInMonths, rate)
	let totalCost = totalAmount - loanAmount

	return {
		loanAmount: loanAmount,
		periodInMonths: periodInMonths,
		instalment: instalment,
		totalAmount: totalAmount,
		totalCost: totalCost,
	}
}

function calculateInstallment(
	loanAmount: number, //Loan Amount
	period: number, //Period
	rate: number //Interest rate
) {
	let installment = PMT(rate / 12, period, loanAmount, 0, 0)
	return -installment
}

function PMT(
	rate: number,
	nper: number,
	pv: number,
	fv: number = 0,
	type: number = 0
) {
	if (rate === 0) return -(pv + fv) / nper

	const pvif = Math.pow(1 + rate, nper)
	let pmt = (rate / (pvif - 1)) * -(pv * pvif + fv)

	if (type === 1) {
		pmt /= 1 + rate
	}

	return pmt
}

function calculateTotalAmount(
	loanAmount: number, //Loan Amount
	period: number, //Period
	rate: number //Interest rate
) {
	let sum = 0
	for (var i = 1; i <= period; i++) {
		//Period in months
		sum += calculateInstallment(loanAmount, period, rate) //Loan amount, period in months, rate
	}
	const totalAmount = Math.ceil(sum)

	return totalAmount
}

//Rounding
// function roundToDecimal(number: number, decimals: number) {
// 	const multiplier = Math.pow(10, decimals)
// 	return Math.round(number * multiplier) / multiplier
// }

function roundToDecimalUpwards(number: number, decimals: number) {
	const multiplier = Math.pow(10, decimals)
	return Math.ceil(number * multiplier) / multiplier
}

/**
 * Checks if application is valid on LifeCycleStatus
 * @param status LifeCycleStatus from application
 * @returns boolean
 */
export const CheckIsValidApplication = (
	status: LifeCycleStatus | undefined,
	product: ProductType | undefined
) => {
	switch (status) {
		case LifeCycleStatus.Canceled:
		case LifeCycleStatus.Disbursed:
		case LifeCycleStatus.TimedOut:
		case LifeCycleStatus.Duplicated:
		case LifeCycleStatus.Invalid:
			return false
		case LifeCycleStatus.Denied:
			return product === ProductType.EspCreditCard
		default:
			return true
	}
}

/**
 * Function to check if application lifecycle status matches any of the types in list
 * @param showOnStatus List of Lifecyclestatuses
 * @param applicationStatus LifecycleStatus which application is currently in
 * @returns true if application LifecycleStatus matches any of the listed showOnStatuses
 */
export const CheckIsLifeCycleStatus = (
	showOnStatus: LifeCycleStatus[],
	applicationStatus: LifeCycleStatus
) => {
	return showOnStatus.includes(applicationStatus)
}

/**
 * Function to check if document collection contains a set of specific document types
 * @param documents document collection in application
 * @param documentTypes list of document types to check for
 * @returns true if ALL documentType exists in document collection
 */
export const CheckHasApplicationDocuments = (
	documents: IDocuments[],
	documentTypes: DocumentTypes[]
) => {
	let documentArr: DocumentTypes[] = []
	documents.forEach((doc) => {
		documentArr.push(doc.type)
	})
	return documentTypes.every((element) => documentArr.includes(element))
}

/**
 * Function that returns application query from sessionstorage
 * If application query is null return quesry value from url
 * @returns query string
 */
export const getQueryValues = () => {
	return queryString.parse(
		sessionStorage.getItem(CookieType.ApplicationQuery) ??
			window.location.search.toLowerCase()
	)
}

/**
 * Checks if promissory note status is signed in application
 * OR query value in url has value 'success'
 * @param applicationData
 * @returns boolean
 */
export const isPnStatusSigned = (applicationData: IApplicationInformation) =>
	applicationData?.signingUrls?.pnSigning?.status! === UrlStatus.Signed ||
	getQueryValues().status === 'success'

/**
 * Checks if psd2 status is signed in application
 * OR query value in url has value 'success'
 * @param applicationData
 * @returns boolean
 */
export const isPsd2StatusSigned = (applicationData: IApplicationInformation) =>
	applicationData?.signingUrls?.psD2?.status! === UrlStatus.Signed ||
	getQueryValues().psd2status === 'success'

/**
 * Checks if psd2 signing is mandatory in application
 * @param applicationData
 * @returns boolean
 */
export const isPsd2Mandatory = (applicationData: IApplicationInformation) =>
	applicationData?.signingUrls?.psD2?.isMandatory!

/**
 * Checks if ppi status is signed in application
 * OR query value in url has value 'success'
 * Used in Finnish application
 * @param applicationData
 * @returns boolean
 */
export const isPpiStatusSigned = (applicationData: IApplicationInformation) =>
	applicationData?.signingUrls?.ppiSigning?.status! === UrlStatus.Signed ||
	getQueryValues().ppistatus === 'success'

/**
 * Returns true if product type is one of the new Finish ones supporting base and margin rates.
 * Only used for Finland (as of end of october 2023)
 * @param applicationData
 * @returns
 */
export function showBaseAndMarginRatesFinland(
	applicationData: IApplicationInformation | undefined
) {
	let isNewProduct = false
	const baseAndMarginProducts = [
		ProductType.FinCashLoan,
		ProductType.FinHalensLoan,
		ProductType.FinRefinLoan,
		ProductType.FinHalensLoanPpi,
		ProductType.FinCashLoanPpi,
		ProductType.FinRefinLoanPpi,
	]
	if (applicationData)
		isNewProduct = baseAndMarginProducts.includes(applicationData.product)

	return isNewProduct
}

/**
 * Updates signing urls for promissory notes with artifact.
 * @param oldUrl
 * @param t
 * @returns url with artifact attached
 */
export const updateSigningUrlsWithArtifact = async (
	oldUrl: string,
	t: TFunction
) => {
	try {
		let queryValues = queryString.parse(queryString.extract(oldUrl))
		let newArtifact: string = await authenticationService.getArtifact(
			queryValues.request_id as string,
			queryValues.task_id as string,
			t
		)
		if (queryValues.artifact) {
			let url = oldUrl.replace(
				queryValues.artifact as string,
				newArtifact
			)
			return url
		}
		return `${oldUrl}&artifact=${newArtifact}`
	} catch (error) {
		//setError(getErrorWithFullMessage(error))
		//redirect to oldUrl for signing in if no artifact
		return oldUrl
	}
}

/**
 * Gets url for signing promissory notes with attached artifact.
 * Artifact enables signing directly and not needing to sign in with Signicat again.
 * @param applicationData
 * @param signingUrls
 * @param t
 */
export const onPnSignClick = (
	applicationData: IApplicationInformation,
	signingUrls: IApplicationUrlsDto,
	t: TFunction
) => {
	const url = applicationData.isCoApplicant
		? signingUrls.coapplicantSigning!
		: signingUrls.applicantSigning!

	updateSigningUrlsWithArtifact(url, t)
		.then((data) => {
			if (data) {
				window.location.href = data
			}
		})
		//don´t show button
		.catch()
}

/**
 * Gets url for signing ppi with attached artifact.
 * Artifact enables signing directly and not needing to sign in with Signicat again.
 * @param signingUrls
 * @param t
 */
export const onPpiSignClick = (
	signingUrls: IApplicationUrlsDto,
	t: TFunction
) => {
	updateSigningUrlsWithArtifact(signingUrls?.ppiContract!, t)
		.then((data) => {
			if (data) {
				window.location.href = data
			}
		})
		//don´t show button
		.catch()
}

/**
 * Redirect function where user is redirected to url.
 * @param url
 */
export const onClickRedirectToUrl = (url: string) => {
	window.location.href = url
}
