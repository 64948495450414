import React, { useEffect } from 'react'
import { Container, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { FooterLoggedOut } from 'components/footer-blocks/footer-logged-out/FooterLoggedOut'
import { logError } from 'appinsights/appinsights'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

import tfbank21_logo_white from 'img/tf-logos/tfbank21-logo-white.svg'
import './ErrorFallbackPage.scss'

interface IErrorFallbackProps {
	resetErrorBoundary?: (...args: Array<unknown>) => void
	error: Error
}

const ErrorFallbackPage = ({
	resetErrorBoundary,
	error,
}: IErrorFallbackProps) => {
	const { t } = useTranslation()

	useEffect(() => {
		logError(error)
	}, [error])

	const ResetButton = () => {
		return (
			<button
				type="button"
				className="btn btn-outline-danger"
				onClick={resetErrorBoundary}>
				{'TryAgain'}
			</button>
		)
	}

	return (
		<div id="error-fallback-page" className="error-fallback-container">
			<div className="background-img">
				<div className="logout-container">
					<Image
						src={tfbank21_logo_white}
						className="tfbank-logo logout"
						alt="TF Bank AB"
					/>
					<Container className="logout-body">
						<ErrorBox
							message={t('ErrorOnRender')}
							button={<ResetButton />}
						/>
					</Container>
					<FooterLoggedOut />
				</div>
			</div>
		</div>
	)
}

export default ErrorFallbackPage
