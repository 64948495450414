import appConfig from '../../app.config'
import { SigningUrlType } from 'constants/enums/signingUrlTypes'
import { DocumentTypes } from 'constants/enums/documentTypes'
export const cdaApiRoutes = {
    getApplicationInformation: (applicationId: number) =>
        `${appConfig.customerDataBaseUrl}/api/ApplicationPage/applicationinformation?applicationId=${applicationId}`,
    getSigningUrl: (applicationId: number, signingUrl: SigningUrlType) =>
        `${appConfig.customerDataBaseUrl}/api/ApplicationPage/signingurls?applicationId=${applicationId}&signingurltype=${signingUrl}`,
    uploadDocument: (applicationId: number) =>
        `${appConfig.customerDataBaseUrl}/api/ApplicationPage/document/uploaddocument?applicationId=${applicationId}`,
    getDocument: (applicationId: string, documentType: DocumentTypes) =>
        `${appConfig.customerDataBaseUrl}/api/ApplicationPage/document/downloaddocument?applicationId=${applicationId}&documentType=${documentType}`,
    updatePpi: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/updateppi`,
    updateSbl: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/updatesbl`,
    updateMissingKyc: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/updatekyc`,
    addPublicNote: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/addpublicnote`,
    updateBankInfo: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/updatebankinfo`,
    getDownloadDocuments: (applicationId: string) =>
        `${appConfig.customerDataBaseUrl}/api/ApplicationPage/document/downloaddocuments?applicationId=${applicationId}`,
    getDownloadDocumentById: (documentId: string) =>
        `${appConfig.customerDataBaseUrl}/api/ApplicationPage/document/downloaddocumentbyid?documentId=${documentId}`,
    updateCustomerConsents: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/updateCustomerConsents`,
    printPn: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/printpn`,
    updateLoanData: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/updateloandata`,
    updateApplicationInformation: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/applicationinformation`,
    runmulticashpreliminarydecision: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/multicash/runpreliminarydecision`,
    runcreditcardpreliminarydecision: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/creditcard/runpreliminarydecision`,
    runcreditdecision: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/runcreditdecision`,
    runmulticashcreditdecision: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/multicash/runcreditdecision`,
    runcreditcardcreditdecision: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/creditcard/runcreditdecision`,
    confirmApplication: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/confirmapplication`,
    confirmMultiCashApplication: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/confirmmulticashapplication`,
    removedirectdebit: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/removedirectdebit`,
    getNordigenBanks: (country: string) =>
        `${appConfig.customerDataBaseUrl}/api/ApplicationPage/nordigen/banks?${country}`,
    getNordigenAgreement: (institutionId: string) =>
        `${appConfig.customerDataBaseUrl}/api/ApplicationPage/nordigen/agreement?${institutionId}`,
    createNordigenRequisitions: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/nordigen/requisitions`,
    getNordigenListAccount: (requisitionId: string) =>
        `${appConfig.customerDataBaseUrl}/api/ApplicationPage/nordigen/listAccount?${requisitionId}`,
    getMaintenanceInfo: (market: string) =>
        `${appConfig.customerDataBaseUrl}/api/customerservice/maintenance/next?market=${market}`,
    electronicIDVerificationRequest: `${appConfig.customerDataBaseUrl}/api/ApplicationPage/electronicid/verification`,
}