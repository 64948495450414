import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import appConfig from 'app.config'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import updateApplicationServices from 'services/updateApplicationServices'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

import './Ppi.scss'

export const PpiDk = () => {
	const { t } = useTranslation()
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)
	const [ppiStatus, setPpiStatus] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<boolean>(false)

	const updatePpiConsent = async () => {
		setLoading(true)
		setError(false)
		try {
			await updateApplicationServices
				.updatePpi(
					{
						ppi: ppiStatus,
						applicationId:
							loanApplication.applicationData?.loanNumber!,
					},
					t
				)
				.then((response) => {
					if (response === 200) {
						dispatchMsp({
							type: MSP_ACTIONS.SET_INDEX,
							payload: {
								index: msp.index + 1,
							},
						})
					}
				})
		} catch (err) {
			console.log(err)
			setError(true)
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className="payment-protection-insurance">
			{loading ? (
				<CircleSpinner />
			) : (
				<div className="ppi-section">
					<div>
						<p className="ppi-description">{t('PpiDesc')}</p>
						<label className="checkbox-container mt-2">
							<input
								type="checkbox"
								name="allowMarketing"
								onChange={() => setPpiStatus(!ppiStatus)}
								checked={ppiStatus}
							/>
							<span className="checkmark"></span>
							<p>
								<b>{t('PpiLabel')}</b>
							</p>
						</label>
						<div className="ppi-terms">
							<div>{t('PpiLine1')}</div>
							<div>ELLER</div>
							<div>{t('PpiLine2')}</div>
						</div>
						<div className="ppi-link-section">
							<a
								href={
									appConfig.environment === 'production'
										? 'https://tfbank.dk/media/0ujn3nya/36xxx-tf-bank-l%C3%A5nesikring-forsikringsbetingelser-1_0.pdf'
										: 'https://stage-tf-sweden.euwest01.umbraco.io/media/0ujn3nya/36xxx-tf-bank-l%C3%A5nesikring-forsikringsbetingelser-1_0.pdf'
								}
								className="link-darkgray"
								target="_blank"
								rel="noopener noreferrer">
								TF Bank Lånesikring, forsikringsbetingelser
							</a>
							<a
								href={
									appConfig.environment === 'production'
										? 'https://tfbank.dk/media/2jjdqpus/36xxx_lpi_tf-bank_information-om-forsikringsformidling_1_0.pdf'
										: 'https://stage-tf-sweden.euwest01.umbraco.io/media/2jjdqpus/36xxx_lpi_tf-bank_information-om-forsikringsformidling_1_0.pdf'
								}
								className="link-darkgray"
								target="_blank"
								rel="noopener noreferrer">
								Information om forsikringsformidling
							</a>
							<a
								href={
									appConfig.environment === 'production'
										? 'https://tfbank.dk/media/0mopm5p0/ipid-l%C3%A5nesikring-18-64-%C3%A5r-produktinformation-1_0.pdf'
										: 'https://stage-tf-sweden.euwest01.umbraco.io/media/0mopm5p0/ipid-l%C3%A5nesikring-18-64-%C3%A5r-produktinformation-1_0.pdf'
								}
								className="link-darkgray"
								target="_blank"
								rel="noopener noreferrer">
								IPID Lånesikring 18-64 år Produktinformation
							</a>
							<a
								href={
									appConfig.environment === 'production'
										? 'https://tfbank.dk/media/rbyb25q4/ipid-l%C3%A5nesikring-65-75-%C3%A5r-produktinformation-1_0.pdf'
										: 'https://stage-tf-sweden.euwest01.umbraco.io/media/rbyb25q4/ipid-l%C3%A5nesikring-65-75-%C3%A5r-produktinformation-1_0.pdf'
								}
								className="link-darkgray"
								target="_blank"
								rel="noopener noreferrer">
								IPID Lånesikring 65-75 år Produktinformation
							</a>
						</div>
					</div>
					{error ? (
						<div className="error-box-container">
							<ErrorBox message={t('PpiError')} />
						</div>
					) : null}
				</div>
			)}

			<div className="ppi-next-button">
				<button
					className="btn btn-primary"
					onClick={() => updatePpiConsent()}>
					{t('Next')}
				</button>
			</div>
		</div>
	)
}
