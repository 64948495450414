import { TranslationFile } from 'i18n'
import { useTranslation } from 'react-i18next'

import { IApplicationInformation } from 'interfaces/i-application-information'

export const ApplicationContactInformationForm = (
	data: IApplicationInformation
) => {
	const { t } = useTranslation([TranslationFile.Forms])

	return {
		fieldSetName: '',
		formName: 'ApplicationContactInformationForm',
		description: t('forms:FinancialDataDescription'),
		collapsable: false,
		show: true,
		visible: true,
		market: 'es',
		fullSize: true,
		col: 12,
		controls: [
			{
				required: true,
				controlName: 'mobilePhoneNumber',
				type: 'tel', //change back to phoneNumber
				label: t('forms:MobilePhoneNo'),
				description: t('forms:MobilePhoneNumberDescription'),
				value: data.contacts?.cellPhone,
				visible: true,
				col: 12,
				wrapperClass: 'tel-container-wrapper es', //Add this class for now when using type "tel"
			},
			{
				required: true,
				controlName: 'email',
				type: 'text',
				label: t('forms:Email'),
				description: t('forms:EmailDescription'),
				value: data.contacts?.email,
				visible: true,
				validations: [
					{
						code: 'MustMatchPattern',
						value: '\\S+@\\S+\\.\\S+',
						message: t('ValidationMsgEmail'),
					},
				],
				col: 12,
			},
			{
				required: false,
				controlName: 'comment',
				type: 'text',
				label: t('forms:Comment'),
				description: t('forms:CommentDescription'),
				visible: true,
				col: 12,
			},
		],
	}
}
