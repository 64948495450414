import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import { FormBuilder } from '@tf/formbuilder'

import appConfig from 'app.config'

import './spainOtpContentBlock.scss'
import {
	IApplicationOtpStartRequest,
	IOtpCompleteRequest,
} from 'interfaces/i-auth/i-otpid'
import {
	addLeadingZeroToDates,
	formatFromToIsoDate,
} from 'utils/dateAndTimeHelpers'
import { getReturnUrl } from 'utils/authHelpers'
import { CountryA3 } from 'constants/enums/countryTypes'
import authenticationService from 'services/authServices'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import { ValidationError } from 'components/message-boxes/validation-error-box/ValidationErrorBox'
import { CookieType } from 'constants/enums/cookieTypes'
import { FormFactory } from 'formBuilder/formFactory'
import { CustomerForm } from 'constants/enums/formTypes'
import { getLanguageLocale } from 'utils/countryHelpers'

const OtpContentBlock = () => {
	const { t } = useTranslation()
	const [loading, setLoading] = useState<boolean>(false)
	const [secret, setSecret] = useState('')
	const [done, setDone] = useState<boolean>(false)
	const [market] = useState<string>(appConfig.country)
	const [error, setError] = useState<Error | undefined>()

	const formSchemaOtpStart = FormFactory(
		CustomerForm.OtpStartFormEs,
		{
			formName: 'start',
			blockName: 'start',
			market: market,
			locale: getLanguageLocale(),
			getDataUrl: '',
			requiredMessage: t('ValidateRequired'),
			validationErrorList: false,
			submitButtonText: t('Proceed'),
		},
		undefined
	)

	const formSchemaOtpComplete = FormFactory(
		CustomerForm.OtpCompleteForm,
		{
			formName: 'complete',
			blockName: 'complete',
			market: market,
			locale: getLanguageLocale(),
			getDataUrl: '',
			validationErrorList: false,
			requiredMessage: t('ValidateRequired'),
			submitButtonText: t('Proceed'),
		},
		undefined
	)

	const loginStart = async (values: IApplicationOtpStartRequest) => {
		setError(undefined)
		setLoading(true)
		// Hax0r fix for humans that are humans as we all know them
		sessionStorage.setItem(
			CookieType.RedirectUrl,
			`/application?applicationid=${values.applicationId}`
		)
		try {
			const { secret } = await authenticationService.otpStart(
				{
					birthDate: formatFromToIsoDate(
						addLeadingZeroToDates(values.birthDate),
						'dd/MM/yyyy'
					)!,
					ssn: values.ssn.toUpperCase(), // PX only handle uppercase
					applicationId: values.applicationId,
					returnUrl: getReturnUrl(),
					country: CountryA3.es,
				},
				t
			)
			setSecret(secret)
		} catch (e) {
			const E = e as AxiosError
			const status = E.response?.status
			if (status === 400) setError(new Error(t('OtpBadRequestError')))
			else setError(new Error(t('OtpInternalServerError')))
		} finally {
			setLoading(false)
		}
	}

	const loginComplete = async (formValues: IOtpCompleteRequest) => {
		setError(undefined)
		setLoading(true)

		try {
			const response = await authenticationService.otpComplete(
				{
					secret,
					otp: formValues.otp,
					returnUrl: getReturnUrl(),
				},
				t
			)
			setDone(true)
			authenticationService.redirectTo(
				response.redirectUrl || getReturnUrl(),
				response.secret
			)
		} catch (e) {
			console.log(e)
			const E = e as AxiosError
			const status = E.response?.status
			if (status === 410) setError(new Error(t('OtpGoneError')))
			else if (status === 400)
				setError(new Error(t('OtpBadRequestError')))
			else setError(new Error(t('OtpInternalServerError')))
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className="spain-otp-container">
			{loading ? (
				<CircleSpinner />
			) : (
				!done && (
					<>
						<div className="login-description">
							<p>{t('LoginHeaderText')}</p>
						</div>
						{error && <ValidationError message={error.message} />}
						{secret ? (
							<FormBuilder
								formSchema={formSchemaOtpComplete}
								apiError={undefined}
								showErrorsInForm={true}
								showSubmitButton={true}
								onSubmit={(otp) => loginComplete(otp)}
							/>
						) : (
							<FormBuilder
								formSchema={formSchemaOtpStart}
								apiError={undefined}
								showErrorsInForm={true}
								showSubmitButton={true}
								onSubmit={(values) => loginStart(values)}
							/>
						)}
					</>
				)
			)}
		</div>
	)
}

export default OtpContentBlock
