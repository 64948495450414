export function downloadBlob(blob: Blob, name: string) {
	const data = window.URL.createObjectURL(blob)
	const link = document.createElement('a')
	link.style.display = 'none'
	link.href = data
	link.download = name
	document.body.appendChild(link)
	link.click()
}

export function showBlob(
	blob: Blob,
	windowRef: Window | null,
	fileName: string = 'file.pdf'
) {
	var URL = window.URL || window.webkitURL
	const data = URL.createObjectURL(blob)

	if (windowRef) {
		windowRef.location.href = data
	} else {
		downloadBlob(blob, fileName)
	}

	setTimeout(function () {
		// For Firefox it is necessary to delay revoking the ObjectURL
		URL.revokeObjectURL(data)
	}, 100)
}
