import React, { useState } from 'react'
import SmoothCollapse from 'react-smooth-collapse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './ReadMoreExpander.scss'

interface IReadMoreExpander {
	children: any
	header?: string
	collapsedHeight?: string
	expanded?: boolean
	showExpander?: boolean
	contentTop?: boolean
	className?: string
	headerExpanded?: string
	headerCollapsed?: string
	showIcons?: boolean
}

export function ReadMoreExpander({
	children,
	header,
	collapsedHeight,
	expanded = false,
	showExpander = true,
	contentTop = false, //When true the expander arrow is displayed below displayed content.
	className = '',
	headerExpanded,
	headerCollapsed,
	showIcons = true
}: IReadMoreExpander) {
	const [isExpanded, setExpanded] = useState(expanded)

	if(header && !headerExpanded){
		headerExpanded = header
	}

	if(header && !headerCollapsed){
		headerCollapsed = header
	}
	
	return (
		<div
			className={`read-more-expander ${contentTop ? 'content-top' : ''} ${
				showExpander ? 'mobile' : ''
			} ${className}`}>
			<div
                onClick={() => setExpanded(!isExpanded)}
                className={`expander-item ${showExpander ? 'show' : 'hide'}`}>
					<span></span>
                {(isExpanded && headerExpanded) || (!isExpanded && headerCollapsed) ? 
					(<div className="read-more-expander-header">{isExpanded ? headerExpanded : headerCollapsed}</div>)
					:(<></>)}		
                {showIcons && <FontAwesomeIcon icon={isExpanded ? 'chevron-up' : 'chevron-down'} />	}
				<span></span>
             </div>
			<SmoothCollapse
				expanded={isExpanded}
				children={children}
				allowOverflowWhenOpen={true}
				collapsedHeight={collapsedHeight}
			/>
		</div>
	)
}
