export enum PropertyType {
	Occupation = 'occupation',
	PurposeOfDeposit = 'purposeOfDeposit',
	MoneySource = 'moneySource',
	TaxCountry = 'taxCountry',
	ExpectedDeposits = 'expectedDeposits',
	ExpectedDepositsAmount = 'expectedDepositsAmount',
	ExpectedWithdrawls = 'expectedWithdrawals',
	ExpectedWithdrawlsAmount = 'expectedWithdrawalsAmount',
	Income = 'income',
	DepositOwner = 'depositOwner',
	PurposeOfLoan = 'purposeOfLoan',
	MaritalStatus = 'maritalStatus',
	AccommodationType = 'accommodationType',
	Education = 'education',
	EmploymentSector = 'employmentSector',
}
