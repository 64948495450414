import React from 'react'
import './CircleSpinner.scss'

interface ICircleSpinner {
	className?: string
}

/**
 * Main spinner for application page
 * @param className specific classname applied
 * @returns Spinner
 */

const CircleSpinner = ({ className }: ICircleSpinner) => {
	return (
		<div id="circle-spinner" className={className ? className : ''}></div>
	)
}

export default CircleSpinner
