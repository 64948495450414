import React from 'react'
import { IMultistepComponent } from 'interfaces/i-multistep'
import Multistep from 'components/multistep/Multistep'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

export const LoanApplicationStepsEe = () => {
	// const { loanApplication }: ILoanApplicationPageProps = useContext(
	// 	ApplicationDispatchContext
	// )

	const loanApplicationSteps: IMultistepComponent[] = [
		{
			id: 1,
			header: 'Psd2',
			// body: <SignPsd2Ee />,
			body: <></>,
			// show: CheckIsLifeCycleStatus(
			// 	[LifeCycleStatus.ReceiveSigning],
			// 	loanApplication.applicationData?.lifeCycleStatus!
			// ),
			show: false,
			stepCompleted: false,
		},
		{
			id: 2,
			header: 'YourLoanOffer',
			// body: <LoanOfferEE />,
			body: <></>,

			show: false,
			// show: CheckIsLifeCycleStatus(
			// 	[LifeCycleStatus.ReceiveSigning],
			// 	loanApplication.applicationData?.lifeCycleStatus!
			// ),
			stepCompleted: false,
		},
		{
			id: 3,
			header: 'ErrorMsgHeader',
			body: <ErrorBox message={'ErrorMsg'} />,
			// show: !CheckIsLifeCycleStatus(
			// 	[LifeCycleStatus.ReceiveSigning],
			// 	loanApplication.applicationData?.lifeCycleStatus!
			// ),
			show: true,
			stepCompleted: false,
		},
	]

	return (
		<Multistep
			multisteps={loanApplicationSteps}
			resetInitialState={false}
		/>
	)
}
