import { CreditDecisionTypes } from 'constants/enums/creditdecisionTypes'
import { ICreditDecisionMessage } from 'interfaces/i-credit-decision-message'

export const CreditDecisionMessages: ICreditDecisionMessage[] = [
	{
		type: CreditDecisionTypes.Error,
		message: 'CreditDecisionError',
		css: 'fail',
	},
	{
		type: CreditDecisionTypes.Invalid,
		message: 'CreditDecisionInvalid',
		css: 'fail',
	},
	{
		type: CreditDecisionTypes.Investigation,
		message: 'CreditDecisionInvestigation',
		css: 'info',
	},
	{
		type: CreditDecisionTypes.Rejected,
		message: 'CreditDecisionRejected',
		css: 'info',
	},
]
