import { TFunction } from 'i18next'
import Axios from 'axios'

import { setUpApplicationInterceptorsFor } from './interceptors'
import { cdaApiRoutes } from 'constants/routes/cdaApiRoutes'
import { getHeadersWithJwtToken } from 'utils/authHelpers'

const transportWithCredentials = setUpApplicationInterceptorsFor(
	Axios.create({})
)

transportWithCredentials.defaults.headers.post['Content-Type'] =
	'application/json'
transportWithCredentials.defaults.headers.post['Access-Control-Allow-Origin'] =
	'*'
transportWithCredentials.defaults.headers.post['Access-Control-Allow-Headers'] =
	'Origin, X-Requested-With, Content-Type, Accept'

const documentServices = {
	async UploadDocument(
		uploadDocumentRequest: FormData,
		applicationId: number,
		t: TFunction
	) {
		let auth = await getHeadersWithJwtToken()
		let headers = {
			headers: {
				Authorization: auth.headers.Authorization,
				'Content-Type': 'application/json',
			},
		}
		const response = await transportWithCredentials.post(
			cdaApiRoutes.uploadDocument(applicationId),
			uploadDocumentRequest,
			headers
		)
		if (response.status !== 200) {
			throw new Error(t('UploadDocumentError'))
		}
	},

	async getDownloadDocuments(applicationId: string, t: TFunction) {
		let data = await transportWithCredentials.get<any>(
			cdaApiRoutes.getDownloadDocuments(applicationId),
			await getHeadersWithJwtToken()
		)
		if (data.status === 200) {
			return data.data.documents
		}
		throw new Error(t('DownloadingFileError'))
	},

	async getDownloadDocumentById(documentId: string) {
		const data = await transportWithCredentials.get<any>(
			cdaApiRoutes.getDownloadDocumentById(documentId),
			{
				...(await getHeadersWithJwtToken()),
				responseType: 'blob',
			}
		)
		if (data.status === 200) {
			console.log(data)
			return {
				blob: data.data as Blob,
			}
		}
		throw new Error('Could not download document by id')
	},
}

export default documentServices
