import React, { useContext } from 'react'
import appConfig from 'app.config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './MaintenanceWindow.scss'
import MaintenanceContext from 'contexts/MaintenanceContext'

const MaintenanceWindow = () => {
	const { maintenanceData, setMaintenanceData } =
		useContext(MaintenanceContext)
	const countryCode = appConfig.country.toUpperCase()

	enum propName {
		durationMessage = 'durationMessage',
		preMessage = 'preMessage',
	}

	const getDurationMessage = GetPropertyDescriptor(
		propName.durationMessage
	)?.value

	const getPreMessage = GetPropertyDescriptor(propName.preMessage)?.value

	function GetPropertyDescriptor(propertyName: propName) {
		return Object.getOwnPropertyDescriptor(
			maintenanceData,
			`${propertyName}${countryCode}`
		)
	}

	function IfMaintenanceWindow() {
		let startTime = new Date(maintenanceData?.maintenanceWindowFrom!)
		let endTime = new Date(maintenanceData?.maintenanceWindowTo!)
		let preStartTime = new Date(startTime)
		let timeNow = new Date(Date.now())
		let preMessageTimeInMinutes = maintenanceData?.minutesBeforeMaintenance!
		preStartTime.setMinutes(
			startTime.getMinutes() - preMessageTimeInMinutes
		)
		return {
			isPreMessage: timeNow < startTime && timeNow > preStartTime,
			isInTimeRange: timeNow > preStartTime && timeNow < endTime,
		}
	}

	return (
		<>
			{getDurationMessage &&
				getDurationMessage !== '' &&
				IfMaintenanceWindow().isInTimeRange && (
					<div className="maintenance-window d-flex justify-content-between p-3">
						<div className="d-flex warning mr-4"></div>
						<div className="d-flex">
							<div className="mr-4">
								<div>
									{IfMaintenanceWindow().isPreMessage
										? getPreMessage
										: getDurationMessage}
								</div>
							</div>
						</div>
						<div className="d-flex">
							<FontAwesomeIcon
								onClick={() => setMaintenanceData()}
								icon="times"
								color="#fff"
								aria-hidden="false"
							/>
						</div>
					</div>
				)}
		</>
	)
}

export default MaintenanceWindow
