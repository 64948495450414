import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { CookieConsentBar } from 'components/cookie-consent/CookieConsentBar'
import { ProductSettingTypes } from 'constants/enums/productTypes'

import tfbank21_logo_symbol_white from 'img/tf-logos/tfbank21-logo-symbol-white.svg'
import './FooterLoggedOut.scss'
import AppContext from 'contexts/AppContext'
import { footerValues } from 'constants/values/footerValues'
import { IFooter } from 'interfaces/i-footer'
import { CountryTypes } from 'constants/enums/countryTypes'

/**
 * Footer not dependent on type of px application product
 * @returns jsx element Footer for logged out pages
 */
export function FooterLoggedOut() {
	const { market } = useContext(AppContext)
	const { t } = useTranslation()
	const [footerContent, setFooterContent] = useState<IFooter>(footerValues[0])

	const getFooterValues = useCallback(() => {
		console.log(footerValues)
		footerValues.find(
			(val) => val.country === market && setFooterContent(val)
		)
	}, [market])

	useEffect(() => {
		getFooterValues()
	}, [getFooterValues])

	return (
		<div id="footer-logged-out">
			<CookieConsentBar />
			<Row>
				<Col>
					<div id="footer-copyright">
						<img
							src={tfbank21_logo_symbol_white}
							alt="TF Bank AB"
						/>
						{`© ${new Date().getFullYear()} ${
							footerContent === undefined
								? 'TF Bank AB'
								: footerContent?.copyRight
						}`}
					</div>
					{market !== CountryTypes.no ? (
						<div id="footer-contact">
							{footerContent?.productSpecific.map(
								(value, key) => {
									if (
										value.productType ===
										ProductSettingTypes.All
									) {
										return (
											<div key={key}>
												{footerContent.country ===
												'fi' ? (
													<div className="footer-contact-phone-number">
														<a
															href={`tel:${value.contactInformation.phoneNumber}}`}>
															{value
																.contactInformation
																.phoneNumber +
																`, (${t(
																	'RegularDepositAccount'
																)}),`}
														</a>
														<a
															href={`tel:${value.contactInformation.phoneNumber2}}`}>
															{value
																.contactInformation
																.phoneNumber2 +
																`, (${t(
																	'RegularLoan'
																)}),`}
														</a>
													</div>
												) : (
													<div className="footer-contact-phone-number">
														<a
															href={`tel:${value.contactInformation.phoneNumber}}`}>
															{
																value
																	.contactInformation
																	.phoneNumber
															}
														</a>
														{','}
													</div>
												)}
											</div>
										)
									} else return null
								}
							)}
							{footerContent !== undefined &&
								Object.entries(footerContent?.address).map(
									([key, value]) => {
										return key === 'zipCodeAndCity'
											? value
											: key === 'country' 
												? ', ' + value
												: value + ', '
									}
								)}
						</div>
					) : null}
				</Col>
			</Row>
		</div>
	)
}
