import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { IApplicationContext } from 'interfaces/i-application-context-props'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'

import './MarketingConsent.scss'

interface IProps {
	allowMarketing?: boolean
}

export const MarketingConsentCheckbox = ({
	allowMarketing = false,
}: IProps) => {
	const { t } = useTranslation()
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const [iAllowMarketing, setIAllowMarketing] =
		useState<boolean>(allowMarketing)

	useEffect(
		() => {
			dispatchLoanApplication({
				type: LOAN_APPLICATION_ACTIONS.SET_UPDATE_CUSTOMERCONSENTS,
				payload: {
					...loanApplication,
					customerConsents: {
						...loanApplication.customerConsents,
						applicationId:
							loanApplication.applicationData?.loanNumber,
						allowMarketing: iAllowMarketing,
					},
				},
			})
		},
		[iAllowMarketing] //eslint-disable-line
	)

	return (
		<div className="marketing-consent check-box-type">
			<label className="checkbox-container mt-2">
				<input
					type="checkbox"
					name="allowMarketing"
					checked={iAllowMarketing}
					onChange={() => setIAllowMarketing(!iAllowMarketing)}
				/>
				<span className="checkmark"></span>
				<div>{t('SubscribeMarketing')}</div>
			</label>
		</div>
	)
}
