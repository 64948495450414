import React from 'react'
import { useTranslation } from 'react-i18next'

import app_store from 'img/mobile-app/app-store.png'
import google_play from 'img/mobile-app/google-play.png'
import Heading from 'components/heading/Heading'
import { getMobileOperatingSystem } from 'utils/commonhelpers'


const AppDownloadSection = () => {
	const { t } = useTranslation()
	const op = getMobileOperatingSystem()
	return (
		<section className="container my-5 section-app">
			<Heading title={'ActivatedCard'} />
			<div className="my-4">{t('ActivatedCardText')}</div>
			<div className="app-links">
				<div>
					{(op === 'iOS' || op === 'unknown') && (
						<a
							href="http://appstore.com/tfbankmobile"
							target="_blank"
							rel="noopener noreferrer">
							<img src={app_store} alt="apple-store" />
						</a>
					)}
					{(op === 'Android' || op === 'unknown') && (
						<a
							href="https://play.google.com/store/apps/details?id=se.tfbank.tfbankmobile"
							target="_blank"
							rel="noopener noreferrer">
							<img src={google_play} alt="goolge-store" />
						</a>
					)}
				</div>
			</div>
		</section>
	)
}

export default AppDownloadSection
