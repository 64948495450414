import React from 'react'

import './SkeletonLoader.scss'

export interface ISkeletonLoader {
	height?: string
	width?: string
}

const SkeletonLoader = ({
	height = 'auto',
	width = '100%',
}: ISkeletonLoader) => {
	return (
		<div id="skeleton-loader">
			<span
				className="loading-block"
				style={{
					height: height,
					width: width,
					backgroundColor: '#f9f8f3',
				}}
			/>
		</div>
	)
}

export default SkeletonLoader
