import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import goldcard_no_mc from 'img/credit-card-images/goldcard_no_mc.png'
import { stringifyNum } from 'utils/commonhelpers'
import { getCurrency } from 'utils/countryHelpers'

const CardSection = ({ applicationData }: any) => {
	const { t } = useTranslation()

	return (
		<section className="container">
			<div className="d-flex justify-content-center align-items-center cards-container py-4">
				<div className="tfbank-card-container">
					<div className="imageContainer">
						<img src={goldcard_no_mc} alt="card" />
					</div>
				</div>
				<div className="box-amount">
					<div className="d-flex flex-column align-items-center">
						<span>{t('ApprovedCreditcardAmount')}</span>
						<span>
							{stringifyNum(applicationData?.approvedAmount, {
								currency: getCurrency(),
								style: 'currency',
							})}
						</span>
					</div>
				</div>
			</div>
			<div className="card-section-text">
				<Trans t={t} i18nKey="CardText">
					<b>
						Die Kreditkarte senden wir zu Ihrer angegebenen Adresse.
					</b>
					<br />
					<br />
					Für die Aktivierung Ihrer Kreditkarte benötigen wir folgende
					Dokumente:
					<br />
					1. Identifikationsdokumente: Nutzen Sie dafür den
					untenstehenden IDNow-Link für eine schnelle und digitale
					Identifizierung
					<br />
					2. Unterschriebenes Aktivierungsformular: Dieses erhalten
					Sie zusammen mit Ihrer Kreditkarte und muss nur noch von
					Ihnen unterschrieben und hier hochgeladen werden.
				</Trans>
			</div>
		</section>
	)
}

export default CardSection
